// src/components/HamburgerIcon.jsx
import React from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

// Styled IconButton to ensure the whole area is clickable
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: '#2561B0', // Same as your original button
  borderRadius: 0,
  padding: '16px',
  '&:hover': {
    backgroundColor: '#2561B0', // Keep the same background color on hover
  },
  marginRight: '16px',
  height: '64px',
  marginLeft: '0px',
}));

// Hamburger icon styling
const HamburgerIconStyled = styled('div')(({ isOpen }) => ({
  display: 'inline-block',
  position: 'relative',
  width: '30px',
  height: '20px',

  '& span': {
    backgroundColor: '#FFFFFF', // Set the lines to white
    borderRadius: '2px',
    display: 'block',
    position: 'absolute',
    height: '3px',
    width: '100%',
    transition: 'all 0.3s ease',
  },

  '& span:nth-of-type(1)': {
    transform: isOpen ? 'rotate(45deg)' : 'rotate(0)',
    top: isOpen ? '9px' : '0',
  },
  '& span:nth-of-type(2)': {
    top: '9px',
    opacity: isOpen ? '0' : '1',
  },
  '& span:nth-of-type(3)': {
    transform: isOpen ? 'rotate(-45deg)' : 'rotate(0)',
    top: isOpen ? '9px' : '18px',
  },
}));

// HamburgerIcon Component
const HamburgerIcon = ({ isOpen, toggleSidebar }) => {
  return (
    <StyledIconButton
      size="large"
      edge="start"
      color="inherit"
      aria-label="open drawer"
      onClick={toggleSidebar}
    >
      <HamburgerIconStyled isOpen={isOpen}>
        <span></span>
        <span></span>
        <span></span>
      </HamburgerIconStyled>
    </StyledIconButton>
  );
};

export default HamburgerIcon;
