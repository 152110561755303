import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import CustomDatePicker from '../../../components/CreatePosting/CustomDatePicker';
import useMobile from '../../../hooks/useMobile';

const FiltersSidebar = ({
  open,
  handleClose,
  filterJobs,
  postingTitle,
  setPostingTitle,
  location,
  setLocation,
  setDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  date,
  setOfficeName,
  officeName,
  setType,
  type,
  resetFilter,
}) => {
  const isMobile = useMobile(767);
  return (
    <Drawer
      style={{ width: isMobile ? '100%' : 340 }}
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          width: isMobile ? '100%' : 340,
          padding: '16px',
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          variant="h5"
          style={{ fontWeight: 500, marginBottom: '1rem' }}
        >
          Filters
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box display="flex" flexDirection="column" gap="1rem">
        <CustomDatePicker
          label="Start Date"
          onChange={(date) => setStartDate(date)}
          value={startDate}
          size="medium"
        />
        <CustomDatePicker
          label="End Date"
          onChange={(date) => setEndDate(date)}
          value={endDate}
          size="medium"
        />

        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="Title"
          value={postingTitle}
          onChange={(e) => setPostingTitle(e.target.value)}
          InputProps={{ style: { padding: '10px' } }}
        />

        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="Location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          InputProps={{ style: { padding: '10px' } }}
        />

        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="Company"
          value={officeName}
          onChange={(e) => setOfficeName(e.target.value)}
          InputProps={{ style: { padding: '10px' } }}
        />

        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel>Type</InputLabel>
          <Select
            value={type}
            onChange={(e) => setType(e.target.value)}
            label="Type"
            style={{ padding: '10px' }}
          >
            <MenuItem value="temporary">Temporary</MenuItem>
            <MenuItem value="permanent">Permanent</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        marginTop="3rem"
        gap="10px"
      >
        <Button
          variant="outlined"
          style={{
            width: '100%',
            border: '1px solid #2561B0',
            color: '#595959',
          }}
          onClick={resetFilter}
        >
          Reset
        </Button>

        <Button
          variant="contained"
          style={{ backgroundColor: '#2561B0', color: '#fff', width: '100%' }}
          onClick={filterJobs}
        >
          Find
        </Button>
      </Box>
    </Drawer>
  );
};

export default FiltersSidebar;
