// import { Box, Typography } from '@mui/material';
// import axios from 'axios';
// import React, { useEffect, useState } from 'react';

// import Dialog from '@mui/material/Dialog';
// import DialogContent from '@mui/material/DialogContent';
// import { useTheme } from '@mui/material/styles';
// import Grid from '@mui/material/Unstable_Grid2';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import referralOwner from '../../assets/images/refer-a-owner.png';
// import LoadingButton from '../../components/General/LoadingButton';

// import Alert from '@mui/material/Alert';
// import CardMedia from '@mui/material/CardMedia';
// import FormControl from '@mui/material/FormControl';
// import FormHelperText from '@mui/material/FormHelperText';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
// import Stack from '@mui/material/Stack';
// import TextField from '@mui/material/TextField';

// import { FormikProvider, useFormik } from 'formik';
// import * as Yup from 'yup';
// import { API_BASE_URL } from '../../config/constants';

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   bgcolor: 'background.paper',
//   boxShadow: 24,
//   p: 4,
//   gap: '20px',
//   display: 'flex',
//   flexDirection: 'column',
//   borderRadius: '6px',
// };

// const OwnerReferralModal = ({
//   isOpen,
//   onClose,
//   successModal,
//   errorModal,
//   getReferrals,
//   setSuccessMessage,
// }) => {
//   const [selectedDate, setSelectedDate] = useState(null);
//   const theme = useTheme();
//   const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
//   const [maxWidth, setMaxWidth] = React.useState('lg');
//   const [loading, setLoading] = useState(false);
//   const [finished, setFinished] = useState(false);
//   const [states, setStates] = useState([]);

//   const schema = Yup.object().shape({
//     first_name: Yup.string().required('First Name is required'),
//     last_name: Yup.string().required('Last Name is required'),
//     phone: Yup.string().required('Enter Phone Number'),
//     state: Yup.string().required('required'),
//     city: Yup.string().required('Enter City Name'),
//     email: Yup.string()
//       .min(5, 'Must be at least 8 characters')
//       .max(20, 'Must be less  than 20 characters')
//       .required('Email is required')
//       .test('Unique Email', 'Email already in use', function (value) {
//         return new Promise((resolve, reject) => {
//           axios
//             .get(`${API_BASE_URL}/available/${value}`)
//             .then((res) => {
//               resolve(true);
//             })
//             .catch((error) => {
//               resolve(false);
//             });
//         });
//       }),
//   });

//   const formik = useFormik({
//     initialValues: {
//       first_name: '',
//       last_name: '',
//       email: '',
//       phone: '',
//       state: '',
//       city: '',
//     },
//     validationSchema: schema,
//     enableReinitialize: true,
//     onSubmit: (values, { resetForm }) => {
//       handleSave(values);
//     },
//   });

//   const handleSave = async (data) => {
//     setLoading(true);
//     data.referral_user_type = 'owner';
//     await axios
//       .post(`${API_BASE_URL}/referral`, data, {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
//         },
//       })
//       .then((res) => {
//         setSuccessMessage('Owner Added Successfully');
//         console.log(res);
//         successModal();
//         setFinished(true);
//         getReferrals();
//         onClose();
//       })
//       .catch((err) => {
//         errorModal();
//         setFinished(true);
//         onClose();
//         console.log(err);
//       });
//   };

//   const getStates = () => {
//     fetch(`${API_BASE_URL}/states`)
//       .then((response) => response.json())
//       .then((data) => {
//         setStates(data.data);
//       })
//       .catch((error) => console.error('Error fetching states:', error));
//   };

//   useEffect(() => {
//     getStates();
//   }, []);

//   return (
//     <Dialog
//       // fullScreen={fullScreen}
//       open={isOpen}
//       onClose={onClose}
//       aria-labelledby="responsive-dialog-title"
//       fullWidth="lg"
//       maxWidth={maxWidth}
//     >
//       <DialogContent
//         sx={{
//           padding: '0px',
//         }}
//       >
//         <Box>
//           <Grid container>
//             <Grid
//               sx={{
//                 border: '1px solid',
//                 borderColor: '#D9D9D9',
//               }}
//               xs={4}
//               md={4}
//             >
//               <CardMedia
//                 component="img"
//                 height="715vh"
//                 width="25%"
//                 image={referralOwner}
//                 alt="Special Offers"
//               />
//             </Grid>

//             <Grid
//               sx={{
//                 border: '1px solid',
//                 borderColor: '#D9D9D9',
//               }}
//               xs={8}
//               md={8}
//             >
//               <Box
//                 sx={{
//                   px: 10,
//                   pt: 4,
//                 }}
//               >
//                 <Typography id="modal-modal-title" variant="h4" component="h2">
//                   Referr a Dental Practice
//                 </Typography>
//               </Box>

//               <Box
//                 sx={{
//                   px: 10,
//                   pt: 4,
//                   pb: 1,
//                 }}
//               >
//                 <FormikProvider value={formik}>
//                   <form>
//                     <Box sx={{ display: 'flex' }}>
//                       <Typography
//                         id="modal-modal-title"
//                         variant="h6"
//                         component="h2"
//                         sx={{ px: 1, pb: 1, pt: 1 }}
//                       >
//                         Profile Information
//                       </Typography>
//                     </Box>

//                     <Box sx={{ display: 'flex' }}>
//                       <FormControl
//                         sx={{ width: '50%', px: 1 }}
//                         variant="outlined"
//                       >
//                         <TextField
//                           label="Doctor First Name"
//                           id="outlined-start-adornment"
//                           sx={{ width: '100%' }}
//                           value={formik.values.first_name}
//                           required
//                           {...formik.getFieldProps('first_name')}
//                         />
//                         {formik.errors.first_name &&
//                           formik.touched.first_name && (
//                             <FormHelperText style={{ color: '#FA5A16' }}>
//                               Please Enter First Name.
//                             </FormHelperText>
//                           )}
//                       </FormControl>
//                       <FormControl
//                         sx={{ width: '50%', px: 1 }}
//                         variant="outlined"
//                       >
//                         <TextField
//                           label="Doctor Last Name"
//                           id="outlined-start-adornment"
//                           sx={{ width: '100%' }}
//                           value={formik.values.last_name}
//                           required
//                           {...formik.getFieldProps('last_name')}
//                         />
//                         {formik.errors.last_name &&
//                           formik.touched.last_name && (
//                             <FormHelperText style={{ color: '#FA5A16' }}>
//                               Please Enter Last Name.
//                             </FormHelperText>
//                           )}
//                       </FormControl>
//                     </Box>

//                     <Box sx={{ display: 'flex' }}>
//                       <Typography
//                         id="modal-modal-title"
//                         variant="h6"
//                         component="h2"
//                         sx={{ px: 1, pb: 1, pt: 1 }}
//                       >
//                         Contact Information
//                       </Typography>
//                     </Box>

//                     <Box sx={{ display: 'flex' }}>
//                       <FormControl
//                         sx={{ width: '50%', px: 1 }}
//                         variant="outlined"
//                       >
//                         <TextField
//                           label="Email"
//                           id="outlined-start-adornment"
//                           sx={{ width: '100%' }}
//                           value={formik.values.email}
//                           required
//                           {...formik.getFieldProps('email')}
//                         />
//                         {formik.errors.email && formik.touched.email && (
//                           <FormHelperText style={{ color: '#FA5A16' }}>
//                             {formik.errors.email}
//                           </FormHelperText>
//                         )}
//                       </FormControl>
//                       <FormControl
//                         sx={{ width: '50%', px: 1 }}
//                         variant="outlined"
//                       >
//                         <TextField
//                           label="Phone"
//                           id="outlined-start-adornment"
//                           sx={{ width: '100%' }}
//                           value={formik.values.phone}
//                           required
//                           {...formik.getFieldProps('phone')}
//                         />
//                         {formik.errors.phone && formik.touched.phone && (
//                           <FormHelperText style={{ color: '#FA5A16' }}>
//                             Please Enter Phone.
//                           </FormHelperText>
//                         )}
//                       </FormControl>
//                     </Box>

//                     <Box sx={{ display: 'flex' }}>
//                       <Typography
//                         id="modal-modal-title"
//                         variant="h6"
//                         component="h2"
//                         sx={{ px: 1, pb: 1, pt: 1 }}
//                       >
//                         Location
//                       </Typography>
//                     </Box>

//                     <Box sx={{ display: 'flex' }}>
//                       <FormControl
//                         sx={{ width: '50%', px: 1 }}
//                         variant="outlined"
//                       >
//                         <InputLabel id="demo-simple-select-label">
//                           States
//                         </InputLabel>
//                         <Select
//                           labelId="demo-simple-select-label"
//                           id="state"
//                           name="state"
//                           value={formik.values.state}
//                           defaultValue={formik.values.state}
//                           label="State"
//                           required
//                           {...formik.getFieldProps('state')}
//                         >
//                           {states.map((list) => {
//                             return (
//                               <MenuItem key={list.id} value={list.id}>
//                                 {list.name}
//                               </MenuItem>
//                             );
//                           })}
//                         </Select>
//                         {formik.errors.state && formik.touched.state && (
//                           <FormHelperText style={{ color: '#FA5A16' }}>
//                             Please choose a state.
//                           </FormHelperText>
//                         )}
//                       </FormControl>

//                       <FormControl
//                         sx={{ width: '50%', px: 1 }}
//                         variant="outlined"
//                       >
//                         <TextField
//                           label="City"
//                           id="outlined-start-adornment"
//                           sx={{ width: '100%' }}
//                           value={formik.values.city}
//                           required
//                           {...formik.getFieldProps('city')}
//                         />
//                         {formik.errors.city && formik.touched.city && (
//                           <FormHelperText style={{ color: '#FA5A16' }}>
//                             Please Enter City.
//                           </FormHelperText>
//                         )}
//                       </FormControl>
//                     </Box>

//                     {formik.errors.email && formik.touched.email && (
//                       <Grid
//                         sx={{
//                           pt: 1,
//                           pb: 1,
//                         }}
//                       >
//                         <Stack sx={{ width: '100%', px: 1, pt: 1 }} spacing={2}>
//                           <Alert
//                             severity="error"
//                             style={{
//                               color: 'red',
//                               backgroundColor: 'rgb(250, 238, 222)',
//                             }}
//                           >
//                             <span style={{ fontWeight: 'bold' }}>
//                               {' '}
//                               Attention{' '}
//                             </span>
//                             <br />
//                             Please note this Owner is already in our system. No
//                             referral gift will be awarded.
//                           </Alert>
//                         </Stack>
//                       </Grid>
//                     )}

//                     <Box sx={{ flexGrow: 5, py: 5, mt: 15 }}>
//                       <Grid container>
//                         <Grid item xs={8}></Grid>
//                         <Grid item xs={4}>
//                           <LoadingButton
//                             color="warning"
//                             variant="contained"
//                             style={{
//                               padding: '5px 20px',
//                               margin: '2px',
//                               borderRadius: '4px',
//                               transition: 'background-color 0.3s',
//                               color: 'black',
//                               backgroundColor: '#f0ecec',
//                             }}
//                             onClick={onClose}
//                           >
//                             Cancel
//                           </LoadingButton>
//                           <LoadingButton
//                             color="primary"
//                             variant="contained"
//                             style={{
//                               padding: '5px 20px',
//                               borderRadius: '4px',
//                               transition: 'background-color 0.3s',
//                               color: '#fff',
//                               backgroundColor: '#FA5A16',
//                             }}
//                             loading={loading}
//                             done={finished}
//                             // onClick={saveReferal}
//                             onClick={formik.handleSubmit}
//                           >
//                             Confirm
//                           </LoadingButton>
//                         </Grid>
//                       </Grid>
//                     </Box>
//                   </form>
//                 </FormikProvider>
//               </Box>
//             </Grid>
//           </Grid>
//         </Box>
//       </DialogContent>
//     </Dialog>
//   );
// };

// export default OwnerReferralModal;

import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  CardMedia,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Alert,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingButton from '../../components/General/LoadingButton';
import referralOwner from '../../assets/images/refer-a-owner.png';
import { API_BASE_URL } from '../../config/constants';

const OwnerReferralModal = ({
  isOpen,
  onClose,
  successModal,
  errorModal,
  getReferrals,
  setSuccessMessage,
}) => {
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm')); // Adjust for small screens

  const schema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    phone: Yup.string().required('Enter Phone Number'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    email: Yup.string()
      .min(5, 'Must be at least 8 characters')
      .max(20, 'Must be less than 20 characters')
      .required('Email is required')
      .test('Unique Email', 'Email already exists', function (value) {
        return new Promise((resolve) => {
          axios
            .get(`${API_BASE_URL}/available/${value}`)
            .then(() => resolve(true))
            .catch(() => resolve(false));
        });
      }),
  });

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      state: '',
      city: '',
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values) => handleSave(values),
  });

  const handleSave = async (data) => {
    setLoading(true);
    data.referral_user_type = 'owner';
    try {
      const res = await axios.post(`${API_BASE_URL}/referral`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      });
      setSuccessMessage('Owner Added Successfully');
      successModal();
      getReferrals();
      onClose();
    } catch (err) {
      errorModal();
    } finally {
      setLoading(false);
    }
  };

  const getStates = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/states`);
      setStates(response.data.data);
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };

  useEffect(() => {
    getStates();
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth="lg"
      fullWidth
      sx={{ margin: '2rem' }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <Grid container sx={{ flexDirection: fullScreen ? 'column' : 'row' }}>
          {/* Image Section */}
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                height: fullScreen ? '100px' : '100%',
                backgroundImage: `url(${referralOwner})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            ></Box>
          </Grid>

          {/* Form Section */}
          <Grid item xs={12} sm={8} sx={{ padding: fullScreen ? 2 : 5 }}>
            <Box sx={{ pb: 4 }}>
              <Typography variant="h4">Refer a Dental Practice</Typography>
            </Box>

            <FormikProvider value={formik}>
              <form>
                <Typography variant="h6">Profile Information</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: fullScreen ? 'column' : 'row',
                  }}
                >
                  <FormControl fullWidth sx={{ mb: 2, mr: fullScreen ? 0 : 1 }}>
                    <TextField
                      label="First Name"
                      {...formik.getFieldProps('first_name')}
                      error={
                        formik.touched.first_name &&
                        Boolean(formik.errors.first_name)
                      }
                      helperText={
                        formik.touched.first_name && formik.errors.first_name
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2, ml: fullScreen ? 0 : 1 }}>
                    <TextField
                      label="Last Name"
                      {...formik.getFieldProps('last_name')}
                      error={
                        formik.touched.last_name &&
                        Boolean(formik.errors.last_name)
                      }
                      helperText={
                        formik.touched.last_name && formik.errors.last_name
                      }
                    />
                  </FormControl>
                </Box>

                <Typography variant="h6">Contact Information</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: fullScreen ? 'column' : 'row',
                  }}
                >
                  <FormControl fullWidth sx={{ mb: 2, mr: fullScreen ? 0 : 1 }}>
                    <TextField
                      label="Email"
                      {...formik.getFieldProps('email')}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2, ml: fullScreen ? 0 : 1 }}>
                    <TextField
                      label="Phone"
                      {...formik.getFieldProps('phone')}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                  </FormControl>
                </Box>

                <Typography variant="h6">Location</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: fullScreen ? 'column' : 'row',
                  }}
                >
                  <FormControl fullWidth sx={{ mb: 2, mr: fullScreen ? 0 : 1 }}>
                    <InputLabel>State</InputLabel>
                    <Select
                      label="State"
                      {...formik.getFieldProps('state')}
                      error={
                        formik.touched.state && Boolean(formik.errors.state)
                      }
                    >
                      {states.map((list) => (
                        <MenuItem key={list.id} value={list.id}>
                          {list.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.state && formik.errors.state && (
                      <FormHelperText error>
                        {formik.errors.state}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2, ml: fullScreen ? 0 : 1 }}>
                    <TextField
                      label="City"
                      {...formik.getFieldProps('city')}
                      error={formik.touched.city && Boolean(formik.errors.city)}
                      helperText={formik.touched.city && formik.errors.city}
                    />
                  </FormControl>
                </Box>

                {formik.errors.email && formik.touched.email && (
                  <Alert severity="error" sx={{ mb: 2 }}>
                    Please note this Owner is already in our system. No referral
                    gift will be awarded.
                  </Alert>
                )}

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: 2,
                    columnGap: { xs: 2, sm: 1 },
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    color="warning"
                    sx={{
                      mr: { xs: '0', sm: '2' },
                      flexGrow: fullScreen ? '1' : '0',
                    }}
                    onClick={onClose}
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    loading={loading}
                    onClick={formik.handleSubmit}
                    sx={{
                      flexGrow: fullScreen ? '1' : '0',
                    }}
                  >
                    Confirm
                  </LoadingButton>
                </Box>
              </form>
            </FormikProvider>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default OwnerReferralModal;
