import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
    Box,
    Button,
    Dialog,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import React from 'react';

const ChangeTimeDialog = ({
  changeTimeModal,
  setChangeTimeModal,
  handleTabChange,
}) => {
  return (
    <Dialog
      open={changeTimeModal}
      onClose={() => setChangeTimeModal(false)}
      fullWidth
      maxWidth="sm"
    >
      <Box sx={{ padding: '20px' }}>
        <div className="d-flex justify-content-between align-items-center">
          <Typography variant="h6">Change Time</Typography>
          <IconButton onClick={() => setChangeTimeModal(false)}>
            <CloseIcon />
          </IconButton>
        </div>

        <Grid container sx={{ backgroundColor: '#FFF3EE', p: 2 }}>
          <Grid item xs={2}>
            <WarningAmberIcon color="error" />
          </Grid>
          <Grid item xs={10}>
            <Typography variant="subtitle1" color="error" fontWeight="bold">
              Warning!
            </Typography>
            <Typography variant="body2" color="error">
              If you want to change start and end times, you need to switch to
              <b> complex schedule</b>. Do you want to proceed?
            </Typography>
          </Grid>
        </Grid>

        <div className="d-flex justify-content-end mt-3">
          <Button onClick={() => setChangeTimeModal(false)}>Cancel</Button>
          <Button
            onClick={() => {
              handleTabChange(null, 2);
              setChangeTimeModal(false);
            }}
          >
            Confirm
          </Button>
        </div>
      </Box>
    </Dialog>
  );
};

export default ChangeTimeDialog;
