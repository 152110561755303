import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@mui/icons-material/Close';
import Star from '@mui/icons-material/Star';
import {
  Box,
  Button,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Rating from '@mui/material/Rating';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  drawer: {
    maxWidth: 340,
    width: '100%',
    flexShrink: 0,
  },
  drawerPaper: {
    padding: theme.spacing(2),
    maxWidth: 340,
    width: '100%',
  },
  heading: {
    margin: theme.spacing(2),
  },
  specialtyBox: {
    margin: theme.spacing(1),
  },

  ratingBox: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    minWidth: '100%',
  },
}));

const specialties = [
  { id: 0, name: 'Office Manager' },
  { id: 1, name: 'Receptionist' },
  { id: 2, name: 'Treatment Coordinator' },
  { id: 3, name: 'Insurance Biller' },
  { id: 4, name: 'Patient Coordinator' },
  { id: 5, name: 'Financial Coordinator' },
];

function ApplicantsFilterSidebar({
  fetchFilterApplicants,
  resetFilter,

  isSidebarOpen,
  setIsSidebarOpen,

  selectRating,
  setSelectRating,

  specialty,
  setSpecialty,

  selectStatus,
  setSelectStatus,

  desiredRate,
  setDesiredRate,
}) {
  const classes = useStyles();

  // const handleSelectSpeciality = (speciality) => {
  //   const { id } = speciality;
  //   if (selectSpeciality.includes(id)) {
  //     setSelectSpeciality(selectSpeciality.filter((item) => item !== id));
  //   } else {
  //     setSelectSpeciality([...selectSpeciality, id]);
  //   }
  // };

  const handleSelectRating = (event) => {
    const {
      target: { value },
    } = event;
    setSelectRating(typeof value === 'string' ? value.split(',') : value);
  };

  const handleSelectStatus = (event) => {
    const {
      target: { value },
    } = event;
    setSelectStatus(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <Drawer
      anchor="right"
      open={isSidebarOpen}
      onClose={() => setIsSidebarOpen(false)}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="1rem"
      >
        <Typography variant="h5" style={{ fontWeight: 500 }}>
          Filters
        </Typography>
        <IconButton onClick={() => setIsSidebarOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <TextField
          variant="outlined"
          fullWidth
          label="Specialty"
          id="specialty"
          value={specialty}
          onChange={(e) => setSpecialty(e.target.value)}
          InputProps={{
            style: {
              textAlign: 'center',
            },
          }}
        />

        <TextField
          variant="outlined"
          fullWidth
          label="Desired Rate"
          id="desired_rate"
          value={desiredRate}
          onChange={(e) => setDesiredRate(e.target.value)}
          InputProps={{
            style: {
              textAlign: 'center',
            },
          }}
        />
        <FormControl>
          <InputLabel id="name-label">Average Score</InputLabel>
          <Select
            style={{
              textAlign: 'center',
            }}
            label={'Rating'}
            labelId="Rating-select-label"
            id="Rating-select-label"
            multiple
            value={selectRating}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <>
                    {value},
                    <Star sx={{ color: 'orange' }} />
                  </>
                ))}
              </Box>
            )}
            onChange={handleSelectRating}
          >
            <MenuItem value={5}>
              <Rating name="read-only" value={5} readOnly />
            </MenuItem>
            <MenuItem value={4}>
              <Rating name="read-only" value={4} readOnly />
            </MenuItem>
            <MenuItem value={3}>
              <Rating name="read-only" value={3} readOnly />
            </MenuItem>
            <MenuItem value={2}>
              <Rating name="read-only" value={2} readOnly />
            </MenuItem>
            <MenuItem value={1}>
              <Rating name="read-only" value={1} readOnly />
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ marginBottom: '1rem' }}>
          <InputLabel id="name-label">Status</InputLabel>
          <Select
            style={{
              width: '100%',
            }}
            label={'Status'}
            labelId="Status-select-label"
            id="Status-select-label"
            value={selectStatus}
            onChange={handleSelectStatus}
          >
            <MenuItem value="hired">Hired</MenuItem>
            <MenuItem value="applied">Applied</MenuItem>
            <MenuItem value="approved">Approved</MenuItem>
          </Select>
        </FormControl>
      </div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '2rem',
        }}
      >
        <Button
          variant="outlined"
          onClick={resetFilter}
          style={{
            color: '#2D62ED',
            width: '100%',
            marginRight: '1rem',
          }}
        >
          Reset
        </Button>
        <Button
          onClick={fetchFilterApplicants}
          variant="contained"
          style={{
            backgroundColor: '#2D62ED',
            color: 'white',
            width: '100%',
          }}
        >
          Find
        </Button>
      </Box>
    </Drawer>
  );
}

export default ApplicantsFilterSidebar;
