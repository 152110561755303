export const MuiFormLabel = {
  // MuiFormLabel: {
  //   styleOverrides: {
  //     asterisk: {
  //       color: '#FA5A16', // Global asterisk color for required fields
  //     },
  //   },
  // },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        top: '-5px',
      },
      shrink: {},
      asterisk: {
        color: '#FA5A16', // Apply the custom color to asterisk
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {},
    },
  },
};
