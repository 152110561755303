import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Layout from '../../../components/Layout';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { Autocomplete, IconButton } from '@mui/material';
import axios from 'axios';
import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { TagsInput } from 'react-tag-input-component';
import isEmailValidator from 'validator/lib/isEmail';
import * as Yup from 'yup';
import ErrorModal from '../../../components/General/ErrorModal';
import SuccessModal from '../../../components/General/SuccessModal';
import { selectUser, setUser } from '../../../redux/slices/userSlice';
import ExperienceInputs from './ExperienceInputs';
import { API_BASE_URL } from '../../../config/constants';

const langs = [
  {
    name: 'one',
    id: 1,
  },
  {
    name: 'two',
    id: 2,
  },
  {
    name: 'three',
    id: 3,
  },
];

const SkillsAndExperience = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [progressValue, setProgressValue] = useState(0);
  const [progressColor, setProgressColor] = useState('#FA5A16');
  const [submission, setSubmission] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openFailModal, setOpenFailModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [universityName, setUniversityName] = useState(user.university_name);
  const [degreeName, setDegreeName] = useState(user.degree_name);
  const [degreeOptions, setDegreeOptions] = useState([]);
  const [educationOptions, setEducationOptions] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [userSkills, setUserSkills] = useState(() => {
    let user_skills = [];
    if (Array.isArray(user.user_skills)) {
      user.user_skills.map((value, index) => {
        user_skills.push(value);
      });
    }
    return user_skills;
  });

  const [experiences, setExperiences] = useState(() => {
    let experiences = [];
    if (Array.isArray(user.user_experiences)) {
      user.user_experiences.map((list) => {
        experiences.push({
          company_name: list.company_name,
          start_date: list.start_date,
          end_date: list.end_date,
          description: list.description,
          contact_email: list.contact_email,
          contact_name: list.contact_name,
          contact_phone: list.contact_phone,
        });
      });
    }

    if (user.user_experiences.length == 0) {
      experiences.push({
        company_name: '',
        start_date: '',
        end_date: '',
        description: '',
        contact_email: '',
        contact_name: '',
        contact_phone: '',
      });
    }
    return experiences;
  });

  const [userLanguages, setUserLanguages] = useState(() => {
    let user_languages = [];
    if (Array.isArray(user.user_languages)) {
      user.user_languages.map((value, index) => {
        user_languages.push(value);
      });
    }
    return user_languages;
  });

  const schema = Yup.object().shape({
    university_name: Yup.string().required('Educataion is required'),
    // degree_name: Yup.string().required("Highest Degree is required"),
    // user_languages: Yup.string().required("Language is required"),
    user_languages: Yup.array()
      .min(1, 'Days of the week are required')
      .required('required'),
    // user_skills: Yup.string().required("Skills are required"),
    user_skills: Yup.array().min(1, 'At least one skill').required('required'),
    // contact_email: Yup.string().email("Email Format is not correct"),
    user_experiences: Yup.array().of(
      Yup.object().shape({
        company_name: Yup.string().required('Enter Company Name'),
        start_date: Yup.string().required('Select Start Date'),
        end_date: Yup.string().required('Select End Date'),
        description: Yup.string().required('Enter Description'),
        contact_email: Yup.string()
          .email('Email Format is not correct')
          .required('Enter Email ID')
          .test(
            'is-valid',
            (message) => `Email is invalid`,
            (value) =>
              value
                ? isEmailValidator(value)
                : new Yup.ValidationError('Invalid value')
          ),
        contact_name: Yup.string().required('Enter Name'),
        contact_phone: Yup.string().required('Enter Phone Number'),
      })
    ),
  });

  const getEducation = async () => {
    try {
      const eduRes = await axios.get(`${API_BASE_URL}/educations`);

      const eduArray = Object.values(eduRes.data.data).map((edu) => ({
        id: edu.id,
        name: edu.name,
      }));
      setEducationOptions(eduArray);
    } catch (error) {
      console.error(error);
    }
  };

  const getDegree = async () => {
    try {
      const degreeRes = await axios.get(`${API_BASE_URL}/degrees`);

      const degreeArray = Object.values(degreeRes.data.data).map((degree) => ({
        id: degree.id,
        name: degree.name,
      }));
      setDegreeOptions(degreeArray);
    } catch (error) {
      console.error(error);
    }
  };

  const getLanguages = async () => {
    axios
      .get(`${API_BASE_URL}/languages`)
      .then((response) => {
        setLanguages(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching languages:', error);
      });
  };

  // let experiences = [];
  // useEffect(() => {
  //   user.user_experiences.map((list) => {
  //     experiences.push({
  //       company_name: list.company_name,
  //       start_date: list.start_date,
  //       end_date: list.end_date,
  //       description: list.description,
  //       contact_email: list.contact_email,
  //       contact_name: list.contact_name,
  //       contact_phone: list.contact_phone,
  //     });
  //   });
  // }, [experiences]);

  const formik = useFormik({
    initialValues: {
      university_name: universityName,
      user_languages: userLanguages,
      degree_name: degreeName,
      user_skills: userSkills,
      user_experiences: experiences,
    },

    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      handleSave(values);
    },
  });

  const handleSave = async (data) => {
    setIsLoading(true);
    await axios
      .put(`${API_BASE_URL}/profile/basic-info`, data, {
        headers: {
          method: 'PUT',
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      })
      .then((res) => {
        const updatedUser = {
          ...user,
          university_name: formik.values.university_name,
          user_skills: res.data.data.user_skills,
          user_experiences: res.data.data.user_experiences,
          user_languages: res.data.data.user_languages,
        };

        dispatch(setUser(updatedUser));
        // console.log("user ", user);
        // dispatch(setUser(res.data.data));
        localStorage.setItem('user', JSON.stringify(res.data.data));
        setSubmission(true);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(`Error!`);
        setIsLoading(false);
        setOpenFailModal(true);
      });
  };

  const addExperiences = (values, setValues) => {
    const user_experiences = [...values.user_experiences];
    user_experiences.push({
      company_name: '',
      start_date: '',
      end_date: '',
      description: '',
      contact_email: '',
      contact_name: '',
      contact_phone: '',
    });
    setValues({ ...values, user_experiences });
  };

  const updateUserExperiences = (values, setValues) => {
    const user_experiences = [...values.user_experiences];
    user.user_experiences.map((list) => {
      user_experiences.push({
        company_name: list.company_name,
        start_date: list.start_date,
        end_date: list.end_date,
        description: list.description,
        contact_email: list.contact_email,
        contact_name: list.contact_name,
        contact_phone: list.contact_phone,
      });
    });
    setValues({ ...values, user_experiences });
  };

  const removeExperience = (i, values, setValues) => {
    const user_experiences = [...values.user_experiences];
    user_experiences.splice(i, 1);
    setValues({ ...values, user_experiences });
  };

  const progress = () => {
    const group1Completed =
      !!formik.values.university_name &&
      !!formik.values.user_languages &&
      !!formik.values.degree_name;
    const group2Completed = !!formik.values.user_skills;
    const group3Completed = !!formik.values.user_experiences;
    const totalGroups = 3;
    const completedGroups = [
      group1Completed,
      group2Completed,
      group3Completed,
    ].filter(Boolean).length;

    const newProgress = (completedGroups / totalGroups) * 100;
    setProgressValue(Math.ceil(newProgress));

    if (newProgress === 75) setProgressColor('#FFCF33');
    if (group2Completed && !group1Completed && !group3Completed)
      setProgressColor('#FFCF33');
    else if (group1Completed && group3Completed && !group2Completed)
      setProgressColor('#FFCF33');
    else if ((group1Completed || group3Completed) && !group2Completed)
      setProgressColor('#FA5A16');
    else if (group1Completed && group2Completed && group3Completed)
      setProgressColor('#4CAF50');
  };

  useEffect(() => {
    progress();
    getEducation();
    getDegree();
    getLanguages();
  }, []);

  return (
    <Layout>
      <Grid
        sx={{
          px: 3,
          borderBottom: '1px solid #D9D9D9',
          width: 'auto',
          color: '#8C8C8C',
          fontSize: '0.9rem',
        }}
      >
        <PermIdentityIcon sx={{ py: 0.2, my: 0.2, mr: 0.9 }} />
        My Account
      </Grid>
      <div
        className="d-flex"
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #D9D9D9',
        }}
      >
        <Grid
          sx={{
            px: 3,
            pt: 2,
            pb: 1,
            mb: 3,
            mt: 1,
            width: 'auto',
          }}
        >
          <h4 className="pb-0 mb-1" style={{ color: '#262626' }}>
            My Account
          </h4>
          <p style={{ color: '#8C8C8C', fontSize: '0.8rem' }}>
            Skills and Experience
          </p>
        </Grid>
        <div></div>
      </div>

      <div
        style={{
          borderBottom: '1px solid #D9D9D9',
        }}
      >
        <Grid
          sx={{
            px: 3,
            pt: 2,
            pb: 1,
            mb: 3,
            mt: 1,
            width: 'auto',
          }}
        >
          {/* <Grid item rowGap={2}>
            <Grid
              sm={12}
              display={"flex"}
              justifyContent={"space-between"}
              fontSize={"14px"}
            >
              <Typography>{progressValue}% completed</Typography>
            </Grid>

            <Grid sm={12}>
              <LinearProgress
                sx={{
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: `${progressColor} !important`,
                  },
                  background: "#E8E8E8",
                }}
                variant="determinate"
                value={progressValue}
              />
            </Grid>
          </Grid> */}

          {/* <form onSubmit={formik.handleSubmit}> */}
          <FormikProvider value={formik}>
            <form>
              <Grid container marginTop={6}>
                <Grid md={8}>
                  <Typography fontSize={'16px'} fontWeight={'semi-bold'}>
                    Basic Information
                  </Typography>

                  <Grid sm={11} mt={2}>
                    <Grid
                      container
                      gap={1}
                      sm={12}
                      justifyContent={'space-between'}
                    >
                      <Grid xs={12} sm={6} lg={4}>
                        <FormControl
                          sx={{ p: 0 }}
                          size="small"
                          variant="outlined"
                          className="my-form-control"
                          required
                          fullWidth
                        >
                          <InputLabel id="university-name-label">
                            Education
                          </InputLabel>

                          <Select
                            labelId="university-name-label"
                            id="university_name"
                            name="university_name"
                            value={formik.values.university_name}
                            defaultValue={formik.values.university_name}
                            label="Education"
                            required
                            {...formik.getFieldProps('university_name')}
                          >
                            {educationOptions.map((list) => {
                              return (
                                <MenuItem key={list.id} value={list.id}>
                                  {list.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {formik.errors.university_name &&
                            formik.touched.university_name && (
                              <FormHelperText style={{ color: '#FA5A16' }}>
                                Please choose an option
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>

                      {/* <Grid sm={6} lg={6}>
                        {" "}
                        <FormControl
                          sx={{ p: 0 }}
                          size="small"
                          variant="outlined"
                          className="my-form-control"
                          required
                          fullWidth
                        >
                          <InputLabel id="demo-simple-select-label">
                            Highest Degree Earned
                          </InputLabel>
                          <Select
                            labelId="degree-select-label"
                            id="highest_degree_earned_id"
                            name="highest_degree_earned"
                            value={formik.values.degree_name}
                            // defaultValue={formik.values.university_name}
                            label="highest_degree_earned"
                            required
                            {...formik.getFieldProps("degree_name")}
                          >
                            {degreeOptions.map((list, index) => {
                              return (
                                <MenuItem
                                  key={list.id}
                                  value={list.id}>
                                  {list.name}
                                </MenuItem>
                              );
                            })}
                          </Select>

                          {formik.errors.degree_name && formik.touched.degree_name && (
                            <FormHelperText style={{ color: "#FA5A16" }}>
                              Please choose an option.
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid> */}

                      <Grid xs={12}>
                        <FormControl
                          sx={{ p: 0, mt: 2 }}
                          size="small"
                          variant="outlined"
                          className="my-form-control"
                          required
                          fullWidth
                        >
                          <Autocomplete
                            multiple
                            id="user_languages"
                            options={languages.map(
                              (option) => option.language_name
                            )}
                            defaultValue={userLanguages}
                            disableCloseOnSelect
                            required
                            // {...formik.getFieldProps("user_languages")}
                            onChange={(event, value) => {
                              setUserLanguages(value);
                              formik.setFieldValue(
                                'user_languages',
                                userLanguages
                              );
                            }}
                            renderOption={(props, option, { selected }) => {
                              return (
                                <MenuItem
                                  key={option}
                                  value={option}
                                  sx={{ justifyContent: 'space-between' }}
                                  {...props}
                                >
                                  {option}
                                  {selected ? <CheckIcon color="info" /> : null}
                                </MenuItem>
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Languages"
                                placeholder="Languages"
                              />
                            )}
                          />

                          {formik.errors.user_languages &&
                            formik.touched.user_languages && (
                              <FormHelperText style={{ color: '#FA5A16' }}>
                                Please Select Language.
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>

                      <br />
                      <Grid xs={12}>
                        <FormControl
                          sx={{ p: 0 }}
                          size="small"
                          variant="outlined"
                          className="my-form-control"
                          required
                          fullWidth
                        >
                          {/* <InputLabel
                            sx={{
                              // background: "white",
                              paddingX: "4px",
                              fontSize: "13px",
                              paddingTop: "2px",
                            }}
                            id="demo-simple-select-label"
                          >
                            Your Key Skills
                          </InputLabel> */}

                          {/* <TextField
                            id='outlined-basic'
                            label='Last Name.'
                            variant='outlined'
                            required={true}
                            value={User && name.last_name}
                            onChange={(e) => {
                              setName({ ...name, last_name: e.target.value });
                            }}
                            style={{ width: '200px', margin: '10px 20px 10px 0' }}
                          /> */}

                          <Typography
                            fontSize={'16px'}
                            fontWeight={'semi-bold'}
                          >
                            Your Key Skills
                          </Typography>

                          <TagsInput
                            value={userSkills}
                            onChange={(user_skills) => {
                              setUserSkills(user_skills);
                              formik.setFieldValue('user_skills', userSkills);
                            }}
                            name="user_skills"
                            id="user_skills"
                            placeHolder="Enter Skills"
                          />
                          {formik.errors.user_skills &&
                            formik.touched.user_skills && (
                              <FormHelperText style={{ color: '#FA5A16' }}>
                                Please Enter Skills.
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Typography mt={3} fontSize={'16px'} fontWeight={'semi-bold'}>
                    <div
                      style={{
                        // gap: '470px',
                        width: '100%',
                        // display: 'flex',
                        // justifyContent: 'flex-start',
                        // alignItems: 'center',
                      }}
                    >
                      <div>Work Experience (Latest 3 Jobs)</div>
                      <div>
                        {formik.values.user_experiences.length < 3 ? (
                          <>
                            {/* <IconButton
                              onClick={(e) => addExperiences(formik.values, formik.setValues)}
                              style={{
                                backgroundColor: '#d7edeb',
                                padding: '2px 15px 2px 15px',
                                borderRadius: '4px',
                                textAlign: 'center',
                                fontSize: '16px',
                                fontWeight: '400',
                                border: '1px solid #2561B0',
                                color: '#fff',
                                right: 0,
                              }}
                            >
                              <AddCircleOutlineSharpIcon color='secondary' />
                            </IconButton> */}
                            <button
                              onClick={(e) =>
                                addExperiences(formik.values, formik.setValues)
                              }
                              style={{
                                backgroundColor: '#2561B0',
                                color: '#fff',
                                border: 'none',
                                borderRadius: '4px',
                                padding: '3px 10px',
                              }}
                            >
                              <AddIcon
                                style={{
                                  border: '1px solid #fff',
                                  color: '#fff',
                                  padding: '0',
                                  fontSize: '16px',
                                  marginRight: '5px',
                                  fontWeight: 'bold',
                                }}
                              />
                              Add
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </Typography>

                  {formik.values.user_experiences.map((object, index) => {
                    let id_input = 'company_name_' + index;
                    return (
                      <>
                        <Grid
                          sm={11}
                          mt={2}
                          mb={5}
                          px={3}
                          py={3}
                          container
                          position={'relative'}
                          style={{
                            boxShadow: '0px 4px 12px 0px #00000026',
                            borderRadius: '3px',
                          }}
                        >
                          <Typography
                            fontSize={'14px'}
                            fontWeight={'semi-bold'}
                          >
                            Work Experience {index + 1}
                          </Typography>

                          {formik.values.user_experiences.length > 1 && (
                            <>
                              <IconButton
                                sx={{
                                  position: 'absolute',
                                  top: '-10px',
                                  right: '-10px',
                                  // not necessary, just added this coz it looks weird
                                  background: 'red',
                                }}
                                color="error"
                                aria-label="add to shopping cart"
                                onClick={() =>
                                  removeExperience(
                                    index,
                                    formik.values,
                                    formik.setValues
                                  )
                                }
                              >
                                <CloseIcon
                                  sx={{
                                    color: 'white',
                                  }}
                                />
                              </IconButton>
                            </>
                          )}

                          <Grid
                            container
                            sm={12}
                            justifyContent={'space-between'}
                            mt={1}
                            rowGap={1}
                          >
                            <Grid xs={12} sm={6} lg={4}>
                              <ExperienceInputs
                                name={`user_experiences.${index}.company_name`}
                                placeholder="Company Name"
                                type="text"
                              />
                            </Grid>

                            <Grid xs={12} sm={6} lg={4} px={{ sm: 2 }}>
                              <ExperienceInputs
                                label={'Start Date'}
                                name={`user_experiences.${index}.start_date`}
                                placeholder="Select Date"
                                type="date"
                                value={object.start_date}
                                onChange={(date) => {
                                  formik.setFieldValue(
                                    `user_experiences.${index}.start_date`,
                                    date
                                  );
                                }}
                              />
                            </Grid>

                            <Grid xs={12} sm={6} lg={4}>
                              <ExperienceInputs
                                label={'End Date'}
                                name={`user_experiences.${index}.end_date`}
                                placeholder="Select Date"
                                type="date"
                                value={object.end_date}
                                onChange={(date) => {
                                  formik.setFieldValue(
                                    `user_experiences.${index}.end_date`,
                                    date
                                  );
                                }}
                              />
                            </Grid>

                            <Grid xs={12}>
                              <FormControl
                                sx={{ p: 0, mt: 2 }}
                                size="small"
                                variant="outlined"
                                className="my-form-control"
                                required
                                fullWidth
                              >
                                {/* <InputLabel
                                  sx={{
                                    background: "white",
                                    paddingX: "4px",
                                    fontSize: "13px",
                                    paddingTop: "2px",
                                  }}
                                  id="demo-simple-select-label"
                                >
                                  Function Performed
                                </InputLabel> */}

                                <Typography
                                  fontSize={'16px'}
                                  fontWeight={'semi-bold'}
                                >
                                  Functions Performed
                                </Typography>

                                <ExperienceInputs
                                  name={`user_experiences.${index}.description`}
                                  placeholder="Description"
                                  type="textarea"
                                  value={object.description}
                                />
                              </FormControl>
                            </Grid>

                            <Typography
                              mt={2}
                              fontSize={'14px'}
                              fontWeight={'semi-bold'}
                            >
                              Contact Information of Employer
                            </Typography>

                            <Grid container xs={12} rowGap={3}>
                              <Grid xs={12} sm={6} lg={4}>
                                <ExperienceInputs
                                  name={`user_experiences.${index}.contact_name`}
                                  placeholder="Name"
                                  type="text"
                                />
                              </Grid>
                              <Grid xs={12} sm={6} lg={4} px={{ sm: 2 }}>
                                <ExperienceInputs
                                  name={`user_experiences.${index}.contact_email`}
                                  placeholder="Email"
                                  type="email"
                                />
                              </Grid>
                              <Grid xs={12} sm={6} lg={4}>
                                <ExperienceInputs
                                  name={`user_experiences.${index}.contact_phone`}
                                  placeholder="Phone"
                                  type="phone"
                                  value={object.contact_phone}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>

                <Grid xs={12} md={4}>
                  <Grid
                    item
                    px={3}
                    py={2.4}
                    sx={{
                      background: '#E8E8E8',
                      borderRadius: '5px',
                      fontSize: '2px',
                    }}
                    border={'1px solid #E8E8E8'}
                    height={'min-content'}
                  >
                    <Grid container flexWrap={'wrap'}>
                      <Grid
                        fontSize="14px"
                        color="#4CAF50"
                        fontWeight={'semi-bold'}
                      >
                        25%
                      </Grid>
                      <Grid fontSize="14px">
                        &nbsp;&nbsp;Add Basic Information
                      </Grid>
                    </Grid>

                    <Grid container mt={1} flexWrap={'wrap'}>
                      <Grid
                        fontSize="14px"
                        color="#4CAF50"
                        fontWeight={'semi-bold'}
                      >
                        50%
                      </Grid>
                      <Grid fontSize="14px">
                        &nbsp;&nbsp;Add Work Experience
                      </Grid>
                    </Grid>

                    <Grid container mt={1}>
                      <Grid
                        fontSize="14px"
                        color="#4CAF50"
                        fontWeight={'semi-bold'}
                      >
                        25%
                      </Grid>
                      <Grid fontSize="14px">
                        &nbsp;&nbsp;Information of your last employers
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid mt={5}>
                <Grid>
                  <Button
                    variant="outline-primary"
                    type="submit"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid #2561B0',
                      borderRadius: '6px',
                      color: '#2561B0',
                      width: '160px',
                      whiteSpace: 'nowrap',
                      fontSize: 'bold',
                      height: '40px',
                    }}
                    onClick={formik.handleSubmit}
                  >
                    {isLoading ? 'Saving...' : 'Save Changes'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormikProvider>
        </Grid>
      </div>

      {submission && (
        <SuccessModal
          open={submission}
          handleClose={() => setSubmission(false)}
          successMessage={'Experiences And Skills Updated Successfully'}
        />
      )}
      {openFailModal && (
        <ErrorModal
          open={openFailModal}
          handleClose={() => setOpenFailModal(false)}
          errorMessage={errorMessage}
        />
      )}
    </Layout>
  );
};

export default SkillsAndExperience;
