import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import ApplicantPopup from '../../../../components/General/ApplicantPopup';
import { getStatusStyle } from '../../../../utils/CustomDataGridStyle';
import { capitalizeFirstLetter } from '../../../../utils/helper';
import '../TemporaryJobs/PostingApplicants.css';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import axios from 'axios';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import ShowMoreIcon from '../../../../assets/icons/showmore.png';
import ErrorModal from '../../../../components/General/ErrorModal';
import ProposalHistoryModal from '../../../../components/General/ProposalHistoryModal';
import { API_BASE_URL } from '../../../../config/constants';
import useMobile from '../../../../hooks/useMobile';

const styles = {
  table: {},
  cell: {
    padding: '10px',
  },
  newStatus: {
    backgroundColor: '#75B0FA',
    border: '1px solid #4A93F0',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  activeStatus: {
    backgroundColor: '#4CAF50',
    border: '1px solid #388E3C',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  cancelledStatus: {
    backgroundColor: '#ff9900',
    border: '1px solid #ff9900',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  completedStatus: {
    backgroundColor: '#4CAF50',
    border: '1px solid #388E3C',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  updatedStatus: {
    backgroundColor: '#B6A8FF',
    border: '1px solid #7C67EB',
    borderRadius: '16px',
    padding: '1px 8px',
  },
  appliedStatus: {
    backgroundColor: '#FFC400',
    border: '1px solid #FFC400',
    borderRadius: '16px',
    padding: '1px 8px',
  },
  selectedRow: {
    backgroundColor: '#D7E8FF',
  },
};

// const getStatusStyle = (status, classes) => {
//   switch (status) {
//     case 'new':
//       return classes.newStatus;
//     case 'active':
//       return classes.activeStatus;
//     case 'cancelled':
//       return classes.cancelledStatus;
//     case 'completed':
//       return classes.completedStatus;
//     case 'updated':
//       return classes.updatedStatus;
//     case 'applied':
//       return classes.appliedStatus;
//     case 'accepted':
//       return classes.activeStatus;
//     case 'hired':
//       return classes.activeStatus;
//     default:
//       return status;
//   }
// };

const ProposalsDataGrid = ({
  classes,
  rows,
  columns,
  paging,
  selectedItem,
  setSelectedItem,
  fetchApplicants,
  postingId,
  setInterviewDetailsOpen,
  setActions,
}) => {
  const { total, per_page, current_page } = paging;
  const [page, setPage] = useState(current_page - 1);
  const [rowsPerPage, setRowsPerPage] = useState(per_page);

  const [errorApproveDialog, setErrorApproveDialog] = useState(false);

  const [errorMessages, setErrorMessages] = useState([]);
  const [historyModal, setHistoryModal] = useState(false);

  const navigate = useNavigate();

  const getRate = (user_location) => {
    var rate = user_location.filter((location) => {
      return location.is_current == true;
    });

    if (rate.length != 0) {
      return rate[0].desired_rate;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    setPage(current_page - 1);
    setRowsPerPage(per_page);
  }, [paging, current_page, per_page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSelectedItem(null);
    fetchApplicants(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setSelectedItem(null);
    fetchApplicants(1, newRowsPerPage);
  };

  const handleRowClick = (item) => {
    if (selectedItem && selectedItem.id === item.id) {
      setHistoryModal(false);
      setSelectedItem(null);

      return;
    } else if (selectedItem && selectedItem.id !== item.id) {
      setSelectedItem(item);
      return;
    } else {
      setSelectedItem(item);
      return;
    }
  };

  const [isApplicantDataOpen, setIsApplicantDataOpen] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState(null);

  const handleNameClick = (item) => {
    setSelectedApplicant(item);
    setIsApplicantDataOpen(true);
  };

  const handleApplicantDataClose = () => {
    setSelectedApplicant(null);
    setIsApplicantDataOpen(false);
  };

  const hireApplicant = (id) => {
    if (id) {
      axios
        .put(`${API_BASE_URL}/owner/applicants/${id}/hire`, null, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
          },
        })
        .then((res) => {
          fetchApplicants(page + 1, rowsPerPage);
        })
        .catch((res) => {
          setErrorApproveDialog(true);
          setErrorMessages(res.response.data.message.description);
        });
    }
  };

  const tableRows = rows?.map((item, index) => {
    return {
      id: item.id,
      date: moment(item.date_time).utc().format('MM/DD/YY hh:mm A'),
      hiring_rate: <> $ {item.hiring_rate}</>,
      status: (
        <span style={getStatusStyle(item.proposal_status.toLowerCase())}>
          {capitalizeFirstLetter(item.proposal_status)}
        </span>
      ),
      days: (
        <>
          {item.proposal_posting_schedules.map((day) => {
            return (
              <Chip
                label={capitalizeFirstLetter(day.schedule_day)}
                color="primary"
                sx={{ margin: 0.5 }}
                key={day.id}
              />
            );
          })}
        </>
      ),
      applicant_count: (
        <Button
          variant="outline-primary"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #2561B0',
            borderRadius: '6px',
            color: '#2561B0',
            width: '100%',
            whiteSpace: 'nowrap',
            fontSize: 'bold',
          }}
          onClick={() => {
            setSelectedItem(item);
            setHistoryModal(true);
          }}
        >
          History
        </Button>
      ),
    };
  });

  const isMobile = useMobile(767);
  console.log(selectedItem);
  return (
    <div>
      {!isMobile && (
        <Table className={classes.table} style={{ overflow: 'scroll' }}>
          <TableHead>
            <TableRow>
              {columns.map((item, index) => (
                <TableCell
                  key={index}
                  style={{ width: item.width }}
                  className={classes.cell}
                >
                  {item.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => {
                var statusStyle = getStatusStyle(
                  item.proposal_status.toLowerCase()
                );
                return (
                  <TableRow
                    key={index}
                    onClick={() => handleRowClick(item)}
                    className={
                      selectedItem && selectedItem.id === item.id
                        ? classes.selectedRow
                        : ''
                    }
                  >
                    <TableCell className={classes.cell}>
                      {tableRows[index].date}
                    </TableCell>

                    <TableCell className={classes.cell}>
                      {tableRows[index].hiring_rate}
                    </TableCell>

                    <TableCell className={classes.cell}>
                      {tableRows[index].status}
                    </TableCell>

                    <TableCell className={classes.cell}>
                      {tableRows[index].days}
                    </TableCell>

                    <TableCell className={classes.cell}>
                      {tableRows[index].applicant_count}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      )}

      {isMobile && (
        <Table className={classes.table}>
          <TableBody>
            {tableRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => {
                return (
                  <div
                    style={{
                      margin: isMobile ? '0 0 15px 0' : '0 10px 15px',
                      backgroundColor: '#fff',
                      padding: '0 15px',
                      borderRadius: '6px',
                      boxShadow: '0px 2px 8px 0px #00000026',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                      }}
                    >
                      <TableRow
                        key={row.id}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleRowClick(tableRows[rowIndex])}
                      >
                        <ExpandMoreIcon />
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            padding: '0 10px',
                          }}
                        >
                          <>
                            {Array.from({ length: 2 }, (_, i) => (
                              <TableCell
                                style={{ borderBottom: 'none' }}
                                key={i}
                                className={classes.cell}
                                sx={{ padding: { xs: '8px', sm: '1rem' } }}
                              >
                                {row[columns[i].field] !== undefined
                                  ? row[columns[i].field]
                                  : columns[i].field}
                              </TableCell>
                            ))}
                          </>
                        </div>

                        <TableCell
                          style={{ borderBottom: 'none', marginLeft: '10px' }}
                          key={Math.floor(Math.random() * 10000)}
                          className={classes.cell}
                          sx={{ padding: { xs: '8px', sm: '1rem' } }}
                        >
                          {row[columns[2].field] !== undefined
                            ? row[columns[2].field]
                            : columns[2].field}
                        </TableCell>
                      </TableRow>
                      {setActions && (
                        <div
                          onClick={() => {
                            setSelectedItem(rows[rowIndex]);
                            setActions(true);
                          }}
                          style={{
                            cursor: 'pointer',
                            border: '1px solid #E8E8E8',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            src={ShowMoreIcon}
                            style={{ padding: '10px' }}
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                    {selectedItem && selectedItem.id === rows[rowIndex].id && (
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            marginTop: '10px',
                          }}
                        >
                          <>
                            {Array.from(
                              { length: columns.length },
                              (_, i) =>
                                i >= 3 && (
                                  <TableCell
                                    style={{
                                      borderBottom: 'none',
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                    key={i}
                                    className={classes.cell}
                                    sx={{ padding: { xs: '8px', sm: '1rem' } }}
                                  >
                                    <div>
                                      <p
                                        style={{
                                          fontSize: '13px',
                                          fontFamily: 'Roboto',
                                          color: '#262626',
                                        }}
                                      >
                                        {columns[i]?.headerName}
                                      </p>
                                      <div
                                        style={{
                                          color: '#000000',
                                          fontSize: '15px',
                                          marginTop: '6px',
                                        }}
                                      >
                                        {selectedItem[columns[i]?.field] !==
                                        undefined
                                          ? selectedItem[columns[i]?.field]
                                          : columns[i]?.field}
                                      </div>
                                    </div>
                                  </TableCell>
                                )
                            )}
                          </>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
          </TableBody>
        </Table>
      )}

      {selectedApplicant && (
        <ApplicantPopup
          isOpen={isApplicantDataOpen}
          onClose={handleApplicantDataClose}
          selectedApplicant={selectedApplicant}
        />
      )}

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {errorApproveDialog && (
        <ErrorModal
          open={errorApproveDialog}
          handleClose={() => setErrorApproveDialog(false)}
          errorMessage={errorMessages}
        />
      )}

      {historyModal && (
        <ProposalHistoryModal
          selectedItem={selectedItem}
          open={historyModal}
          handleClose={() => setHistoryModal(false)}
        />
      )}
    </div>
  );
};
export default withStyles(styles)(ProposalsDataGrid);
