import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import * as React from 'react';
import { API_BASE_URL } from '../../../config/constants';

export default function UnblockOfficeModal({
  open,
  handleClose,
  selectedItem,
  fetchData,
}) {
  const authToken = localStorage.getItem('auth_token');

  const handleConfirm = () => {
    if (selectedItem !== null) {
      axios
        .put(`${API_BASE_URL}/offices/${selectedItem.id}/blocked`, null, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          fetchData();
          handleClose();
        })
        .catch((e) => console.log(e));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <div
          className="d-flex"
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Unblock An Office
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Grid item xs={12} md={4}>
            <Grid
              className="rounded"
              container
              spacing={1}
              sx={{ backgroundColor: '#FFF3EE', p: 1 }}
            >
              <Grid item xs={1}>
                <ReportProblemOutlinedIcon
                  sx={{ color: '#FA5A16', fontSize: '20px' }}
                />
              </Grid>
              <Grid item xs={11} sx={{ fontSize: '0.9rem' }}>
                <p style={{ color: '#E54C0B' }} className="fw-semibold mb-0">
                  Attention!
                </p>
                <p style={{ color: '#E54C0B' }}>
                  Are you sure you want to remove LucySmile from the blocked
                  list?
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Typography>
      </DialogContent>
      <DialogActions>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-outline-primary me-2"
            style={{
              border: 0,
              color: '#2561B0',
            }}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-outline-primary"
            style={{
              border: 0,
              color: '#2561B0',
            }}
            onClick={handleConfirm}
          >
            Confirm
          </button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
