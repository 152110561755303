import { Grid } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import RefreshIcon from '../../../../assets/icons/arrow-clockwise.svg';
import FilterIcon from '../../../../assets/icons/filter.svg';
import ApplicantPopup from '../../../../components/General/ApplicantPopup';
import CustomDataGrid from '../../../../components/General/CustomDataGrid';
import StarRating from '../../../../components/General/StarRating';
import Layout from '../../../../components/Layout';
import { API_BASE_URL } from '../../../../config/constants';
import BlacklistModal from '../CurrentlyBooked/BlacklistModal';
import UnblacklistModal from '../CurrentlyBooked/UnblacklistModal';
import FiltersSidebarPreviouslyBooked from './FiltersSidebarPreviouslyBooked';

const PreviouslyBooked = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event, item) => {
    if (anchorEl !== null) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
      setSelectedItem(item);
      setSelectedApplicantId(item);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
  };

  const [selectedItem, setSelectedItem] = useState(null);
  const [banned, setBanned] = useState(null);
  const [filtersSidebar, setFiltersSidebar] = useState(false);
  const [openBlacklistModal, setOpenBlacklistModal] = useState(false);
  const [openUnblacklistModal, setOpenUnblacklistModal] = useState(false);

  const [previouslyBooked, setPreviouslyBooked] = useState({});

  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [isApplicantDataOpen, setIsApplicantDataOpen] = useState(false);
  const [selectedApplicantId, setSelectedApplicantId] = useState(null);
  const [selectRating, setSelectRating] = useState([]);
  const [professional, setProfessional] = useState('');
  const [date, setDate] = useState('');
  const [posting, setPosting] = useState('');
  const [type, setType] = useState('');

  const [actions, setActions] = useState(false);
  const [gridWidth, setGridWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setGridWidth(window.innerWidth < 800 ? true : false);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, [window.innerWidth]);

  const handleNameClick = (item) => {
    setSelectedApplicant(item);
    setIsApplicantDataOpen(true);
  };

  const handleApplicantDataClose = () => {
    setSelectedApplicant(null);
    setIsApplicantDataOpen(false);
  };

  const fetchPreviouslyBooked = (page, limit) => {
    axios
      .get(
        `${API_BASE_URL}/owner/applicants/previously-booked?page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
          },
        }
      )
      .then((response) => {
        setPreviouslyBooked(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchPreviouslyBooked(1, 10);
  }, []);

  const columns = [
    { field: 'professional', headerName: 'Professional', width: 130 },
    { field: 'posting_title', headerName: 'Posting Title', width: 250 },
    { field: 'last_worked_date', headerName: 'Last Worked Date', width: 150 },
    { field: 'blacklisted', headerName: 'Banned', width: 150 },
    { field: 'total_rating', headerName: 'Total Rating', width: 150 },
    // { field: 'actions', headerName: '', width: 50 },
  ];

  const rows = previouslyBooked?.data?.map((item, index) => {
    return {
      id: item.id,
      professional: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <img
            src={`${API_BASE_URL}/assets/${item.user.avatar}`}
            alt=""
            style={{
              width: 30,
              height: 30,
              borderRadius: '50%',
            }}
          />
          <span
            style={{
              color: '#2561B0',
              fontWeight: 400,
              fontSize: '14px',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => {
              handleNameClick(item.user);
            }}
          >
            {item.user.first_name} {item.user.last_name}
          </span>
        </div>
      ),
      posting_title: item.posting.title,
      last_worked_date: item?.posting_schedule?.schedule_date
        ? moment(item.posting_schedule.schedule_date).utc().format('MM/DD/YY')
        : '-',
      blacklisted:
        item.user.is_blocked !== 0 ? (
          <div
            style={{
              backgroundColor: '#FA5A16',
              height: 10,
              width: 10,
              borderRadius: '50%',
            }}
          >
            {/* Banned */}
          </div>
        ) : (
          <>
            {/* <div
              style={{
                backgroundColor: '#FA5A16',
                height: 10,
                width: 10,
                borderRadius: '50%',
              }}
            >Unbanned</div> */}
          </>
        ),
      total_rating: <StarRating rating={item.user.average_score} />,
      // actions: (
      //   <div
      //     style={{
      //       display: 'flex',
      //       gap: 10,
      //       alignItems: 'center',
      //       justifyContent: 'end',
      //     }}
      //     onClick={(event) => handleClick(event, item)}
      //   >
      //     <MoreVertIcon
      //       style={{
      //         color: '#fff',
      //         cursor: 'pointer',
      //         backgroundColor: '#2561B0',
      //         padding: 4,
      //         borderRadius: 4,
      //         fontSize: 31,
      //       }}
      //     />

      //     <Menu
      //       id={index}
      //       anchorEl={anchorEl}
      //       open={open}
      //       onClose={handleClose}
      //       MenuListProps={{
      //         'aria-labelledby': 'basic-button',
      //       }}
      //       style={{
      //         width: 200,
      //       }}
      //     >
      //       <MenuItem
      //         style={{
      //           width: 200,
      //           fontSize: 14,
      //         }}
      //         value={item.posting_id}
      //         onClick={() => {
      //           navigate(
      //             `/owner/booked/currently/${selectedApplicantId.posting_id}/review/${selectedApplicantId.user_id}`
      //           )
      //         }}
      //       >
      //         Review this Candidate
      //       </MenuItem>

      //       {/* {item.user.is_blocked === 0 ? (
      //         <MenuItem
      //           style={{
      //             width: 200,
      //             fontSize: 14,
      //           }}
      //           onClick={() => {
      //             handleClose();
      //             setOpenBlacklistModal(true);
      //             setBanned(selectedItem.user_id);
      //             console.log('item.user_id', item.user_id, selectedItem.user_id);
      //           }}
      //         >
      //           Banned {item.user.is_blocked}
      //         </MenuItem>
      //       ) : (
      //         <MenuItem
      //           style={{
      //             width: 200,
      //             fontSize: 14,
      //           }}
      //           onClick={() => {
      //             handleClose();
      //             setBanned(selectedItem.user_id);
      //             setOpenUnblacklistModal(true);
      //           }}
      //         >
      //           Unbanned
      //         </MenuItem>
      //       )} */}
      //     </Menu>
      //   </div>
      // ),
      ...item,
    };
  });

  const handleFilterData = (page, limit) => {
    let endpoint = `${API_BASE_URL}/owner/applicants/previously-booked?page=${page}&limit=${limit}`;

    if (selectRating) {
      endpoint += `&rating=${selectRating}`;
    }

    if (professional) {
      endpoint += `&professional=${professional}`;
    }

    if (date) {
      endpoint += `&from=${date}`;
    }

    if (posting) {
      endpoint += `&title=${posting}`;
    }

    if (type) {
      endpoint += `&type=${type}`;
    }

    axios
      .get(endpoint, {
        headers: {
          method: 'GET',
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      })
      .then((response) => {
        setPreviouslyBooked(response.data);
        setFiltersSidebar(false);
      })
      .catch((e) => {
        console.log(e);
        setFiltersSidebar(false);
      });
  };

  const resetFilter = () => {
    setProfessional('');
    setPosting('');
    setDate('');
    setSelectRating([]);
    setType('');
    fetchPreviouslyBooked(1, 10);
  };

  const actionButtons = [
    {
      type: 'button',
      label: 'Post / See Review',
      onClick: () => {},
      disabled:
        selectedItem === null || selectedItem.posting_status === 'cancelled',
      backgroundColor: '#fff',
      borderColor: '#2561B0',
      color: '#595959',
      marginTop: '16px',
    },
    {
      type: 'button',
      label: 'Hire for Permanent Job',
      onClick: () => {},
      disabled: selectedItem === null || selectedItem.blacklisted === true,
      backgroundColor: '#fff',
      borderColor: '#2561B0',
      color: '#595959',
      marginTop: '16px',
    },
    {
      type: 'button',
      label: 'Book Again',
      onClick: () => {},
      disabled: selectedItem == null || selectedItem.applicants_count === 0,
      backgroundColor: '#fff',
      borderColor: '#2561B0',
      color: '#595959',
      marginTop: '16px',
    },
  ];

  return (
    <Layout
      items={[
        { link: '/owner/booked/currently', name: 'Booked Postings' },
        { link: '/owner/booked/previously', name: 'Previously Booked' },
      ]}
    >
      <Grid
        sx={{
          px: 3,
          pt: 2,
          pb: 1,
          borderBottom: '1px solid #D9D9D9',
          width: 'auto',
        }}
      >
        <h4 className="pb-0 mb-1" style={{ color: '#262626' }}>
          Previously Booked
        </h4>
        <p style={{ color: '#8C8C8C', fontSize: '0.8rem' }}>
          Management of Previously Booked Professionals
        </p>
      </Grid>

      <div
        className="d-flex justify-content-between align-items-center flex-wrap row-gap-2"
        style={{
          padding: '10px 20px',
          backgroundColor: '#F5F5F5',
          borderBottom: '1px solid #D9D9D9',
        }}
      >
        <div
          style={{
            display: gridWidth ? 'none' : 'flex',
            gap: 20,
          }}
        >
          <Button
            variant="outlined"
            style={{
              border:
                selectedItem !== null
                  ? '1px solid #2561B0'
                  : '1px solid #D9D9D9',
              color: selectedItem !== null ? '#595959' : '#BFBFBF',
              backgroundColor: selectedItem != null ? '#fff' : '#F5F5F5',
            }}
            disabled={
              selectedItem === null ||
              selectedItem.posting_status === 'cancelled'
            }
          >
            Post / See Review
          </Button>
          <Button
            variant="outlined"
            style={{
              border:
                selectedItem !== null
                  ? '1px solid #2561B0'
                  : '1px solid #D9D9D9',
              color: selectedItem !== null ? '#595959' : '#BFBFBF',
              backgroundColor: selectedItem != null ? '#fff' : '#F5F5F5',
            }}
            disabled={
              selectedItem === null || selectedItem.blacklisted === true
            }
          >
            Hire for Permanent Job
          </Button>

          <Button
            variant="outlined"
            style={{
              border:
                selectedItem != null
                  ? '1px solid #2561B0'
                  : '1px solid #D9D9D9',
              color: selectedItem != null ? '#595959' : '#BFBFBF',
              backgroundColor: selectedItem != null ? '#fff' : '#F5F5F5',
            }}
            disabled={
              selectedItem == null || selectedItem.applicants_count === 0
            }
          >
            Book Again
          </Button>
        </div>
        <div
          className="d-flex"
          style={{
            gap: 20,
            alignItems: 'center',
          }}
        >
          <Button
            style={{
              border: '1px solid #2561B0',
              color: '#595959',
              backgroundColor: '#2561B0',
            }}
            onClick={() => setFiltersSidebar(true)}
          >
            <img src={FilterIcon} alt="" />
            <span
              style={{
                marginLeft: 5,
                color: '#fff',
              }}
            >
              Filters
            </span>
          </Button>
          <Button
            style={{
              border: '1px solid #2561B0',
              color: '#595959',
              backgroundColor: '#fff',
            }}
            onClick={() => {
              fetchPreviouslyBooked(1, 10);
            }}
          >
            <img src={RefreshIcon} alt="" />
            <span>
              <span
                style={{
                  marginLeft: 5,
                }}
              >
                Reset filters
              </span>
            </span>
          </Button>
        </div>
      </div>

      {previouslyBooked && previouslyBooked.data && (
        <CustomDataGrid
          columns={columns}
          rows={rows}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          paging={previouslyBooked.paging}
          actions={actions}
          setActions={setActions}
          buttons={actionButtons}
        />
      )}

      {openBlacklistModal && selectedItem && (
        <BlacklistModal
          open={openBlacklistModal}
          handleClose={() => setOpenBlacklistModal(false)}
          fetchData={() => {
            fetchPreviouslyBooked(1, 10);
          }}
          selectedItem={selectedItem}
          banned={banned}
        />
      )}

      {openUnblacklistModal && selectedItem && (
        <UnblacklistModal
          open={openUnblacklistModal}
          handleClose={() => setOpenUnblacklistModal(false)}
          fetchData={() => {
            fetchPreviouslyBooked(1, 10);
          }}
          selectedItem={selectedItem}
          unblacklist={true}
          banned={banned}
        />
      )}

      {filtersSidebar && (
        <FiltersSidebarPreviouslyBooked
          handleClose={() => setFiltersSidebar(false)}
          open={filtersSidebar}
          selectRating={selectRating}
          setSelectRating={setSelectRating}
          professional={professional}
          setProfessional={setProfessional}
          date={date}
          setDate={setDate}
          posting={posting}
          setPosting={setPosting}
          type={type}
          setType={setType}
          handleFilterData={() => {
            handleFilterData(1, 10);
          }}
          resetFilter={resetFilter}
        />
      )}

      {selectedApplicant && (
        <ApplicantPopup
          isOpen={isApplicantDataOpen}
          onClose={handleApplicantDataClose}
          selectedApplicant={selectedApplicant}
        />
      )}
    </Layout>
  );
};

export default PreviouslyBooked;
