import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  Select,
} from '@mui/material';
import Chip from '@mui/material/Chip';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { API_BASE_URL } from '../../config/constants';
import { selectUser, setUser } from '../../redux/slices/userSlice';

//Step 2
export default function Speciality({ setActiveStep }) {
  const user = useSelector(selectUser);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [isVerified, setIsVerified] = useState(null);
  const [selectedStates, setSelectedStates] = useState([]);
  const [states, setStates] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [verificationOption, setVerificationOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [categoryValue, setCategoryValue] = useState('');
  const [subCategoryValue, setSubCategoryValue] = useState('');

  const authToken = localStorage.getItem('auth_token');
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      setCategoryValue(user.category_id);
      setSubCategoryValue(user.user_sub_categories.map((usb) => usb.id));
      setSelectedSubCategories(user.user_sub_categories.map((usb) => usb.id));
    }
  }, [user]);

  useEffect(() => {
    fetch(`${API_BASE_URL}/states`)
      .then((response) => response.json())
      .then((data) => {
        setStates(data.data);
      })
      .catch((error) => console.error('Error fetching states:', error));
  }, []);

  useEffect(() => {
    fetch(`${API_BASE_URL}/categories`)
      .then((response) => response.json())
      .then((data) => {
        setCategories(data.data);
      })
      .catch((error) => console.error('Error fetching categories:', error));
  }, [categoryValue]);

  useEffect(() => {
    if (selectedCategory) {
      fetch(
        `${API_BASE_URL}/subCategories?name=${
          categories[selectedCategory - 1].name
        }`
      )
        .then((response) => response.json())
        .then((data) => {
          setSubCategories(data.data);
        })
        .catch((error) =>
          console.error('Error fetching subcategories:', error)
        );
    }
  }, [categories, selectedCategory, categoryValue]);

  const handleNextClick = async () => {
    setIsLoading(true);
    const category_id = selectedCategory;
    const is_certificate_required = isVerified ? true : false;
    const sub_category_ids = selectedSubCategories.join(',');
    const state_ids = selectedStates.join(',');
    const steps_completed = 2;
    const apiUrl = `${API_BASE_URL}/signup/profile/speciality`;

    let requestData = {
      category_id,
      is_certificate_required,
      sub_category_ids,
      steps_completed,
    };

    if (isVerified) {
      requestData = {
        ...requestData,
        state_ids,
      };
    }

    await axios
      .post(
        apiUrl,
        { ...requestData },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        localStorage.setItem('user', JSON.stringify(response.data.data));
        dispatch(setUser(response.data.data));
        setActiveStep((state) => state + 1);
      })
      .catch((error) => {
        console.error('Error sending data:', error);
      });
    setIsLoading(false);
  };
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setCategoryValue(event.target.value);
    setSelectedSubCategories([]); // Reset selected subcategories when category changes
  };

  const handleVerificationChange = (event) => {
    const value = event.target.value === 'yes';
    setVerificationOption(value);
    setIsVerified(value);
    console.log(verificationOption, isVerified);
  };

  const handleStateSelect = (event) => {
    let selectedState = event.target.value;
    console.log(selectedState.toString());
    if (!selectedStates.includes(selectedState)) {
      setSelectedStates([...selectedStates, selectedState]);
    }
    console.log(selectedStates);
  };

  const handlePillRemoveState = (index) => {
    const updatedStates = [...selectedStates];
    updatedStates.splice(index, 1);
    setSelectedStates(updatedStates);
  };

  const handleSubCategorySelect = (event) => {
    const selectedSubCategory = event.target.value;
    if (!selectedSubCategories.includes(selectedSubCategory)) {
      setSelectedSubCategories([...selectedSubCategories, selectedSubCategory]);
    }
  };

  const handlePillRemove = (index) => {
    const updatedSubCategories = [...selectedSubCategories];
    updatedSubCategories.splice(index, 1);
    setSelectedSubCategories(updatedSubCategories);
  };

  return (
    <div className="mx-auto mx-lg-0">
      <h4
        className="text-start mb-2 fw-semibold"
        style={{ fontSize: '1.25rem' }}
      >
        Speciality
      </h4>
      <Grid container className="m-0">
        <Grid item xs={12} md={6}>
          <FormControl
            size="small"
            variant="outlined"
            fullWidth
            className="mb-3"
          >
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              labelId="category-label"
              id="category"
              label="Category"
              value={categoryValue}
              onChange={handleCategoryChange}
              fullWidth
            >
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="small" variant="outlined" fullWidth>
            <InputLabel id="sub-category-label">Sub Category</InputLabel>
            <Select
              labelId="sub-category-label"
              id="subCategory"
              label="Sub Category"
              value={selectedSubCategories}
              onChange={handleSubCategorySelect}
            >
              {subCategories.map((subCategory) => (
                <MenuItem key={subCategory.id} value={subCategory.id}>
                  {subCategory.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div
            className="my-2 ps-0"
            style={{ display: 'flex', flexWrap: 'wrap' }}
          >
            {selectedSubCategories.map((subCategory, index) => {
              const foundCategory = subCategories.find(
                (category) => category.id === subCategory
              );

              if (foundCategory) {
                return (
                  <Chip
                    key={index}
                    label={foundCategory.name}
                    onDelete={() => handlePillRemove(index)}
                    color="primary"
                    size="small"
                    className="ms-0 me-1 my-1"
                    style={{ marginRight: '0.3rem' }}
                  />
                );
              }

              return null; // Handle the case where category is not found
            })}
          </div>
        </Grid>
        {selectedCategory && (
          <Grid item className="justify-content-start" sx={{paddingLeft: {md: '1rem'}}} md={6}>
            <Grid
              className="rounded"
              container
              sx={{ backgroundColor: '#FFF8E1', p: 1 }}
            >
              <Grid item xs={2} md={1}>
                <ErrorOutlineOutlinedIcon sx={{ color: '#FFC400' }} />
              </Grid>
              <Grid item xs={10} md={11} sx={{ fontSize: '0.9rem' }}>
                <p style={{ color: '#B28900' }} className="fw-semibold mb-0">
                  Attention
                </p>
                <p style={{ color: '#B28900' }}>
                  Please click on all subcategories that you feel comfortable
                  working within. <br />
                  {selectedCategory === 1 ? (
                    <span className="fw-semibold">
                      Ex: RDAEF clicks on RDA and DA instead of just RDAEF
                    </span>
                  ) : selectedCategory === 2 ? (
                    <span className="fw-semibold">
                      Ex: RDHAP clicks on RDH instead of just RDHAP
                    </span>
                  ) : selectedCategory === 3 ? (
                    <span className="fw-semibold">
                      Ex: OFFICE MANAGER clicks on TREATMWNT COORDINATOR instead
                      of just OFFICE MANAGER
                    </span>
                  ) : selectedCategory === 4 ? (
                    <span className="fw-semibold">
                      Ex: SPECIALISTS clicks on ORTHODONTIST
                    </span>
                  ) : null}
                </p>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={12} md={6}>
          <div
            className={`mt-4 p-4 ${
              isVerified === true ? 'hoverRadioBorder' : 'hoverRadioNoBorder'
            }`}
          >
            <Grid container>
              <Grid item xs={2}>
                <Radio
                  size="small"
                  value="yes"
                  checked={verificationOption === true}
                  onChange={handleVerificationChange}
                />
              </Grid>
              <Grid item xs={10}>
                <span>
                  I verify that I am fully licensed to practice in the following
                  states and have no violations or restrictions against my
                  license.
                </span>
              </Grid>
            </Grid>
            <br />

            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <FormControl
                  size="small"
                  variant="outlined"
                  className="mb-3"
                  fullWidth
                >
                  <InputLabel id="select-label">States</InputLabel>
                  <Select
                    labelId="select-label"
                    id="select"
                    value={selectedStates}
                    onChange={handleStateSelect}
                    label="States"
                  >
                    {states
                      ? states.map((state) => (
                          <MenuItem
                            disabled={selectedStates.includes(state)}
                            key={state.id}
                            value={state.id}
                          >
                            {state.name}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>

                <h5 className="">Selected States</h5>

                <div className="" style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {selectedStates.map((istate, index) => {
                    const foundState = states.find(
                      (state) => state.id === istate
                    );

                    if (foundState) {
                      return (
                        <Chip
                          key={foundState.id}
                          label={foundState.name}
                          onDelete={() => handlePillRemoveState(index)}
                          color="primary"
                          size="small"
                          style={{ margin: '4px' }}
                        />
                      );
                    }
                    return null; // Handle the case where category is not found
                  })}
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <br />
      <Grid container>
        <Grid item xs={12} md={6}>
          <div
            className={`p-4 mb-2 mt-2 ${
              isVerified === false ? 'hoverRadioBorder' : 'hoverRadioNoBorder'
            }`}
          >
            <Grid container>
              <Grid item xs={2}>
                <Radio
                  size="small"
                  value="no"
                  onChange={handleVerificationChange}
                  checked={verificationOption === false}
                />
              </Grid>
              <Grid item xs={10}>
                <span>
                  I do not require any certifications or licenses in order to
                  work in my specialty
                </span>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <Row className="my-3">
        <Col xs={12} md={3}>
          <Button
            variant="outlined"
            className="w-100"
            color="primary"
            style={{ height: '3rem' }}
            sx={{
              borderRadius: '4px',
              color: '#2561B0',
              boxShadow: 'none',
              textTransform: 'none',
            }}
            onClick={() => {
              setActiveStep((state) => state - 1);
            }}
          >
            Back
          </Button>
        </Col>
        <Col className="my-3 my-md-0" xs={12} md={3}>
          <Button
            type="submit"
            className="w-100"
            disabled={
              !selectedCategory ||
              !selectedSubCategories.length ||
              (verificationOption && selectedStates.length < 1)
            }
            variant="contained"
            style={{ height: '3rem' }}
            color="primary"
            sx={{
              borderRadius: '4px',
              boxShadow: 'none',
              background: '#2561B0',
              textTransform: 'none',
            }}
            onClick={handleNextClick}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Next'
            )}
          </Button>
        </Col>
      </Row>
    </div>
  );
}
