import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import PaymentIcon from '@mui/icons-material/Payment';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import QrCodeScannerRoundedIcon from '@mui/icons-material/QrCodeScannerRounded';
import StarsOutlinedIcon from '@mui/icons-material/StarsOutlined';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import {
  Button,
  Collapse,
  List,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// import * as React from 'react';
import * as React from 'react';
import CheckInDialog from './CheckInDialog';

export default function SideDrawer({
  isSidebarOpen,
  setIsSidebarOpen,
  isMobile,
}) {
  const [isSubListCreateOpen, setIsSubListCreateOpen] = useState(false);
  const [isSubListBookedOpen, setIsSubListBookedOpen] = useState(false);
  const [isSubListAccountOpen, setIsSubListAccountOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const [selectedItem, setSelectedItem] = useState(
    pathname === '/owner/postings/temporary'
      ? 11
      : pathname === '/owner/postings/permanent'
        ? 12
        : pathname === '/owner/account/profile'
          ? 61
          : pathname === '/owner/account/billing'
            ? 88
            : pathname === '/owner/account/locations'
              ? 62
              : pathname === '/owner/booked/currently'
                ? 21
                : pathname === '/owner/booked/previously'
                  ? 22
                  : pathname === '/owner/applicants'
                    ? 5
                    : pathname === '/owner/transactions'
                      ? 4
                      : pathname === '/owner/subscription'
                        ? 44
                        : pathname === '/owner/jobHistory'
                          ? 3
                          : null
  );

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubListCreateToggle = () => {
    setIsSubListAccountOpen(false);
    setIsSubListCreateOpen(!isSubListCreateOpen);
    setIsSubListBookedOpen(false);
  };

  const handleSubListAccountToggle = () => {
    setIsSubListAccountOpen(!isSubListAccountOpen);
    setIsSubListCreateOpen(false);
    setIsSubListBookedOpen(false);
  };

  const handleSubListBookedToggle = () => {
    setIsSubListAccountOpen(false);
    setIsSubListCreateOpen(false);
    setIsSubListBookedOpen(!isSubListBookedOpen);
  };

  const handleItemClick = (index) => {
    setSelectedItem(index);
  };

  useEffect(() => {
    if (selectedItem === 11 || selectedItem === 12) {
      setIsSubListCreateOpen(true);
    } else if (selectedItem === 21 || selectedItem === 22) {
      setIsSubListBookedOpen(true);
    } else if (
      selectedItem === 61 ||
      selectedItem === 62 ||
      selectedItem === 88
    ) {
      setIsSubListAccountOpen(true);
    } else {
      setIsSubListCreateOpen(false);
      setIsSubListBookedOpen(false);
      setIsSubListAccountOpen(false);
    }
  }, [selectedItem]);

  const buttonStyle = {
    backgroundColor: isHovered ? '#b28900' : '#FFCF33',
    width: '100%',
    textTransform: 'none',
    padding: '10px 0px',
    color: '#262626',
    borderRadius: '4px',
    transition: 'background-color 0.3s',
  };

  // const gridStyle = {
  //   width: 265,
  //   display: isSidebarOpen ? 'block' : 'none',
  //   marginTop: 0,
  //   flexShrink: 0,
  //   minHeight: isMobile ? '100vh' : 'calc(100vh - 65px)',
  //   boxSizing: 'border-box',
  //   backgroundColor: '#2561B0',
  //   boxShadow: 'none',
  //   position: isMobile ? 'absolute' : 'relative',
  //   zIndex: isMobile ? 999 : 'auto',
  //   left: 0,
  // };

  const gridStyle = {
    width: isSidebarOpen ? 265 : isMobile ? '265px' : '0', // Adjust width on non-mobile
    position: isMobile ? 'absolute' : 'relative', // Position absolute for mobile
    left: 0,
    transform: isSidebarOpen ? 'translateX(0)' : 'translateX(-100%)', // Slide in/out
    transition: 'transform 0.3s ease, width 0.3s ease', // Add width transition for desktop
    minHeight: isMobile ? '100vh' : 'calc(100vh - 65px)',
    boxSizing: 'border-box',
    backgroundColor: '#2561B0',
    boxShadow: 'none',
    zIndex: isMobile ? 999 : 'auto',
    overflow: 'hidden', // Prevent content overflow when closed
  };

  return (
    <>
      <div style={gridStyle}>
        <List>
          <ListItemButton
            sx={{
              py: 1.2,
              borderLeft: isSubListCreateOpen
                ? '3.5px solid #FFC400'
                : 'initial',
            }}
            onClick={handleSubListCreateToggle}
          >
            <ListItemIcon>
              <WorkOutlineIcon color="greyFF" />
            </ListItemIcon>
            <ListItemText
              sx={{
                color: '#FFFFFF',
                fontSize: '0.5rem',
              }}
              primary="Create/Edit Postings"
            />
            {isSubListCreateOpen ? (
              <ExpandLessIcon color="greyFF" />
            ) : (
              <ExpandMoreIcon color="greyFF" />
            )}
          </ListItemButton>
          <Collapse in={isSubListCreateOpen} timeout="auto" unmountOnExit>
            <List component="div" sx={{ backgroundColor: '#296DC4' }}>
              <ListItemButton
                onSelect={() => handleItemClick(11)}
                onClick={() => {
                  handleItemClick(11);
                  navigate('/owner/postings/temporary');
                }}
                sx={{
                  backgroundColor: selectedItem === 11 ? '#21589F' : 'initial',
                  borderRadius: selectedItem === 11 ? '6px' : 'initial',
                  width: selectedItem === 11 ? '80%' : 'initial',
                }}
              >
                <ListItemIcon>
                  <svg
                    width="19"
                    height="16"
                    viewBox="0 0 19 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.666 3.41666C16.591 3.41666 17.3327 4.15833 17.3327 5.08333H15.666H2.33268V14.25H15.666H17.3327C17.3327 15.175 16.591 15.9167 15.666 15.9167H2.33268C1.40768 15.9167 0.666016 15.175 0.666016 14.25L0.674349 5.08333C0.674349 4.15833 1.40768 3.41666 2.33268 3.41666H5.66602V1.74999C5.66602 0.824995 6.40768 0.0833282 7.33268 0.0833282H10.666C11.591 0.0833282 12.3327 0.824995 12.3327 1.74999V3.41666H15.666ZM10.666 1.74999V3.41666H7.33268V1.74999H10.666ZM16.0827 8.41666H14.8327V7.16666H18.5827V8.41666H17.3327V12.1667H16.0827V8.41666Z"
                      fill={selectedItem === 11 ? '#FFCF33' : '#1B5198'}
                    />
                  </svg>
                </ListItemIcon>
                <ListItemText
                  sx={{ color: '#FFFFFF' }}
                  primary="Temporary Job"
                />
              </ListItemButton>
              <ListItemButton
                onSelect={() => handleItemClick(12)}
                onClick={() => {
                  handleItemClick(12);
                  navigate('/owner/postings/permanent');
                }}
                sx={{
                  backgroundColor: selectedItem === 12 ? '#21589F' : 'initial',
                  borderRadius: selectedItem === 12 ? '6px' : 'initial',
                  width: selectedItem === 12 ? '80%' : 'initial',
                }}
              >
                <ListItemIcon>
                  <svg
                    width="19"
                    height="16"
                    viewBox="0 0 19 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.3327 5.08334C17.3327 4.15834 16.591 3.41667 15.666 3.41667H12.3327V1.75C12.3327 0.825002 11.591 0.0833359 10.666 0.0833359H7.33268C6.40768 0.0833359 5.66602 0.825002 5.66602 1.75V3.41667H2.33268C1.40768 3.41667 0.674349 4.15834 0.674349 5.08334L0.666016 14.25C0.666016 15.175 1.40768 15.9167 2.33268 15.9167H15.666C16.591 15.9167 17.3327 15.175 17.3327 14.25H15.666H2.33268V5.08334H15.666H17.3327ZM10.666 3.41667V1.75H7.33268V3.41667H10.666ZM17.7493 7.16667H14.8327V12.1667H16.0827V10.5H17.7493C18.416 10.5 18.9993 9.91667 18.9993 9.25V8.41667C18.9993 7.75 18.416 7.16667 17.7493 7.16667ZM16.0827 9.25H17.7493V8.41667H16.0827V9.25Z"
                      fill={selectedItem === 12 ? '#FFCF33' : '#1B5198'}
                    />
                  </svg>
                </ListItemIcon>
                <ListItemText
                  sx={{ color: '#FFFFFF' }}
                  primary="Permanent Job"
                />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton
            sx={{
              py: 1.2,
              borderLeft: isSubListBookedOpen
                ? '3.5px solid #FFC400'
                : 'initial',
            }}
            onClick={handleSubListBookedToggle}
          >
            <ListItemIcon>
              <ChromeReaderModeOutlinedIcon color="greyFF" />
            </ListItemIcon>
            <ListItemText
              sx={{ color: '#FFFFFF', fontSize: '0.5rem' }}
              primary="Booked Postings"
            />
            {isSubListBookedOpen ? (
              <ExpandLessIcon color="greyFF" />
            ) : (
              <ExpandMoreIcon color="greyFF" />
            )}
          </ListItemButton>
          <Collapse in={isSubListBookedOpen} timeout="auto" unmountOnExit>
            <List component="div" sx={{ backgroundColor: '#296DC4' }}>
              <ListItemButton
                onSelect={() => handleItemClick(21)}
                onClick={() => {
                  handleItemClick(21);
                  navigate('/owner/booked/currently');
                  // window.location.reload();
                }}
                sx={{
                  backgroundColor: selectedItem === 21 ? '#21589F' : 'initial',
                  borderRadius: selectedItem === 21 ? '6px' : 'initial',
                  width: selectedItem === 21 ? '80%' : 'initial',
                }}
              >
                <ListItemIcon>
                  <svg
                    width="19"
                    height="16"
                    viewBox="0 0 19 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.666 3.41666C16.591 3.41666 17.3327 4.15833 17.3327 5.08333H15.666H2.33268V14.25H15.666H17.3327C17.3327 15.175 16.591 15.9167 15.666 15.9167H2.33268C1.40768 15.9167 0.666016 15.175 0.666016 14.25L0.674349 5.08333C0.674349 4.15833 1.40768 3.41666 2.33268 3.41666H5.66602V1.74999C5.66602 0.824995 6.40768 0.0833282 7.33268 0.0833282H10.666C11.591 0.0833282 12.3327 0.824995 12.3327 1.74999V3.41666H15.666ZM10.666 1.74999V3.41666H7.33268V1.74999H10.666ZM16.0827 8.41666H14.8327V7.16666H18.5827V8.41666H17.3327V12.1667H16.0827V8.41666Z"
                      fill={selectedItem === 21 ? '#FFCF33' : '#1B5198'}
                    />
                  </svg>
                </ListItemIcon>
                <ListItemText
                  sx={{ color: '#FFFFFF' }}
                  primary="Currently Booked"
                />
              </ListItemButton>
              <ListItemButton
                onSelect={() => handleItemClick(22)}
                onClick={() => {
                  handleItemClick(22);
                  navigate('/owner/booked/previously');
                  // window.location.reload();
                }}
                sx={{
                  backgroundColor: selectedItem === 22 ? '#21589F' : 'initial',
                  borderRadius: selectedItem === 22 ? '6px' : 'initial',
                  width: selectedItem === 22 ? '80%' : 'initial',
                }}
              >
                <ListItemIcon>
                  <svg
                    width="19"
                    height="16"
                    viewBox="0 0 19 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.3327 5.08334C17.3327 4.15834 16.591 3.41667 15.666 3.41667H12.3327V1.75C12.3327 0.825002 11.591 0.0833359 10.666 0.0833359H7.33268C6.40768 0.0833359 5.66602 0.825002 5.66602 1.75V3.41667H2.33268C1.40768 3.41667 0.674349 4.15834 0.674349 5.08334L0.666016 14.25C0.666016 15.175 1.40768 15.9167 2.33268 15.9167H15.666C16.591 15.9167 17.3327 15.175 17.3327 14.25H15.666H2.33268V5.08334H15.666H17.3327ZM10.666 3.41667V1.75H7.33268V3.41667H10.666ZM17.7493 7.16667H14.8327V12.1667H16.0827V10.5H17.7493C18.416 10.5 18.9993 9.91667 18.9993 9.25V8.41667C18.9993 7.75 18.416 7.16667 17.7493 7.16667ZM16.0827 9.25H17.7493V8.41667H16.0827V9.25Z"
                      fill={selectedItem === 22 ? '#FFCF33' : '#1B5198'}
                    />
                  </svg>
                </ListItemIcon>
                <ListItemText
                  sx={{ color: '#FFFFFF' }}
                  primary="Previously Booked"
                />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton
            sx={{
              py: 1.2,
              backgroundColor: selectedItem === 3 ? '#21589F' : 'initial',
              borderLeft:
                selectedItem === 3 ? '3.5px solid #FFC400' : 'initial',
            }}
            onClick={() => {
              handleItemClick(3);
              navigate('/owner/jobHistory');
            }}
            onSelect={() => handleItemClick(3)}
          >
            <ListItemIcon>
              <HistoryOutlinedIcon
                color={selectedItem === 3 ? 'sYellow' : 'greyFF'}
              />
            </ListItemIcon>
            <ListItemText
              sx={{ color: '#FFFFFF', fontSize: '0.5rem' }}
              primary="Job History"
            />
          </ListItemButton>

          <ListItemButton
            sx={{
              py: 1.2,
              backgroundColor: selectedItem === 4 ? '#21589F' : 'initial',
              borderLeft:
                selectedItem === 4 ? '3.5px solid #FFC400' : 'initial',
            }}
            onClick={() => {
              handleItemClick(4);
              navigate('/owner/transactions');
            }}
            onSelect={() => handleItemClick(4)}
          >
            <ListItemIcon>
              <AttachMoneyOutlinedIcon
                color={selectedItem === 4 ? 'sYellow' : 'greyFF'}
              />
            </ListItemIcon>
            <ListItemText
              sx={{ color: '#FFFFFF', fontSize: '0.5rem' }}
              primary="Transactions"
            />
          </ListItemButton>

          <ListItemButton
            sx={{
              py: 1.2,
              backgroundColor: selectedItem === 44 ? '#21589F' : 'initial',
              borderLeft:
                selectedItem === 44 ? '3.5px solid #FFC400' : 'initial',
            }}
            onClick={() => {
              handleItemClick(4);
              navigate('/owner/subscription');
            }}
            onSelect={() => handleItemClick(44)}
          >
            <ListItemIcon>
              <SubscriptionsOutlinedIcon
                color={selectedItem === 44 ? 'sYellow' : 'greyFF'}
              />
            </ListItemIcon>
            <ListItemText
              sx={{ color: '#FFFFFF', fontSize: '0.5rem' }}
              primary="Subscription"
            />
          </ListItemButton>

          <ListItemButton
            sx={{
              py: 1.2,
              backgroundColor: selectedItem === 5 ? '#21589F' : 'initial',
              borderLeft:
                selectedItem === 5 ? '3.5px solid #FFC400' : 'initial',
            }}
            onClick={() => {
              handleItemClick(5);
              navigate('/owner/applicants');
            }}
            onSelect={() => handleItemClick(5)}
          >
            <ListItemIcon>
              <PeopleOutlinedIcon
                color={selectedItem === 5 ? 'sYellow' : 'greyFF'}
              />
            </ListItemIcon>
            <ListItemText
              sx={{ color: '#FFFFFF', fontSize: '0.5rem' }}
              primary="Applicants"
            />
          </ListItemButton>

          <ListItemButton
            sx={{
              py: 1.2,
              borderLeft: isSubListAccountOpen
                ? '3.5px solid #FFC400'
                : 'initial',
            }}
            onClick={handleSubListAccountToggle}
          >
            <ListItemIcon>
              <PersonOutlineOutlinedIcon color="greyFF" />
            </ListItemIcon>
            <ListItemText
              sx={{ color: '#FFFFFF', fontSize: '0.5rem' }}
              primary="My Account"
            />
            {isSubListAccountOpen ? (
              <ExpandLessIcon color="greyFF" />
            ) : (
              <ExpandMoreIcon color="greyFF" />
            )}
          </ListItemButton>

          <Collapse in={isSubListAccountOpen} timeout="auto" unmountOnExit>
            <List component="div" sx={{ backgroundColor: '#296DC4' }}>
              <ListItemButton
                onSelect={() => handleItemClick(88)}
                onClick={() => {
                  handleItemClick(88);
                  navigate('/owner/account/billing');
                }}
                sx={{
                  backgroundColor: selectedItem === 88 ? '#21589F' : 'initial',
                  borderRadius: selectedItem === 88 ? '6px' : 'initial',
                  width: selectedItem === 88 ? '80%' : 'initial',
                }}
              >
                <ListItemIcon>
                  <PaymentIcon
                    color={selectedItem === 88 ? 'sYellow' : '#1B5198'}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{ color: '#FFFFFF', fontSize: '0.5rem' }}
                  primary="Billing"
                />
              </ListItemButton>
            </List>

            <List component="div" sx={{ backgroundColor: '#296DC4' }}>
              <ListItemButton
                onSelect={() => handleItemClick(61)}
                onClick={() => {
                  handleItemClick(61);
                  navigate('/owner/account/profile');
                }}
                sx={{
                  backgroundColor: selectedItem === 61 ? '#21589F' : 'initial',
                  borderRadius: selectedItem === 61 ? '6px' : 'initial',
                  width: selectedItem === 61 ? '80%' : 'initial',
                }}
              >
                <ListItemIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.666 0H3.33268V1.66667H16.666V0ZM16.666 3.33333H3.33268C2.41602 3.33333 1.66602 4.08333 1.66602 5V15C1.66602 15.9167 2.41602 16.6667 3.33268 16.6667H16.666C17.5827 16.6667 18.3327 15.9167 18.3327 15V5C18.3327 4.08333 17.5827 3.33333 16.666 3.33333ZM16.666 15H3.33268V5H16.666V15ZM3.33268 18.3333H16.666V20H3.33268V18.3333ZM14.9993 11.6667H4.99935V13.3333H14.9993V11.6667ZM4.99935 9.16667H14.9993V10.8333H4.99935V9.16667ZM14.9993 6.66667H4.99935V8.33333H14.9993V6.66667Z"
                      fill={selectedItem === 61 ? '#FFCF33' : '#1B5198'}
                    />
                  </svg>
                </ListItemIcon>
                <ListItemText
                  sx={{ color: '#FFFFFF', fontSize: '0.5rem' }}
                  primary="Office Profile"
                />
              </ListItemButton>
            </List>

            <List component="div" sx={{ backgroundColor: '#296DC4' }}>
              <ListItemButton
                onSelect={() => handleItemClick(62)}
                onClick={() => {
                  handleItemClick(62);
                  navigate('/owner/account/locations');
                }}
                sx={{
                  backgroundColor: selectedItem === 62 ? '#21589F' : 'initial',
                  borderRadius: selectedItem === 62 ? '6px' : 'initial',
                  width: selectedItem === 62 ? '80%' : 'initial',
                }}
              >
                <ListItemIcon>
                  <MyLocationIcon
                    color={selectedItem === 62 ? 'sYellow' : '#1B5198'}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{ color: '#FFFFFF', fontSize: '0.5rem' }}
                  primary="Locations"
                />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton
            sx={{
              py: 1.2,
              backgroundColor: selectedItem === 7 ? '#21589F' : 'initial',
              borderLeft:
                selectedItem === 7 ? '3.5px solid #FFC400' : 'initial',
            }}
            onClick={() => handleItemClick(7)}
            onSelect={() => handleItemClick(7)}
          >
            <ListItemIcon>
              <HelpOutlineOutlinedIcon
                color={selectedItem === 7 ? 'sYellow' : 'greyFF'}
              />
            </ListItemIcon>
            <ListItemText
              sx={{ color: '#FFFFFF', fontSize: '0.5rem' }}
              primary="User's Manual"
            />
          </ListItemButton>

          <ListItemButton
            sx={{
              py: 1.2,
              backgroundColor: selectedItem === 8 ? '#21589F' : 'initial',
              borderLeft:
                selectedItem === 8 ? '3.5px solid #FFC400' : 'initial',
            }}
            onClick={() => {
              navigate('/owner/special/offer');
              handleItemClick(8);
            }}
            onSelect={() => handleItemClick(8)}
          >
            <ListItemIcon>
              <StarsOutlinedIcon
                color={selectedItem === 8 ? 'sYellow' : 'greyFF'}
              />
            </ListItemIcon>
            <ListItemText
              sx={{ color: '#FFFFFF', fontSize: '0.5rem' }}
              primary="Special Offers"
            />
          </ListItemButton>

          <Divider
            variant="middle"
            sx={{
              border: '1.5px solid #194378',
              my: 2,
            }}
          />

          <ListItemButton
            sx={{ py: 1.2 }}
            onSelect={() => handleItemClick(9)}
            onClick={() => {
              handleItemClick(9);
              handleClickOpen();
            }}
          >
            <Button
              variant="text"
              style={buttonStyle}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <QrCodeScannerRoundedIcon sx={{ mx: 0.5 }} /> Check In
            </Button>
          </ListItemButton>
        </List>
        <p
          style={{
            color: '#FFFFFF',
            fontSize: '0.9rem',
            textAlign: 'center',
          }}
          className="mx-4 my-3"
        >
          Please have professional scan QR code with their device to check in,
          otherwise choose verification code tab.
        </p>

        {open && (
          <>
            <CheckInDialog open={open} handleClose={handleClose} />
          </>
        )}
      </div>
      {isMobile && isSidebarOpen && (
        <div
          onClick={() => setIsSidebarOpen(false)} // Close sidebar on click
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
            zIndex: 998,
          }}
        />
      )}
    </>
  );
}
