import { Grid } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import RefreshIcon from '../../../../assets/icons/arrow-clockwise.svg';
import CalendarIcon from '../../../../assets/icons/calendar2.svg';
import FilterIcon from '../../../../assets/icons/filter.svg';
import ApplicantsAlertPopup from '../../../../components/General/ApplicantsAlertPopup';
import CustomDataGrid from '../../../../components/General/CustomDataGrid';
import CustomDaysFilled from '../../../../components/General/CustomDaysFilled';
import SuccessModal from '../../../../components/General/SuccessModal';
import Layout from '../../../../components/Layout';
import { getStatusStyle } from '../../../../utils/CustomDataGridStyle';
import { capitalizeFirstLetter } from '../../../../utils/helper';
import ApplicantsSidebar from './ApplicantsSidebar';
import CancelPostingModal from './CancelPostingModal';
import DeletePostingModal from './DeletePostingModal';
import FiltersSidebar from './FiltersSidebar';
import { API_BASE_URL } from '../../../../config/constants';

const TemporaryJobs = () => {
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(null);
  const [temporaryJobs, setTemporaryJobs] = useState({});
  const [open, setOpen] = useState(false);
  const [cancelPosting, setCancelPosting] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [isFiltersSidebarOpen, setIsFiltersSidebarOpen] = useState(false);
  const [isApplicantsSidebarOpen, setIsApplicantsSidebarOpen] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const { successMessage } = location.state || {};
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const authToken = localStorage.getItem('auth_token');
  const navigate = useNavigate();
  const [actions, setActions] = useState(false);
  const [gridWidth, setGridWidth] = useState();

  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [postingTitle, setPostingTitle] = useState('');
  const [locationFilter, setLocationFilter] = useState('');
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [postingType, setPostingType] = useState('');

  useEffect(() => {
    const handleResize = () => {
      setGridWidth(window.innerWidth < 800 ? true : false);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, [window.innerWidth]);

  useEffect(() => {
    if (successMessage) {
      setOpenSuccessModal(true);
    }
  }, [successMessage]);

  useEffect(() => {
    fetchData(1, 10);
  }, []);

  const fetchData = (page, limit) => {
    axios
      .get(
        `${API_BASE_URL}/owner/postings?posting_type=temporary&page=${page}&limit=${limit}&order_by=created_at&order_type=desc`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        setTemporaryJobs(res.data);
      })
      .catch((e) => console.log(e));
  };

  const handleFilterData = (page, limit) => {
    let endpoint = `${API_BASE_URL}/owner/postings?posting_type=temporary&page=${page}&limit=${limit}`;

    if (postingTitle) {
      endpoint += `&title=${postingTitle}`;
    }

    if (locationFilter) {
      endpoint += `&location=${locationFilter}`;
    }

    if (status) {
      endpoint += `&posting_status=${status}`;
    }

    if (startDate) {
      endpoint += `&from=${startDate}`;
    }

    if (endDate) {
      endpoint += `&to=${endDate}`;
    }

    if (postingType) {
      endpoint += `&posting_schedule=${postingType}`;
    }

    axios
      .get(endpoint, {
        headers: {
          method: 'GET',
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        setTemporaryJobs(res.data);
        setIsFiltersSidebarOpen(false);
      })
      .catch((e) => {
        setIsFiltersSidebarOpen(false);
        console.log(e);
      });
  };

  const resetFilter = () => {
    setPostingTitle('');
    setLocationFilter('');
    setStatus('');
    setStartDate('');
    setEndDate('');
    setPostingType('');
    fetchData(1, 10);
  };

  const buttons = [
    {
      type: 'button',
      label: 'Edit',
      onClick: () => {
        if (selectedItem?.applicants_count > 0) {
          setIsOpenPopup(true);
        } else {
          navigate(`/owner/postings/edit/temporary/${selectedItem?.id}`);
        }
        setActions(false);
      },
      disabled: selectedItem?.posting_status === 'cancelled',
      backgroundColor: '#fff',
      borderColor: '#2561B0',
      color: '#595959',
      marginTop: '30px',
    },
    {
      type: 'button',
      label: 'Clone',
      onClick: () => {
        navigate(`/owner/postings/clone/temporary/${selectedItem?.id}`);
        setActions(false);
      },
      backgroundColor: '#fff',
      borderColor: '#2561B0',
      color: '#595959',
      marginTop: '20px',
    },
    {
      type: 'button',
      label: 'Delete',
      onClick: () => {
        setOpen(true);
        setActions(false);
      },
      backgroundColor: '#fff',
      borderColor: '#FA5A16',
      color: '#FA5A16',
      marginTop: '20px',
    },
    {
      type: 'button',
      label: 'Cancel',
      onClick: () => {
        setCancelPosting(true);
        setActions(false);
      },
      disabled: selectedItem?.posting_status === 'cancelled',
      backgroundColor: '#fff',
      borderColor: '#FA5A16',
      color: '#FA5A16',
      marginTop: '20px',
    },
    {
      type: 'hr',
      marginTop: '20px',
      color: '#2561B0', // Customize hr color
    },
    {
      type: 'button',
      label: 'View Applicants',
      onClick: () => {
        setIsApplicantsSidebarOpen(true);
        setActions(false);
      },
      disabled: selectedItem?.applicants_count === 0,
      backgroundColor: '#fff',
      borderColor: '#2561B0',
      color: '#595959',
      marginTop: '0px',
    },
    {
      type: 'button',
      label: 'View Postings',
      onClick: () => navigate(`/owner/postings/temporary/${selectedItem.id}`),
      backgroundColor: '#fff',
      borderColor: '#2561B0',
      color: '#595959',
      marginTop: '20px',
    },
  ];

  const columns = [
    { field: 'title', headerName: 'Posting Title', width: 250 },
    { field: 'place_name', headerName: 'Location', width: 150 },
    { field: 'status', headerName: 'Status', width: 150 },
    { field: 'startDate', headerName: 'Start Date', width: 150 },
    { field: 'endDate', headerName: 'End Date', width: 150 },
    { field: 'applicantsCount', headerName: 'Applicants', width: 150 },
    { field: 'filled_days', headerName: 'Days Filled', width: 150 },
    { field: 'col2', headerName: '', width: 150 },
  ];
  const rows = temporaryJobs?.data?.map((item) => {
    const statusStyle = getStatusStyle(item.current_posting_status);
    return {
      id: item.id,
      title: item.title,
      place_name: item.user_location.place_name,
      status: (
        <span style={statusStyle}>
          {capitalizeFirstLetter(item.current_posting_status)}
        </span>
      ),
      startDate: (
        <div
          aria-describedby={selectedItem && selectedItem.id}
          style={{
            position: 'relative',
            color: '#000000',
          }}
        >
          {moment(item.start_date).format('MM/DD/YYYY')}
        </div>
      ),
      endDate: moment(item.end_date).format('MM/DD/YYYY'),
      applicantsCount:
        item.applicants_count > 0 ? (
          <Button onClick={() => setIsApplicantsSidebarOpen(true)}>
            {item.applicants_count}
          </Button>
        ) : (
          <Button>{item.applicants_count}</Button>
        ),
      filled_days: (
        <CustomDaysFilled
          schedulesCount={item.schedules_count}
          selectedCandidateCount={item.selected_candidate_count}
          status={item?.posting_status}
          cancelledCount={item.cancelled_count}
        />
      ),
      col2: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => navigate(`/owner/postings/temporary/${item.id}`)}
            style={{ backgroundColor: '#2561B0', border: 0 }}
          >
            Postings
          </Button>
        </div>
      ),
      ...item,
    };
  });

  const OnClick = () => {
    setIsOpenPopup((prev) => {
      return !prev;
    });
  };

  return (
    <Layout
      items={[
        {
          name: 'Postings',
          link: '/',
        },
        {
          name: 'Temporary Job',
          link: '/owner/postings/temporary',
        },
      ]}
    >
      <ApplicantsAlertPopup
        type={'temporary'}
        isOpen={isOpenPopup}
        handleOnClick={OnClick}
        id={selectedItem && selectedItem.id}
      />

      <Grid
        sx={{
          px: 3,
          pt: 2,
          pb: 1,
          borderBottom: '1px solid #D9D9D9',
          width: 'auto',
        }}
      >
        <h4 className="pb-0 mb-1" style={{ color: '#262626' }}>
          Temporary Jobs
        </h4>
        <p style={{ color: '#8C8C8C', fontSize: '0.8rem' }}>Manage Postings</p>
      </Grid>

      <div
        className="d-flex justify-content-between"
        style={{
          backgroundColor: '#F5F5F5',
          padding: '12px 20px',
          borderBottom: '1px solid #D9D9D9',
          overflowX: 'auto',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: 20,
          }}
        >
          <Button
            variant="outlined"
            style={{
              border: '1px solid #2561B0',
              color: '#595959',
              backgroundColor: '#fff',
            }}
            onClick={() => navigate('/owner/postings/create/temporary')}
          >
            Create
          </Button>
          <Button
            variant="outlined"
            style={{
              border:
                selectedItem !== null
                  ? '1px solid #2561B0'
                  : '1px solid #D9D9D9',
              color: selectedItem !== null ? '#595959' : '#BFBFBF',
              backgroundColor: selectedItem != null ? '#fff' : '#F5F5F5',
              display: `${gridWidth ? 'none' : ''}`,
            }}
            disabled={
              selectedItem === null ||
              selectedItem?.posting_status === 'cancelled'
            }
            onClick={() => {
              if (selectedItem.applicants_count > 0) {
                setIsOpenPopup(true);
              } else
                navigate(`/owner/postings/edit/temporary/${selectedItem?.id}`);
            }}
          >
            Edit
          </Button>

          <Button
            variant="outlined"
            style={{
              border:
                selectedItem != null
                  ? '1px solid #2561B0'
                  : '1px solid #D9D9D9',
              color: selectedItem != null ? '#595959' : '#BFBFBF',
              backgroundColor: selectedItem != null ? '#fff' : '#F5F5F5',
              display: `${gridWidth ? 'none' : ''}`,
            }}
            disabled={selectedItem == null}
            onClick={() =>
              navigate(`/owner/postings/clone/temporary/${selectedItem?.id}`)
            }
          >
            Clone
          </Button>
          <Button
            variant="outlined"
            style={{
              border:
                selectedItem != null
                  ? '1px solid #FA5A16'
                  : '1px solid #D9D9D9',
              color: selectedItem != null ? '#FA5A16' : '#BFBFBF',
              backgroundColor: selectedItem != null ? '#fff' : '#F5F5F5',
              display: `${gridWidth ? 'none' : ''}`,
            }}
            disabled={selectedItem == null}
            onClick={handleOpen}
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            style={{
              border:
                selectedItem != null
                  ? '1px solid #FA5A16'
                  : '1px solid #D9D9D9',
              color: selectedItem != null ? '#FA5A16' : '#BFBFBF',
              backgroundColor: selectedItem != null ? '#fff' : '#F5F5F5',
              display: `${gridWidth ? 'none' : ''}`,
            }}
            disabled={
              selectedItem == null ||
              selectedItem?.posting_status === 'cancelled'
            }
            onClick={() => setCancelPosting(true)}
          >
            Cancel
          </Button>
          <div
            style={{
              borderLeft: '1px solid #D9D9D9',
              height: '100%',
              display: `${gridWidth ? 'none' : ''}`,
            }}
          ></div>
          <Button
            variant="outlined"
            style={{
              border:
                selectedItem != null
                  ? '1px solid #2561B0'
                  : '1px solid #D9D9D9',
              color: selectedItem != null ? '#595959' : '#BFBFBF',
              backgroundColor: selectedItem != null ? '#fff' : '#F5F5F5',
              display: `${gridWidth ? 'none' : ''}`,
              whiteSpace: 'nowrap',
            }}
            disabled={
              selectedItem == null || selectedItem.applicants_count === 0
            }
            onClick={() =>
              navigate(`/owner/postings/temporary/${selectedItem?.id}`)
            }
          >
            View Applicants
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            marginLeft: '10px',
            gap: 20,
          }}
        >
          <Button
            style={{
              border: '1px solid #2561B0',
              color: '#fff',
              backgroundColor: '#2561B0',
            }}
            onClick={() => navigate('/owner/postings/calendar')}
          >
            <img src={CalendarIcon} alt="" />
          </Button>
          <div
            style={{
              borderLeft: '1px solid #D9D9D9',
              height: '100%',
            }}
          ></div>
          <Button
            style={{
              border: '1px solid #2561B0',
              color: '#595959',
              backgroundColor: '#2561B0',
            }}
            onClick={() => setIsFiltersSidebarOpen(true)}
          >
            <img src={FilterIcon} alt="" />
          </Button>
          <Button
            style={{
              border: '1px solid #2561B0',
              color: '#595959',
              backgroundColor: '#fff',
            }}
            onClick={() => fetchData(1, 10)}
          >
            <img src={RefreshIcon} alt="" />
          </Button>
        </div>
      </div>

      {temporaryJobs && temporaryJobs.data && (
        <CustomDataGrid
          rows={rows}
          columns={columns}
          paging={temporaryJobs?.paging}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          setIsApplicantsSidebarOpen={setIsApplicantsSidebarOpen}
          fetchData={fetchData}
          actions={actions}
          setActions={setActions}
          buttons={buttons}
        />
      )}

      {openSuccessModal && (
        <SuccessModal
          open={openSuccessModal}
          handleClose={() => setOpenSuccessModal(false)}
          successMessage={successMessage}
        />
      )}

      {open && (
        <DeletePostingModal
          open={open}
          handleClose={handleClose}
          selectedItem={selectedItem}
          fetchData={fetchData}
          gridWidth={gridWidth}
        />
      )}

      {cancelPosting && selectedItem && (
        <CancelPostingModal
          open={cancelPosting}
          handleClose={() => setCancelPosting(false)}
          selectedItem={selectedItem}
          fetchData={fetchData}
          gridWidth={gridWidth}
        />
      )}
      <div style={{ backgroundColor: 'red' }}>
        {isApplicantsSidebarOpen && selectedItem && (
          <ApplicantsSidebar
            isSidebarOpen={isApplicantsSidebarOpen}
            setIsSidebarOpen={setIsApplicantsSidebarOpen}
            selectedItem={selectedItem}
            gridWidth={gridWidth}
          />
        )}
      </div>

      {isFiltersSidebarOpen && (
        <FiltersSidebar
          isSidebarOpen={isFiltersSidebarOpen}
          setIsSidebarOpen={setIsFiltersSidebarOpen}
          setTemporaryJobs={setTemporaryJobs}
          gridWidth={gridWidth}
          showMoreFilters={showMoreFilters}
          setShowMoreFilters={setShowMoreFilters}
          postingTitle={postingTitle}
          setPostingTitle={setPostingTitle}
          locationFilter={locationFilter}
          setLocationFilter={setLocationFilter}
          status={status}
          setStatus={setStatus}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          postingType={postingType}
          setPostingType={setPostingType}
          resetFilter={resetFilter}
          handleFilterData={() => {
            handleFilterData(1, 10);
          }}
        />
      )}
    </Layout>
  );
};

export default TemporaryJobs;
