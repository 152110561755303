import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useMobile from '../hooks/useMobile';
import { selectUser } from '../redux/slices/userSlice';
import SideDrawer from './Navbar/Drawer';
import NavbarOwner from './Navbar/NavbarOwner';
import NavigationPanel from './Navbar/NavigationPanel';
import ProfessionalDrawer from './Navbar/ProfessionalDrawer';

const Layout = ({ children, items, basicHeader }) => {
  const user = useSelector(selectUser);
  const isMobile = useMobile();
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile);

  const mainNavBar = React.useMemo(
    () => (
      <NavbarOwner
        setIsSidebarOpen={setIsSidebarOpen}
        isSidebarOpen={isSidebarOpen}
      />
    ),
    [isSidebarOpen]
  );
  return (
    <>
      <div style={{ position: 'relative', zIndex: 100 }}>
        {/* <NavbarOwner
          setIsSidebarOpen={setIsSidebarOpen}
          isSidebarOpen={isSidebarOpen}
        /> */}
        {mainNavBar}
      </div>
      <div className="d-flex">
        {user.role_type === 'owner' ? (
          <SideDrawer
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
            isMobile={isMobile}
          />
        ) : (
          <ProfessionalDrawer
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
            isMobile={isMobile}
          />
        )}
        <div
          style={{
            width: isSidebarOpen && !isMobile ? 'calc(100% - 256px)' : '100%',
            transition: 'all 0.3s ease',
            minHeight: 'calc(100vh - 256px)',
            backgroundColor: '#FAFAFA',
          }}
        >
          {items && <NavigationPanel items={items} />}
          {basicHeader && (
            <Grid
              sx={{
                px: 3,
                pt: 2,
                pb: 1,
                borderBottom: '1px solid #D9D9D9',
                width: 'auto',
              }}
            >
              <h4 className="pb-0 mb-1" style={{ color: '#262626' }}>
                {basicHeader.title}
              </h4>
              <p style={{ color: '#8C8C8C', fontSize: '0.8rem' }}>
                {basicHeader.description}
              </p>
            </Grid>
          )}
          <div>{children}</div>
          <p
            className="mt-auto text-center mx-auto text-lightgrey p-1"
            style={{ fontSize: '0.73rem' }}
          >
            If you have any questions, please contact us at:{' '}
            <a href="https://maydaydentalstaffing.com">
              maydaydentalstaffing.com
            </a>{' '}
            <br />. Mayday Dental Staffing is a registered trademark. Copyright
            © Mayday Dental Staffing. All rights reserved. Patent pending.
          </p>
        </div>
      </div>
    </>
  );
};

export default Layout;
