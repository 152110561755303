import {
  FormHelperText,
  Grid,
  TextareaAutosize,
  TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { ErrorMessage, useField } from 'formik';
import React from 'react';
import InputMask from 'react-input-mask';
import '../../../App.css';

const ExperienceInputs = ({ label, ...props }, ref) => {
  const [field] = useField(props);
  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    props.onChange(formattedDate);
  };
  return (
    <>
      {props.type === 'text' ? (
        <>
          <TextField
            required
            size="small"
            variant="outlined"
            fullWidth
            {...field}
            {...props}
          />
          <ErrorMessage
            component="div"
            name={field.name}
            style={errorMessage}
          />
        </>
      ) : props.type === 'textarea' ? (
        <>
          <TextareaAutosize
            {...field}
            {...props}
            value={props.value}
            style={{
              width: '100%',
              border: '1px solid #BFBFBF',
              borderRadius: '4px',
              marginTop: '20px',
              padding: '10px 14px',
              fontSize: '14px',
              fontWeight: '400',
              fontFamily: 'Roboto',
              resize: 'none',
            }}
            rows="6"
            placeholder="Enter Performed Functions "
          ></TextareaAutosize>

          {errorMessage && (
            <FormHelperText style={{ color: '#FA5A16' }}>
              <ErrorMessage
                component="div"
                name={field.name}
                style={errorMessage}
              />
            </FormHelperText>
          )}
        </>
      ) : props.type === 'date' ? (
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={props.label}
              slotProps={{ textField: { size: 'small' } }}
              value={props.value && dayjs(props.value)}
              // className="border rounded"
              inputProps={{
                required: true,
              }}
              onChange={(newValue) => {
                handleDateChange(newValue);
              }}
              sx={{ width: '100%' }}
            />
            {/* <ErrorMessage component="div" name={field.name} style={errorMessage} /> */}
            {errorMessage && (
              <FormHelperText style={{ color: '#FA5A16' }}>
                <ErrorMessage
                  component="div"
                  name={field.name}
                  style={errorMessage}
                />
              </FormHelperText>
            )}
          </LocalizationProvider>
        </>
      ) : props.type === 'phone' ? (
        <>
          <InputMask
            {...field}
            {...props}
            mask="(999) 999 - 9999"
            maskChar="_"
            value={props.value}
          >
            {(inputProps) => (
              <TextField
                size="small"
                className="w-15 h-30"
                label="Contact Phone"
                variant="outlined"
                // sx={{ mb: 3 }}
                inputProps={{ ...inputProps, type: 'tel' }}
                fullWidth
              />
            )}
          </InputMask>

          {errorMessage && (
            <FormHelperText style={{ color: '#FA5A16' }}>
              <ErrorMessage
                component="div"
                name={field.name}
                style={errorMessage}
              />
            </FormHelperText>
          )}
        </>
      ) : props.type === 'email' ? (
        <>
          <TextField
            required
            type="email"
            size="small"
            variant="outlined"
            fullWidth
            {...field}
            {...props}
          />
          <ErrorMessage
            component="div"
            name={field.name}
            style={errorMessage}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ExperienceInputs;

const errorMessage = {
  color: 'red',
  position: 'absolute',
  fontSize: '11px',
};
