import { ErrorOutline } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import * as React from 'react';

export default function SendAlertModal({
  open,
  handleClose,
  selectedItem,
  fetchData,
}) {
  const handleConfirm = () => {
    if (selectedItem !== null) {
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle>
        <div
          className="d-flex"
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Alert
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Grid item xs={12} md={4}>
            <Grid
              className="rounded"
              container
              spacing={1}
              sx={{ backgroundColor: '#FFF8E1', p: 1 }}
            >
              <Grid item xs={2} sm={1}>
                <ErrorOutline sx={{ color: '#FFC400' }} />
              </Grid>
              <Grid item xs={10} sm={11} sx={{ fontSize: '0.9rem' }}>
                <p style={{ color: '#B28900' }} className="fw-semibold mb-0">
                  Attention!
                </p>
                <p style={{ color: '#B28900' }}>
                  Are you sure you want to send an alert to Bill Porter?
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Typography>
      </DialogContent>

      <DialogActions>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-outline-primary me-2"
            style={{
              border: 0,
              color: '#2561B0',
            }}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-outline-primary"
            style={{
              border: 0,
              color: '#2561B0',
            }}
            onClick={handleConfirm}
          >
            Send
          </button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
