import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { Button } from 'react-bootstrap';
import CustomDatePicker from '../../../../components/CreatePosting/CustomDatePicker';
const useStyles = makeStyles((theme) => ({
  drawer: {
    maxWidth: 340,
    width: '100%',
    flexShrink: 0,
  },
  drawerPaper: {
    padding: theme.spacing(2),
    maxWidth: 340,
    width: '100%',
  },
  heading: {
    margin: theme.spacing(2),
  },
  specialtyBox: {
    margin: theme.spacing(1),
  },

  ratingBox: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    minWidth: '100%',
  },
}));

const FiltersSidebar = ({
  isSidebarOpen,
  setIsSidebarOpen,
  setTemporaryJobs,
  gridWidth,

  postingTitle,
  setPostingTitle,

  locationFilter,
  setLocationFilter,

  status,
  setStatus,

  startDate,
  setStartDate,

  endDate,
  setEndDate,

  handleFilterData,
  resetFilter,
}) => {
  const authToken = localStorage.getItem('auth_token');
  const classes = useStyles();

  return (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
      open={isSidebarOpen}
      onClose={() => setIsSidebarOpen(false)}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="1rem"
      >
        <Typography variant="h5" style={{ fontWeight: 500 }}>
          Filters
        </Typography>
        <IconButton onClick={() => setIsSidebarOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <TextField
          variant="outlined"
          fullWidth
          label="Posting Title"
          id="postingTitle"
          value={postingTitle}
          onChange={(e) => setPostingTitle(e.target.value)}
          InputProps={{
            style: {
              textAlign: 'center',
              padding: '10px',
            },
          }}
        />

        <TextField
          variant="outlined"
          fullWidth
          label="Location"
          id="location"
          value={locationFilter}
          onChange={(e) => setLocationFilter(e.target.value)}
          InputProps={{
            style: {
              textAlign: 'center',
              padding: '10px',
            },
          }}
        />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status}
            label="Status"
            onChange={(e) => setStatus(e.target.value)}
            style={{
              textAlign: 'center',
              padding: '10px',
            }}
          >
            <MenuItem
              value="need_check_in"
              style={{
                display: 'block',
                paddingLeft: '15px',
                margin: '5px',
              }}
            >
              Needs Check-in
            </MenuItem>
            <MenuItem
              value="check_in"
              style={{
                display: 'block',
                paddingLeft: '15px',
                margin: '5px',
              }}
            >
              Checked-in
            </MenuItem>
            <MenuItem
              value="rejected"
              style={{
                display: 'block',
                paddingLeft: '15px',
                margin: '5px',
              }}
            >
              Declined
            </MenuItem>
            <MenuItem
              value="not_show"
              style={{
                display: 'block',
                paddingLeft: '15px',
                margin: '5px',
              }}
            >
              Not Show
            </MenuItem>
          </Select>
        </FormControl>

        <CustomDatePicker
          label="Date"
          value={startDate}
          onChange={(date) => setStartDate(date)}
          size="medium"
        />
        {/* <CustomDatePicker
        label='End Date'
        onChange={(date) => setEndDate(date)}
        value={endDate}
      /> */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '10px',
          }}
        >
          <Button
            variant="outline-primary"
            style={{
              width: '100%',
              border: '1px solid #2561B0',
              color: '#595959',
            }}
            onClick={resetFilter}
          >
            Reset
          </Button>
          <Button
            variant="primary"
            style={{
              width: '100%',
              backgroundColor: '#2561B0',
              border: 0,
            }}
            onClick={handleFilterData}
          >
            Find
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default FiltersSidebar;
