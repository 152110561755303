import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

export default function ForAssistant({
  setAdditionalInfo,
  additionalInfo,
  setSpecialties,
  specialties,
  setYearsAsRDA,
  yearsAsRDA,
  setYearsWorked,
  yearsWorked,
  handleKeyDownPrc,
  handleKeyDown,
  handleChipDeletePrc,
  handleChipDelete,
  practiceManagementSoftware,
  setPrcValue,
  prcValue,
  radiographySystems,
  setRinputValue,
  rinputValue,
}) {
  return (
    <Box className="m-0">
      <h4 className="m-0 p-0 my-3 fw-semibold" style={{ fontSize: '1.25rem' }}>
        Questionaire
      </h4>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <h6 style={{ color: '#595959' }}>
            How many years/months did you work in dental field?*
          </h6>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                required
                className="w-100"
                label="Years"
                variant="outlined"
                value={yearsWorked.years}
                onChange={(e) =>
                  setYearsWorked((state) => ({
                    ...state,
                    years: e.target.value.replace(/\D/, ''),
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                size="small"
                className="w-100"
                label="Months"
                variant="outlined"
                value={yearsWorked.months}
                onChange={(e) => {
                  let value = e.target.value.replace(/\D/, '');
                  if (value > 12) value = '12';
                  setYearsWorked((state) => ({
                    ...state,
                    months: value,
                  }));
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} className='pt-3'>
              <h6 style={{ color: '#595959' }}>
                Proficient with the following Digital Radiography Systems{' '}
                <span className="text-lightgrey"> (Up to 50): </span>
              </h6>
              <TextField
                size="small"
                required
                variant="outlined"
                sx={{
                  mb: 2,
                  display: 'flex',
                  flexWrap: 'wrap',
                  height: 'auto',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    height: '0.3rem',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '6px',
                    backgroundColor: '#bfbfbf',
                  },
                  '&::-webkit-scrollbar-track': {
                    borderRadius: '6px',
                    backgroundColor: '#eee',
                  },
                  '&::-webkit-scrollbar-button': {
                    display: 'none',
                  },
                }}
                value={rinputValue}
                onChange={(e) => setRinputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {radiographySystems.map((system, index) => (
                        <Chip
                          color="primary"
                          className="me-1 rounded"
                          key={index}
                          size="small"
                          label={system}
                          onDelete={() => handleChipDelete(index)} // Modified line to handle chip deletion
                          deleteIcon={<CloseIcon />} // Assuming you have an icon component for the "X"
                        />
                      ))}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <h6 style={{ color: '#595959' }}>
            How many years/months as an RDA/DA?
          </h6>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                className="w-100"
                label="Years"
                variant="outlined"
                value={yearsAsRDA.years}
                onChange={(e) =>
                  setYearsAsRDA((state) => ({
                    ...state,
                    years: e.target.value.replace(/\D/, ''),
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                size="small"
                className="w-100"
                label="Months"
                variant="outlined"
                sx={{ mb: 2 }}
                value={yearsAsRDA.months}
                onChange={(e) => {
                  let value = e.target.value.replace(/\D/, '');
                  if (value > 12) value = '12';
                  setYearsAsRDA((state) => ({
                    ...state,
                    months: value,
                  }));
                }}
              />
            </Grid>
          </Grid>
          <h6 style={{ color: '#595959' }}>
            Proficient with the following Practice Management Software{' '}
            <span className="text-lightgrey"> (Up to 50): </span>
          </h6>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                size="small"
                className="w-100"
                variant="outlined"
                sx={{
                  mb: 2,
                  display: 'flex',
                  flexWrap: 'wrap',
                  height: 'auto',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    height: '0.3rem',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '6px',
                    backgroundColor: '#bfbfbf',
                  },
                  '&::-webkit-scrollbar-track': {
                    borderRadius: '6px',
                    backgroundColor: '#eee',
                  },
                  '&::-webkit-scrollbar-button': {
                    display: 'none',
                  },
                }}
                value={prcValue}
                onChange={(e) => setPrcValue(e.target.value)}
                onKeyDown={handleKeyDownPrc}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {practiceManagementSoftware.map((system, index) => (
                        <Chip
                          size="small"
                          color="primary"
                          className="me-1 rounded"
                          key={index}
                          label={system}
                          onDelete={() => handleChipDeletePrc(index)}
                          deleteIcon={<CloseIcon />}
                        />
                      ))}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <h4 className="m-0 p-0 my-3 fw-semibold" style={{ fontSize: '1.25rem' }}>
        Experienced in the following specialties:
      </h4>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl size="small" className="w-100">
                <InputLabel id="category-label">General</InputLabel>
                <Select
                  required
                  className="w-100"
                  labelId="category-label"
                  id="category"
                  label="General"
                  sx={{ mb: 2 }}
                  value={specialties.general}
                  onChange={(e) =>
                    setSpecialties((state) => ({
                      ...state,
                      general: e.target.value,
                    }))
                  }
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="Some">Some</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl size="small" className="w-100">
                <InputLabel id="category-label">Prostho</InputLabel>
                <Select
                  required
                  className="w-100"
                  labelId="category-label"
                  id="category"
                  label="Prostho"
                  sx={{ mb: 2 }}
                  value={specialties.prostho}
                  onChange={(e) =>
                    setSpecialties((state) => ({
                      ...state,
                      prostho: e.target.value,
                    }))
                  }
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="Some">Some</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl size="small" className="w-100">
                <InputLabel id="category-label">Cosmetic</InputLabel>
                <Select
                  required
                  className="w-100"
                  labelId="category-label"
                  id="category"
                  label="Cosmetic"
                  sx={{ mb: 2 }}
                  value={specialties.cosmetic}
                  onChange={(e) =>
                    setSpecialties((state) => ({
                      ...state,
                      cosmetic: e.target.value,
                    }))
                  }
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="Some">Some</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl size="small" className="w-100">
                <InputLabel id="category-label">Pedo</InputLabel>
                <Select
                  required
                  className="w-100"
                  labelId="category-label"
                  id="category"
                  label="Pedo"
                  sx={{ mb: 2 }}
                  value={specialties.pedo}
                  onChange={(e) =>
                    setSpecialties((state) => ({
                      ...state,
                      pedo: e.target.value,
                    }))
                  }
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="Some">Some</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid containerspacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl size="small" className="w-100">
                <InputLabel id="category-label">Ortho</InputLabel>
                <Select
                  required
                  className="w-100"
                  labelId="category-label"
                  id="category"
                  label="Ortho"
                  sx={{ mb: 2 }}
                  value={specialties.ortho}
                  onChange={(e) =>
                    setSpecialties((state) => ({
                      ...state,
                      ortho: e.target.value,
                    }))
                  }
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="Some">Some</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl size="small" className="w-100">
                <InputLabel id="category-label">Perio</InputLabel>
                <Select
                  required
                  className="w-100"
                  labelId="category-label"
                  id="category"
                  label="Perio"
                  sx={{ mb: 2 }}
                  value={specialties.perio}
                  onChange={(e) =>
                    setSpecialties((state) => ({
                      ...state,
                      perio: e.target.value,
                    }))
                  }
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="Some">Some</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl size="small" className="w-100">
                <InputLabel id="category-label">Endo</InputLabel>
                <Select
                  required
                  className="w-100"
                  labelId="category-label"
                  id="category"
                  label="Endo"
                  sx={{ mb: 2 }}
                  value={specialties.endo}
                  onChange={(e) =>
                    setSpecialties((state) => ({
                      ...state,
                      endo: e.target.value,
                    }))
                  }
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="Some">Some</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl size="small" className="w-100">
                <InputLabel id="category-label">Implants</InputLabel>
                <Select
                  required
                  className="w-100"
                  labelId="category-label"
                  id="category"
                  label="Implants"
                  sx={{ mb: 2 }}
                  value={specialties.implants}
                  onChange={(e) =>
                    setSpecialties((state) => ({
                      ...state,
                      implants: e.target.value,
                    }))
                  }
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="Some">Some</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl size="small" className="w-100">
                <InputLabel id="category-label">Oral Surgery</InputLabel>
                <Select
                  required
                  className="w-100"
                  labelId="category-label"
                  id="category"
                  label="Oral Surgery"
                  sx={{ mb: 2 }}
                  value={specialties.oralSurgery}
                  onChange={(e) =>
                    setSpecialties((state) => ({
                      ...state,
                      oralSurgery: e.target.value,
                    }))
                  }
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="Some">Some</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <h4 className="m-0 p-0 my-3 fw-semibold" style={{ fontSize: '1.25rem' }}>
        Additional Information
      </h4>
      <Grid container spacing={2}>
        <Grid item container spacing={2} xs={12} md={6}>
          <Grid item xs={12} md={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="category-label">
                Experienced in Cad-Cam (E4D or Cerec):
              </InputLabel>
              <Select
                required
                className="w-100"
                labelId="category-label"
                id="category"
                label="Experienced in Cad-Cam (E4D or Cerec):"
                value={additionalInfo.cadCam}
                onChange={(e) =>
                  setAdditionalInfo((state) => ({
                    ...state,
                    cadCam: e.target.value,
                  }))
                }
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="Some">Some</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="category-label">Experienced in Pano:</InputLabel>
              <Select
                required
                className="w-100"
                labelId="category-label"
                id="category"
                label="Experienced in Pano:"
                value={additionalInfo.pano}
                onChange={(e) =>
                  setAdditionalInfo((state) => ({
                    ...state,
                    pano: e.target.value,
                  }))
                }
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="Some">Some</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="category-label">
                Experienced in 3D Imaging:
              </InputLabel>
              <Select
                required
                className="w-100"
                labelId="category-label"
                id="category"
                label="Experienced in 3D Imaging:"
                value={additionalInfo.printers3D}
                onChange={(e) =>
                  setAdditionalInfo((state) => ({
                    ...state,
                    printers3D: e.target.value,
                  }))
                }
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="Some">Some</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="category-label">
                Experienced in Nomad portable X-Ray:
              </InputLabel>
              <Select
                required
                className="w-100"
                labelId="category-label"
                id="category"
                label="Experienced in Nomad portable X-Ray:"
                value={additionalInfo.nomadXRay}
                onChange={(e) =>
                  setAdditionalInfo((state) => ({
                    ...state,
                    nomadXRay: e.target.value,
                  }))
                }
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="Some">Some</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container spacing={2} xs={12} md={6}>
          <Grid item xs={12} md={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="category-label">
                Experienced in Intraoral Cameras:
              </InputLabel>
              <Select
                required
                className="w-100"
                labelId="category-label"
                id="category"
                label="Experienced in Intraoral Cameras:"
                value={additionalInfo.intraoralCameras}
                onChange={(e) =>
                  setAdditionalInfo((state) => ({
                    ...state,
                    intraoralCameras: e.target.value,
                  }))
                }
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="Some">Some</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="category-label">
                Experienced in Cephalometric X-Ray machine:
              </InputLabel>
              <Select
                required
                className="w-100"
                labelId="category-label"
                id="category"
                label="Experienced in Cephalometric X-Ray machine:"
                value={additionalInfo.cephalometricXRay}
                onChange={(e) =>
                  setAdditionalInfo((state) => ({
                    ...state,
                    cephalometricXRay: e.target.value,
                  }))
                }
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="Some">Some</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="category-label">
                Are you cross trained front to back?
              </InputLabel>
              <Select
                required
                className="w-100"
                labelId="category-label"
                id="category"
                label="Are you cross trained front to back?"
                value={additionalInfo.crossTrained}
                onChange={(e) =>
                  setAdditionalInfo((state) => ({
                    ...state,
                    crossTrained: e.target.value,
                  }))
                }
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="Some">Some</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
