import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from '@mui/material';
import axios from 'axios';
import * as React from 'react';
import { API_BASE_URL } from '../../../../config/constants';

const style = {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
};

export default function CancelPostingScheduleDialog({
  open,
  handleClose,
  selectedItem,
  fetchData,
}) {
  const authToken = localStorage.getItem('auth_token');

  const handleCancelPostingSchedule = () => {
    if (selectedItem !== null) {
      axios
        .put(
          `${API_BASE_URL}/owner/postings/schedules/${selectedItem.id}/cancel`,
          null,
          {
            headers: {
              method: 'PUT',
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => {
          fetchData(1, 10);
          handleClose();
        })
        .catch((e) => console.log(e));
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="dialog-title">
      <DialogTitle id="dialog-title">
        Cancel this Assignment
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            cursor: 'pointer',
          }}
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </div>
      </DialogTitle>

      <DialogContent sx={style}>
        <Typography>
          <Grid item xs={12} md={4}>
            <Grid
              className="rounded"
              container
              spacing={1}
              sx={{ backgroundColor: '#FFF3EE', p: 1 }}
            >
              <Grid item xs={2} sm={1}>
                <ReportProblemOutlinedIcon sx={{ color: '#E54C0B' }} />
              </Grid>
              <Grid item xs={10} sm={11} sx={{ fontSize: '0.9rem' }}>
                <p style={{ color: '#E54C0B' }} className="fw-semibold mb-0">
                  Attention!
                </p>
                <p style={{ color: '#E54C0B' }}>
                  Do you wish to cancel ({selectedItem.schedule_date} -{' '}
                  {selectedItem.schedule_day})? A cancellation fee will be
                  applied.
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} style={{ color: '#2561B0' }}>
          Cancel
        </Button>
        <Button
          onClick={handleCancelPostingSchedule}
          style={{ color: '#2561B0' }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
