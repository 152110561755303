import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';

import CustomDatePicker from '../../../../components/CreatePosting/CustomDatePicker';

const useStyles = makeStyles((theme) => ({
  drawer: {
    maxWidth: 340,
    width: '100%',
    flexShrink: 0,
  },
  drawerPaper: {
    padding: theme.spacing(2),
    maxWidth: 340,
    width: '100%',
  },
  heading: {
    margin: theme.spacing(2),
  },
  specialtyBox: {
    margin: theme.spacing(1),
  },

  ratingBox: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    minWidth: '100%',
  },
}));

function ProposalFilterSidebar({
  fetchProposal,
  resetFilter,

  isSidebarOpen,
  setIsSidebarOpen,

  hiringRate,
  setHiringRate,

  selectStatus,
  setSelectStatus,

  proposalDate,
  setProposalDate,
}) {
  const classes = useStyles();

  const handleSelectStatus = (event) => {
    const {
      target: { value },
    } = event;
    setSelectStatus(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
      open={isSidebarOpen}
      onClose={() => setIsSidebarOpen(false)}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="1rem"
      >
        <Typography variant="h5" style={{ fontWeight: 500 }}>
          Filters
        </Typography>
        <IconButton onClick={() => setIsSidebarOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box className="d-flex flex-column gap-3">
        <CustomDatePicker
          label="Date"
          value={proposalDate}
          onChange={(date) => setProposalDate(date)}
          size="medium"
        />

        <TextField
          variant="outlined"
          fullWidth
          label="Hiring Rate"
          id="hiringRate"
          value={hiringRate}
          onChange={(e) => setHiringRate(e.target.value)}
          InputProps={{
            style: {
              textAlign: 'center',
            },
          }}
        />

        <FormControl sx={{ marginBottom: '1rem' }}>
          <InputLabel id="name-label">Status</InputLabel>
          <Select
            style={{
              width: '100%',
            }}
            label={'Status'}
            labelId="Status-select-label"
            id="Status-select-label"
            value={selectStatus}
            onChange={handleSelectStatus}
          >
            <MenuItem value="new">New</MenuItem>
            <MenuItem value="accepted">Accepted</MenuItem>
            <MenuItem value="cancelled">Cancelled</MenuItem>
          </Select>
        </FormControl>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '1rem',
          }}
        >
          <Button
            variant="outlined"
            onClick={resetFilter}
            style={{
              color: '#2D62ED',
              width: '100%',
              marginRight: '1rem',
            }}
          >
            Reset
          </Button>
          <Button
            onClick={fetchProposal}
            variant="contained"
            style={{
              backgroundColor: '#2D62ED',
              color: 'white',
              width: '100%',
            }}
          >
            Find
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}

export default ProposalFilterSidebar;
