import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  CardMedia,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Alert,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingButton from '../../components/General/LoadingButton';
import referaprofessional from '../../assets/images/refer-a-professional.png';
import { API_BASE_URL } from '../../config/constants';

const ReferralModal = ({
  isOpen,
  onClose,
  successModal,
  errorModal,
  getReferrals,
  setSuccessMessage,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm')); // Adjust for small screens
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [states, setStates] = useState([]);

  const schema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    phone: Yup.string().required('Enter Phone Number'),
    state: Yup.string().required('required'),
    city: Yup.string().required('Enter City Name'),
    email: Yup.string()
      .min(5, 'Must be at least 8 characters')
      .max(20, 'Must be less than 20 characters')
      .required('Email is required')
      .test('Unique Email', 'Email already exists', function (value) {
        return new Promise((resolve) => {
          axios
            .get(`${API_BASE_URL}/available/${value}`)
            .then(() => resolve(true))
            .catch(() => resolve(false));
        });
      }),
  });

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      state: '',
      city: '',
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values) => handleSave(values),
  });

  const handleSave = async (data) => {
    setLoading(true);
    data.referral_user_type = 'professional';
    try {
      const res = await axios.post(`${API_BASE_URL}/referral`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      });
      setSuccessMessage('Professional Added Successfully');
      successModal();
      setFinished(true);
      getReferrals();
      onClose();
    } catch (err) {
      errorModal();
      setFinished(true);
      onClose();
    } finally {
      setLoading(false);
    }
  };

  const getStates = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/states`);
      const data = await response.json();
      setStates(data.data);
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };

  useEffect(() => {
    getStates();
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth="lg"
      fullWidth
      sx={{ margin: '2rem' }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <Grid container sx={{ flexDirection: fullScreen ? 'column' : 'row' }}>
          {/* Image Section */}
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                height: fullScreen ? '100px' : '100%', // 100px on mobile, auto height based on content on larger screens
                backgroundImage: `url(${referaprofessional})`,
                backgroundSize: 'cover', // Ensures the image covers the whole area without stretching
                backgroundPosition: 'center', // Keeps the image centered
                backgroundRepeat: 'no-repeat', // Ensures no repeat of the image
                width: '100%',
              }}
            ></Box>
          </Grid>

          {/* Form Section */}
          <Grid item xs={12} sm={8} sx={{ padding: fullScreen ? 2 : 5 }}>
            <Box sx={{ pb: 4 }}>
              <Typography variant="h4">Refer a Professional</Typography>
            </Box>

            <FormikProvider value={formik}>
              <form>
                <Typography variant="h6">Profile Information</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: fullScreen ? 'column' : 'row',
                  }}
                >
                  <FormControl fullWidth sx={{ mb: 2, mr: fullScreen ? 0 : 1 }}>
                    <TextField
                      label="First Name"
                      {...formik.getFieldProps('first_name')}
                      error={
                        formik.touched.first_name &&
                        Boolean(formik.errors.first_name)
                      }
                      helperText={
                        formik.touched.first_name && formik.errors.first_name
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2, ml: fullScreen ? 0 : 1 }}>
                    <TextField
                      label="Last Name"
                      {...formik.getFieldProps('last_name')}
                      error={
                        formik.touched.last_name &&
                        Boolean(formik.errors.last_name)
                      }
                      helperText={
                        formik.touched.last_name && formik.errors.last_name
                      }
                    />
                  </FormControl>
                </Box>

                <Typography variant="h6">Contact Information</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: fullScreen ? 'column' : 'row',
                  }}
                >
                  <FormControl fullWidth sx={{ mb: 2, mr: fullScreen ? 0 : 1 }}>
                    <TextField
                      label="Email"
                      {...formik.getFieldProps('email')}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2, ml: fullScreen ? 0 : 1 }}>
                    <TextField
                      label="Phone"
                      {...formik.getFieldProps('phone')}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                  </FormControl>
                </Box>

                <Typography variant="h6">Location</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: fullScreen ? 'column' : 'row',
                  }}
                >
                  <FormControl fullWidth sx={{ mb: 2, mr: fullScreen ? 0 : 1 }}>
                    <InputLabel>State</InputLabel>
                    <Select
                      label="State"
                      {...formik.getFieldProps('state')}
                      error={
                        formik.touched.state && Boolean(formik.errors.state)
                      }
                    >
                      {states.map((list) => (
                        <MenuItem key={list.id} value={list.id}>
                          {list.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.state && formik.errors.state && (
                      <FormHelperText error>
                        {formik.errors.state}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2, ml: fullScreen ? 0 : 1 }}>
                    <TextField
                      label="City"
                      {...formik.getFieldProps('city')}
                      error={formik.touched.city && Boolean(formik.errors.city)}
                      helperText={formik.touched.city && formik.errors.city}
                    />
                  </FormControl>
                </Box>

                {formik.errors.email && formik.touched.email && (
                  <Alert severity="error" sx={{ mb: 2 }}>
                    Please note this professional is already in our system. No
                    referral gift will be awarded.
                  </Alert>
                )}

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: 2,
                    columnGap: { xs: 2, sm: 1 },
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    color="warning"
                    sx={{
                      mr: { xs: '0', sm: '2' },
                      flexGrow: fullScreen ? '1' : '0',
                    }}
                    onClick={onClose}
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    loading={loading}
                    onClick={formik.handleSubmit}
                    sx={{
                      flexGrow: fullScreen ? '1' : '0',
                    }}
                  >
                    Confirm
                  </LoadingButton>
                </Box>
              </form>
            </FormikProvider>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ReferralModal;
