import { Avatar, AvatarGroup, Box, Typography } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { truncate } from '../../../../../utils/helper';
import ManageThisDayModal from './ManageThisDayModal';
import { API_BASE_URL } from '../../../../../config/constants';

const localizer = momentLocalizer(moment);

const ScheduleCalendar = ({ events }) => {
  const [currentDate, setCurrentDate] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentView, setCurrentView] = useState('month');
  const handleResize = () => {
    if (window.matchMedia('(max-width: 600px)').matches) {
      setCurrentView('day');
    } else if (window.matchMedia('(max-width: 1024px)').matches) {
      setCurrentView('week');
    } else {
      setCurrentView('month');
    }
  };

  useEffect(() => {
    handleResize(); // Set the initial view based on screen size
    window.addEventListener('resize', handleResize); // Listen for screen resizing

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const formats = {
    weekdayFormat: (date, culture, localizer) =>
      localizer.format(date, 'dddd', culture),
  };

  const getSelectedDateEvents = () => {
    if (selectedDate !== null) {
      const selectedDateEvents = events.filter((event) => {
        return (
          moment(event.start).format('YYYY-MM-DD') ===
          moment(selectedDate).format('YYYY-MM-DD')
        );
      });

      const selectedDateEventsArray = selectedDateEvents.map((event) => {
        return event.events;
      });

      return selectedDateEventsArray.flat();
    }
  };

  const onSelectSlot = useCallback((slotInfo) => {
    const { start } = slotInfo;
    setSelectedDate(start);
    setShowModal(true);
  }, []);

  const handlePrevious = () => {
    if (currentView === 'day') {
      setCurrentDate(currentDate.clone().subtract(1, 'day'));
    } else if (currentView === 'week') {
      setCurrentDate(currentDate.clone().subtract(1, 'week'));
    } else if (currentView === 'month') {
      setCurrentDate(currentDate.clone().subtract(1, 'month'));
    }
  };

  const handleNext = () => {
    if (currentView === 'day') {
      setCurrentDate(currentDate.clone().add(1, 'day'));
    } else if (currentView === 'week') {
      setCurrentDate(currentDate.clone().add(1, 'week'));
    } else if (currentView === 'month') {
      setCurrentDate(currentDate.clone().add(1, 'month'));
    }
  };

  const getDateLabel = () => {
    if (currentView === 'day') {
      return currentDate.format('dddd MMMM D, YYYY');
    } else if (currentView === 'week') {
      const startOfWeek = currentDate.clone().startOf('week');
      const endOfWeek = currentDate.clone().endOf('week');
      return `${startOfWeek.format('MMMM D')} – ${endOfWeek.format('D')}, ${currentDate.format('YYYY')}`;
    } else if (currentView === 'month') {
      return currentDate.format('MMMM YYYY');
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="my-calendar">
      <Box
        className="d-flex align-item align-items-center justify-content-center gap-3"
        sx={{
          marginBottom: { xs: '0.5rem', sm: '1rem' },
        }}
      >
        <button
          style={{
            border: 'none',
            backgroundColor: 'transparent',
          }}
          onClick={handlePrevious}
        >
          <BsChevronLeft />
        </button>
        <Typography
          sx={{
            margin: 0,
            color: '#262626',
            fontSize: { sm: 20 },
            fontWeight: 500,
          }}
        >
          <span className="month-name">{getDateLabel()}</span>
        </Typography>
        <button
          style={{
            border: 'none',
            backgroundColor: 'transparent',
          }}
          onClick={handleNext}
        >
          <BsChevronRight />
        </button>
      </Box>

      <Calendar
        selectable={true}
        events={events}
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 750 }}
        views={['month', 'day', 'week']}
        view={currentView}
        formats={formats}
        toolbar={false}
        date={currentDate.toDate()}
        onSelectEvent={onSelectSlot}
        onSelectSlot={onSelectSlot}
        elementProps={{ style: { height: '100%' } }}
        components={{
          event: EventTemplate,
        }}
      />

      {selectedDate && (
        <ManageThisDayModal
          showModal={showModal}
          selectedDate={selectedDate}
          setShowModal={setShowModal}
          handleCloseModal={handleCloseModal}
          selectedDateEvents={getSelectedDateEvents()}
          events={events}
        />
      )}
    </div>
  );
};

export default ScheduleCalendar;

const EventTemplate = ({ event }) => {
  return (
    <div
      style={{
        height: '100%',
        margin: '0 7px',
      }}
    >
      {event?.events?.length > 1 ? (
        <>
          {event.events.length === 2 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
              }}
            >
              <SingleEvent event={event.events[0]} />
              <SingleEvent event={event.events[1]} />
            </div>
          ) : (
            <GroupedEvents event={event} />
          )}
        </>
      ) : (
        <SingleEvent event={event.events[0]} />
      )}
    </div>
  );
};

const GroupedEvents = ({ event }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
      }}
    >
      <p
        style={{
          fontSize: 10,
          fontWeight: 400,
          color: '#262626',
        }}
      >
        {event.title}
      </p>
      <UserImages event={event} />
    </div>
  );
};

const SingleEvent = ({ event }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '5px',
        padding: '3px 6px',
        backgroundColor: '#E3EFFF',
        borderRadius: 6,
        border: '1px solid #4D80CC',
        borderLeft: '4px solid #4D80CC',
      }}
    >
      <img
        style={{
          width: 25,
          height: 25,
          borderRadius: '50%',
        }}
        src={
          event.applicant.user.avatar
            ? `${API_BASE_URL}/assets/${event.applicant.user.avatar}`
            : 'https://via.placeholder.com/150'
        }
        alt=""
      />
      <div>
        <p
          style={{
            fontSize: 10,
            fontWeight: 500,
            color: '#262626',
            margin: 0,
            wordBreak: 'break-word', // Ensures long words break to the next line
            whiteSpace: 'normal', // Allows text to wrap within the container
            overflow: 'hidden', // Prevents overflow of text
            textOverflow: 'ellipsis', // Adds ellipsis if text is too long
          }}
        >
          {event.applicant.user.first_name} {event.applicant.user.last_name}
        </p>
        <p
          style={{
            fontSize: 10,
            fontWeight: 300,
            color: '#262626',
            margin: 0,
            wordBreak: 'break-word', // Ensures long words break to the next line
            whiteSpace: 'normal', // Allows text to wrap within the container
            overflow: 'hidden', // Prevents overflow of text
            textOverflow: 'ellipsis', // Adds ellipsis if text is too long
          }}
        >
          {truncate(event.posting.title, 16)}
        </p>
      </div>
    </div>
  );
};

const UserImages = ({ event }) => {
  const remainingUsers = event.events.length - 3;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <AvatarGroup max={3}>
        {event.events.slice(0, 3).map((event, index) => (
          <Avatar
            alt={event.applicant.user.first_name}
            src={
              event.applicant.user.avatar
                ? `${API_BASE_URL}/assets/${event.applicant.user.avatar}`
                : 'https://via.placeholder.com/150'
            }
            key={index}
            style={{
              width: 25,
              height: 25,
              borderRadius: '50%',
            }}
          />
        ))}
      </AvatarGroup>
      {remainingUsers > 0 && (
        <div
          style={{
            color: '#4A93F0',
            fontSize: 10,
            fontWeight: 400,
          }}
        >
          +{remainingUsers} Professionals
        </div>
      )}
    </div>
  );
};
