import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfilePicture from '../../../components/General/ProfilePicture';
import SuccessModal from '../../../components/General/SuccessModal';
import Layout from '../../../components/Layout';
import { selectUser, setUser } from '../../../redux/slices/userSlice';
import Locations from './Locations';

import ChangeEmailDialog from './ChangeEmailDialog';
import ChangePasswordDialog from './ChangePasswordDialog';

import CheckIcon from '@mui/icons-material/Check';
import { Autocomplete } from '@mui/material';
import moment from 'moment';
import InputMask from 'react-input-mask';
import { API_BASE_URL } from '../../../config/constants';
import { setUserTimeZoneGlobally } from '../../../utils/helper';

const AccountProfile = () => {
  const User = useSelector(selectUser);
  const dispatch = useDispatch();
  const [open, setOpenEmail] = useState(false);
  const [open1, setOpenPassword] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(User.mobile);
  const [changeEmail, setChangeEmail] = useState({
    password: '',
    email: '',
  });
  const [changePassword, setChangePassword] = useState({
    c_password: '',
    n_password: '',
    confirm_password: '',
  });
  const [gender, setGender] = useState(User.gender);
  const [name, setName] = React.useState({
    first_name: User.first_name,
    last_name: User.last_name,
  });
  const [finalSubmission, setFinalSubmission] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [otherStates, setOtherStates] = useState(User.other_state_available);
  const [relocate, setRelocate] = useState(User.willing_to_relocate);
  const [stateId, setStateId] = useState(User.other_state_id);
  const [states, setStates] = useState([]);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [userTimeZone, setUserTimeZone] = useState(User.time_zone_id);

  // const [selectedState, setSelectedState] = useState(User.other_state_id);
  const [selectedState, setSelectedState] = useState(() => {
    let other_stats = [];
    if (Array.isArray(User.other_state_id)) {
      User.other_state_id.map((value, index) => {
        other_stats.push(value);
      });
    }
    return other_stats;
  });

  const [userState, setUserState] = useState(User.state_id);
  // const [selectedState, setSelectedState] = useState(
  //   User.other_state_id && states.length !== 0 && states.filter((state) => state.id === User.other_state_id)[0].name
  // );

  useEffect(() => {
    fetch(`${API_BASE_URL}/states`)
      .then((response) => response.json())
      .then((data) => {
        setStates(data.data);
      })
      .catch((error) => console.error('Error fetching states:', error));
  }, []);

  const handlefinalsubmit = async () => {
    const formData = new FormData();
    // const file = dataUrlToFile(cropData, "output.png");
    // const file = await dataUrlToFileUsingFetch(cropData, 'output.png', 'image/png');
    // console.log('Cover uploadFile === >', file, uploadFile);
    // if (file) formData.append('upload_file', file);
    // if (uploadFile) formData.append('upload_file', uploadFile);
    if (name.first_name) formData.append('first_name', name.first_name);
    if (name.last_name) formData.append('last_name', name.last_name);
    if (otherStates) {
      formData.append('other_state_available', otherStates);
    } else {
      formData.append('other_state_available', 0);
    }
    if (relocate) {
      formData.append('willing_to_relocate', relocate);
    } else {
      formData.append('willing_to_relocate', 0);
    }
    if (gender) formData.append('gender', gender);
    // if (stateId) formData.append('other_state_id', stateId);
    if (userState) formData.append('state_id', userState);

    if (userTimeZone) formData.append('time_zone_id', userTimeZone);

    if (phoneNumber) formData.append('mobile', phoneNumber);

    let other_states = [];
    selectedState.map((object, index) => {
      other_states.push({
        id: object.id,
        name: object.name,
        iso_code: object.iso_code,
        country_id: object.country_id,
      });
    });
    formData.append('other_state_id', JSON.stringify(other_states));

    try {
      axios
        .post(`${API_BASE_URL}/profile`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          dispatch(setUser(res.data.data));
          localStorage.setItem('user', JSON.stringify(res.data.data));

          // console.log('Userssss', User.first_name, User.user_time_zone);
          if (userTimeZone) {
            var utz = timeZoneOptions.filter((value, index) => {
              if (userTimeZone == value.id) {
                return value;
              }
            });
            if (utz.length != 0) {
              setUserTimeZoneGlobally(utz[0].time_zone);
            }
          }
          // setImage(null);
          setFinalSubmission(true);
        })
        .catch((err) => {
          throw new Error(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeEmail = async () => {
    try {
      axios
        .put(
          `${API_BASE_URL}/profile/update-email`,
          {
            current_password: changeEmail.password,
            email: changeEmail.email,
          },
          {
            headers: {
              method: 'PUT',
              Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
            },
          }
        )
        .then((res) => {
          setOpenEmail(false);
        })
        .catch((err) => {
          throw new Error(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangePassword = async () => {
    try {
      axios
        .put(
          `${API_BASE_URL}/profile/update-password`,
          {
            current_password: changePassword.c_password,
            password: changePassword.n_password,
            password_confirmation: changePassword.confirm_password,
            email: User.email,
          },
          {
            headers: {
              method: 'PUT',
              Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
            },
          }
        )
        .then((res) => {
          setOpenPassword(false);
        })
        .catch((err) => {
          throw new Error(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    setName({ first_name: User.first_name, last_name: User.last_name });
  }, [User]);

  const handleChange = (event) => {
    setGender(event.target.value);
  };

  const getTimeZones = async () => {
    try {
      const timeZoneRes = await axios.get(`${API_BASE_URL}/zones`);

      const timeZoneArray = Object.values(timeZoneRes.data.data).map(
        (timeZone) => ({
          id: timeZone.id,
          label: timeZone.zone_name,
          offset: timeZone.offset,
          time_zone: timeZone.time_zone,
        })
      );
      setTimeZoneOptions(timeZoneArray);
    } catch (error) {}
  };

  useEffect(() => {
    getTimeZones();
  }, []);

  return (
    <Layout>
      <Grid
        sx={{
          px: 3,
          borderBottom: '1px solid #D9D9D9',
          width: 'auto',
          color: '#8C8C8C',
          fontSize: '0.9rem',
        }}
      >
        <PermIdentityIcon sx={{ py: 0.2, my: 0.2, mr: 0.9 }} />
        My Account
      </Grid>
      <div
        className="d-flex"
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #D9D9D9',
        }}
      >
        <Grid
          sx={{
            px: 3,
            pt: 2,
            pb: 1,
            mb: 3,
            mt: 1,
            width: 'auto',
          }}
        >
          <h4 className="pb-0 mb-1" style={{ color: '#262626' }}>
            My Account
          </h4>
          <p style={{ color: '#8C8C8C', fontSize: '0.8rem' }}>
            My Profile / Edit
          </p>
        </Grid>
        <div></div>
      </div>
      {/* <div
        style={{ width: '100%', display: 'flex', backgroundColor: '#fff' }}
        className="container"
      > */}
      <Grid
        container
        sx={{
          padding: {
            xs: '0.5rem',
            sm: '1rem',
          },
        }}
      >
        <Grid item xs={12} lg={9} sx={{ order: { xs: 2, lg: 1 } }}>
          <div
            // style={{ padding: '40px 10px 30px 17px' }}
            style={{ padding: '1rem' }}
          >
            <h4
              className="pb-0 mb-1"
              style={{ color: '#262626', fontSize: '19px' }}
            >
              My Account
            </h4>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '10px',
                marginTop: '25px',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <p style={{ color: '#5c5a5a', fontSize: '0.9rem' }}>
                  Email Address
                  <sup
                    style={{
                      color: 'red',
                      fontWeight: 'bold',
                      fontSize: '0.9rem',
                    }}
                  >
                    *
                  </sup>
                </p>
                <p
                  className="pb-0 mb-1"
                  style={{
                    color: '#262626',
                    fontSize: '15px',
                    fontWeight: '500',
                  }}
                >
                  {User && User.email}
                </p>
                <p
                  onClick={() => setOpenEmail(true)}
                  style={{
                    textDecoration: 'underline',
                    color: 'blue',
                    fontSize: '0.9rem',
                    marginTop: '10px',
                    cursor: 'pointer',
                  }}
                >
                  Edit
                </p>
              </div>

              <div>
                <p style={{ color: '#5c5a5a', fontSize: '0.9rem' }}>Password</p>
                <p
                  className="pb-0 mb-1"
                  style={{
                    color: '#262626',
                    fontSize: '15px',
                    fontWeight: '500',
                  }}
                >
                  ***********
                </p>
                <p
                  onClick={() => setOpenPassword(true)}
                  style={{
                    textDecoration: 'underline',
                    color: 'blue',
                    fontSize: '0.9rem',
                    marginTop: '10px',
                    cursor: 'pointer',
                  }}
                >
                  Edit
                </p>
              </div>

              <div>
                <p style={{ color: '#5c5a5a', fontSize: '0.9rem' }}>
                  Current Date Time
                </p>
                <p
                  className="pb-0 mb-1"
                  style={{
                    color: '#262626',
                    fontSize: '15px',
                    fontWeight: '500',
                  }}
                >
                  {/* {moment().tz("Europe/London").format('Z')} */}
                  {/* {User.user_time_zone} */}
                  {/* {moment().format()} */}
                  {moment().format('MM/DD/YYYY hh:mm A')}
                </p>
              </div>
            </div>
            <hr style={{ color: '#5c5a5a' }} />
            <div style={{ paddingTop: '22px' }}>
              <h4
                className="pb-0 mb-1"
                style={{ color: '#262626', fontSize: '19px' }}
              >
                Personal Detail
              </h4>
              {/* <div
                style={{
                  paddingTop: '22px',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              > */}
              <Grid container marginTop={2} sx={{ rowGap: 2 }}>
                {/* <Box
                sx={{ minWidth: 200 }}
                style={{ margin: '10px 20px 10px 0' }}
              >
                <FormControl fullWidth>
                  <InputLabel id='demo-simple-select-label'>
                    Gender Selection
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={gender}
                    label='Gender Selection'
                    onChange={handleChange}
                  >
                    <MenuItem
                      value='male'
                      selected={
                        User.gender === 'male' || User.gender === 'Male'
                          ? true
                          : false
                      }
                    >
                      Male
                    </MenuItem>
                    <MenuItem
                      value='female'
                      selected={
                        User.gender === 'female' || User.gender === 'Female'
                          ? true
                          : false
                      }
                    >
                      Female
                    </MenuItem>
                    <MenuItem
                      value='other'
                      selected={
                        User.gender === 'other' || User.gender === 'Other'
                          ? true
                          : false
                      }
                    >
                      Other
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box> */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{ paddingRight: { sm: 2 } }}
                >
                  <TextField
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    required={true}
                    value={User && name.first_name}
                    fullWidth
                    onChange={(e) => {
                      setName({ ...name, first_name: e.target.value });
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{ paddingRight: { sm: 2 } }}
                >
                  <TextField
                    id="outlined-basic"
                    label="Last Name."
                    variant="outlined"
                    required={true}
                    value={User && name.last_name}
                    fullWidth
                    onChange={(e) => {
                      setName({ ...name, last_name: e.target.value });
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{ paddingRight: { sm: 2 } }}
                >
                  <InputMask
                    mask="(999) 999 - 9999"
                    maskChar="_"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  >
                    {(inputProps) => (
                      <TextField
                        fullWidth
                        size="medium"
                        className="w-15 h-30"
                        label="Contact Phone"
                        variant="outlined"
                        inputProps={{ ...inputProps, type: 'tel' }}
                      />
                    )}
                  </InputMask>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{ paddingRight: { sm: 2 } }}
                >
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={userState}
                      defaultValue={userState}
                      label="State"
                    >
                      {states ? (
                        states.map((state, index) => {
                          return (
                            <MenuItem
                              key={state.id}
                              value={state.id}
                              onClick={() => {
                                setUserState(state.id);
                              }}
                            >
                              {state.name}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label-user-time-zone">
                      Time Zone
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label-user-time-zone"
                      id="demo-simple-select-label-user-time-zone-select"
                      label="Time Zone"
                      value={userTimeZone}
                      defaultValue={userTimeZone}
                      onChange={(e) => setUserTimeZone(e.target.value)}
                    >
                      {timeZoneOptions.map((timeZone) => (
                        <MenuItem value={timeZone.id}>
                          {timeZone.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>

            <hr style={{ color: '#5c5a5a' }} />

            <div style={{ paddingTop: '22px' }}>
              <h4
                className="pb-0 mb-1"
                style={{ color: '#262626', fontSize: '19px' }}
              >
                Preference & Availability
              </h4>

              <div
                style={{
                  paddingTop: '22px',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    style={{ fontSize: '0.9rem' }}
                    checked={otherStates}
                    control={<Checkbox />}
                    label="Other states where you are able to work legally"
                    onClick={() => {
                      setOtherStates(!otherStates);
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    style={{ fontSize: '0.9rem' }}
                    control={<Checkbox />}
                    label="Willing to relocate"
                    checked={relocate}
                    onClick={() => {
                      setRelocate(!relocate);
                    }}
                  />
                  <p style={{ color: '#5c5a5a', fontSize: '0.8rem' }}>
                    You will be able to observe all US permanent postings
                  </p>
                </FormGroup>
              </div>

              <div style={{ display: 'flex' }}>
                <Box style={{ margin: '10px 0' }}>
                  <FormControl fullWidth>
                    {/* <InputLabel id='demo-simple-select-label'>State</InputLabel> */}
                    {/* <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    // value={stateId}
                    defaultValue={selectedState}
                    label='State'
                  >
                    {states ? states.map((state, index) => {
                      return (
                        <MenuItem
                          key={state.id}
                          value={state.id}
                          onClick={() => {
                            setSelectedState(state.name);
                            setStateId(state.id);
                          }}
                        >
                          {state.name}
                        </MenuItem>
                      )
                    }) : (
                      <></>
                    )}
                  </Select> */}

                    <Autocomplete
                      multiple
                      id="other_stats"
                      // options={states}
                      options={states.map(({ id, name }) => ({ id, name }))}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, newValue) => {
                        return option.id === newValue.id;
                      }}
                      defaultValue={selectedState}
                      // filterSelectedOptions
                      disableCloseOnSelect
                      required
                      onChange={(event, value) => {
                        setSelectedState(value);
                      }}
                      renderOption={(props, option, { selected }) => {
                        return (
                          <MenuItem
                            key={option}
                            value={option}
                            sx={{ justifyContent: 'space-between' }}
                            {...props}
                          >
                            {option.name}
                            {/* {selected ? <CheckIcon color="info" /> : null} */}
                            {selectedState.some(
                              (item) => option.id === item.id
                            ) ? (
                              <CheckIcon color="info" />
                            ) : (
                              <></>
                            )}
                          </MenuItem>
                        );
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="States"
                            placeholder="States"
                          />
                        );
                      }}
                    />
                  </FormControl>
                </Box>
              </div>
              <hr style={{ color: '#5c5a5a' }} />
              <Locations />
            </div>
            <button
              style={{
                color: '#fff',
                backgroundColor: '#2561B0',
                padding: '8px 27px',
                border: 'none',
                marginTop: '40px',
                borderRadius: '4px',
              }}
              onClick={handlefinalsubmit}
            >
              {' '}
              Save Changes
            </button>
          </div>
        </Grid>

        <Grid item xs={12} lg={3} sx={{ order: { xs: 1, lg: 2 } }}>
          <ProfilePicture />
        </Grid>
      </Grid>

      {/* </div> */}
      {open && (
        <ChangeEmailDialog
          open={open}
          handleClose={() => setOpenEmail(false)}
          changeEmail={changeEmail}
          setChangeEmail={setChangeEmail}
          handleChangeEmail={handleChangeEmail}
        />
      )}
      {open1 && (
        <ChangePasswordDialog
          open={open1}
          handleClose={() => setOpenPassword(false)}
          changePassword={changePassword}
          setChangePassword={setChangePassword}
          handleChangePassword={handleChangePassword}
        />
      )}
      {finalSubmission && (
        <SuccessModal
          open={finalSubmission}
          handleClose={() => setFinalSubmission(false)}
          successMessage={'Profile Updated Successfully'}
        />
      )}
    </Layout>
  );
};

export default AccountProfile;
