import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
// import '../TemporaryJobs/PostingApplicants.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import axios from 'axios';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ApplicantPopup from '../../../../components/General/ApplicantPopup';
import ErrorModal from '../../../../components/General/ErrorModal';
import ProposalHistoryModal from '../../../../components/General/ProposalHistoryModal';
import { API_BASE_URL } from '../../../../config/constants';
import useMobile from '../../../../hooks/useMobile';
import { capitalizeFirstLetter } from '../../../../utils/helper';

const styles = {
  table: {},
  cell: {
    padding: '10px',
  },
  newStatus: {
    backgroundColor: '#75B0FA',
    border: '1px solid #4A93F0',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  activeStatus: {
    backgroundColor: '#4CAF50',
    border: '1px solid #388E3C',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  cancelledStatus: {
    backgroundColor: '#ff9900',
    border: '1px solid #ff9900',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  completedStatus: {
    backgroundColor: '#4CAF50',
    border: '1px solid #388E3C',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  updatedStatus: {
    backgroundColor: '#B6A8FF',
    border: '1px solid #7C67EB',
    borderRadius: '16px',
    padding: '1px 8px',
  },
  appliedStatus: {
    backgroundColor: '#FFC400',
    border: '1px solid #FFC400',
    borderRadius: '16px',
    padding: '1px 8px',
  },
  selectedRow: {
    backgroundColor: '#D7E8FF',
  },

  proposalSentStatus: {
    backgroundColor: '#6f8edc',
    border: '1px solid #6f8edc',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  proposalAcceptStatus: {
    backgroundColor: '#f213a5',
    border: '1px solid #f213a5',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  proposalDeclineStatus: {
    backgroundColor: '#393d2e',
    border: '1px solid #393d2e',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
};

const getStatusStyle = (status, classes) => {
  switch (status) {
    case 'new':
      return classes.proposalSentStatus;
    case 'active':
      return classes.proposalSentStatus;
    case 'cancelled':
      return classes.proposalDeclineStatus;
    case 'completed':
      return classes.completedStatus;
    case 'updated':
      return classes.updatedStatus;
    case 'applied':
      return classes.appliedStatus;
    case 'accepted':
      return classes.proposalAcceptStatus;
    case 'hired':
      return classes.activeStatus;
    default:
      return status;
  }
};

const ProposalsDataGrid = ({
  classes,
  rows,
  columns,
  paging,
  selectedItem,
  setSelectedItem,
  fetchApplicants,
  postingId,
  setInterviewDetailsOpen,
}) => {
  const { total, per_page, current_page } = paging;
  const [page, setPage] = useState(current_page - 1);
  const [rowsPerPage, setRowsPerPage] = useState(per_page);

  const [errorApproveDialog, setErrorApproveDialog] = useState(false);

  const [errorMessages, setErrorMessages] = useState([]);
  const [historyModal, setHistoryModal] = useState(false);
  const navigate = useNavigate();

  const getRate = (user_location) => {
    var rate = user_location.filter((location) => {
      return location.is_current == true;
    });

    if (rate.length != 0) {
      return rate[0].desired_rate;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    setPage(current_page - 1);
    setRowsPerPage(per_page);
  }, [paging, current_page, per_page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSelectedItem(null);
    fetchApplicants(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setSelectedItem(null);
    fetchApplicants(1, newRowsPerPage);
  };

  const handleRowClick = (item) => {
    if (selectedItem && selectedItem.id === item.id) {
      setHistoryModal(false);
      setSelectedItem(null);
      return;
    } else if (selectedItem && selectedItem.id !== item.id) {
      setSelectedItem(item);
      return;
    } else {
      setSelectedItem(item);
      return;
    }
  };

  const [isApplicantDataOpen, setIsApplicantDataOpen] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState(null);

  const handleNameClick = (item) => {
    setSelectedApplicant(item);
    setIsApplicantDataOpen(true);
  };

  const handleApplicantDataClose = () => {
    setSelectedApplicant(null);
    setIsApplicantDataOpen(false);
  };

  const hireApplicant = (id) => {
    if (id) {
      axios
        .put(`${API_BASE_URL}/owner/applicants/${id}/hire`, null, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
          },
        })
        .then((res) => {
          fetchApplicants(page + 1, rowsPerPage);
        })
        .catch((res) => {
          setErrorApproveDialog(true);
          setErrorMessages(res.response.data.message.description);
        });
    }
  };

  const isMobile = useMobile(800);

  const mobileRows = rows
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((item, index) => {
      return {
        id: item.id,
        date: <>{moment(item.date_time).utc().format('DD:MM:YY HH:MM A')}</>,
        hiring_rate: <>$ {item.hiring_rate}</>,
        status: (
          <>
            <span
              className={`${getStatusStyle(
                item.proposal_status.toLowerCase(),
                classes
              )}`}
            >
              {capitalizeFirstLetter(item.proposal_status)}
            </span>
          </>
        ),
        days: (
          <>
            {item.proposal_posting_schedules.map((day) => {
              return (
                <Chip
                  key={day.id}
                  label={capitalizeFirstLetter(day.schedule_day)}
                  color="primary"
                  sx={{
                    marginRight: 1,
                    marginBottom: 1,
                  }}
                />
              );
            })}
          </>
        ),
        history: (
          <>
            <Button
              variant="outline-primary"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #2561B0',
                borderRadius: '6px',
                color: '#2561B0',
                width: '100%',
                whiteSpace: 'nowrap',
                fontSize: 'bold',
              }}
              onClick={() => {
                setSelectedItem(item);
                setHistoryModal(true);
              }}
            >
              History
            </Button>
          </>
        ),
      };
    });
  const mobileColumns = [
    { field: 'date', headerName: 'Date' },
    { field: 'hiring_rate', headerName: 'Hiring Rate($/h)' },
    { field: 'status', headerName: 'Status' },
    { field: 'days', headerName: 'Days' },
    { field: 'history', headerName: 'History' },
  ];

  return (
    <div>
      {!isMobile && (
        <div
          style={{
            border: '1px solid #D9D9D9',
            borderRadius: '10px',
            width: '100%',
          }}
        >
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {columns.map((item, index) => (
                  <TableCell
                    key={item.id}
                    style={{ width: item.width }}
                    className={classes.cell}
                  >
                    {item.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow
                    key={item.id}
                    onClick={() => handleRowClick(item)}
                    className={
                      selectedItem && selectedItem.id === item.id
                        ? classes.selectedRow
                        : ''
                    }
                  >
                    <TableCell className={classes.cell}>
                      {moment(item.date_time).utc().format('DD:MM:YY HH:MM A')}
                    </TableCell>

                    <TableCell className={classes.cell}>
                      $ {item.hiring_rate}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <span
                        className={`${getStatusStyle(
                          item.proposal_status.toLowerCase(),
                          classes
                        )}`}
                      >
                        {capitalizeFirstLetter(item.proposal_status)}
                      </span>
                    </TableCell>

                    <TableCell className={classes.cell}>
                      {item.proposal_posting_schedules.map((day) => {
                        return (
                          <Chip
                            key={day.id}
                            label={capitalizeFirstLetter(day.schedule_day)}
                            color="primary"
                            sx={{ marginRight: 1, marginBottom: 1 }}
                          />
                        );
                      })}
                    </TableCell>

                    <TableCell className={classes.cell}>
                      <Button
                        variant="outline-primary"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: '1px solid #2561B0',
                          borderRadius: '6px',
                          color: '#2561B0',
                          width: '100%',
                          whiteSpace: 'nowrap',
                          fontSize: 'bold',
                        }}
                        onClick={() => {
                          setSelectedItem(item);
                          setHistoryModal(true);
                        }}
                      >
                        History
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      )}

      {isMobile && (
        <Table className={`${classes.table}`}>
          <TableBody>
            {mobileRows.map((row, rowIndex) => (
              <div
                key={row.id}
                style={{
                  margin: isMobile ? '0 0 15px 0' : '0 10px 15px',
                  backgroundColor: '#fff',
                  padding: '0 15px',
                  borderRadius: '6px',
                  boxShadow: '0px 2px 8px 0px #00000026',
                }}
              >
                <TableRow
                  key={row.id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    cursor: 'pointer',
                    // backgroundColor: 'wheat',
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                  }}
                  onClick={() => handleRowClick(rows[rowIndex])}
                >
                  <ExpandMoreIcon />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      padding: '0 10px',
                    }}
                  >
                    <>
                      {Array.from({ length: 2 }, (_, i) => (
                        <TableCell
                          style={{ borderBottom: 'none' }}
                          key={i}
                          className={classes.cell}
                          sx={{ padding: { xs: '8px', sm: '1rem' } }}
                        >
                          {row[mobileColumns[i].field] !== undefined
                            ? row[mobileColumns[i].field]
                            : mobileColumns[i].field}
                        </TableCell>
                      ))}
                    </>
                  </div>

                  <TableCell
                    style={{ borderBottom: 'none', marginLeft: '10px' }}
                    key={Math.floor(Math.random() * 10000)}
                    className={classes.cell}
                    sx={{ padding: { xs: '8px', sm: '1rem' } }}
                  >
                    {row[mobileColumns[2].field] !== undefined
                      ? row[mobileColumns[2].field]
                      : mobileColumns[2].field}
                  </TableCell>
                </TableRow>
                {selectedItem && selectedItem.id === row.id && (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        padding: '0 20px',
                        marginTop: '20px',
                      }}
                    >
                      <>
                        {Array.from(
                          { length: mobileColumns.length },
                          (_, i) =>
                            i >= 3 && (
                              <TableCell
                                style={{
                                  borderBottom: 'none',
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                                key={i}
                                className={classes.cell}
                                sx={{ padding: { xs: '8px', sm: '1rem' } }}
                              >
                                <div>
                                  <p
                                    style={{
                                      fontSize: '13px',
                                      fontFamily: 'Roboto',
                                      color: '#262626',
                                    }}
                                  >
                                    {mobileColumns[i]?.headerName}
                                  </p>
                                  <div
                                    style={{
                                      color: '#000000',
                                      fontSize: '15px',
                                      marginTop: '6px',
                                    }}
                                  >
                                    {selectedItem[mobileColumns[i]?.field] !==
                                    undefined
                                      ? selectedItem[mobileColumns[i]?.field]
                                      : mobileColumns[i]?.field}
                                  </div>
                                </div>
                              </TableCell>
                            )
                        )}
                      </>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </TableBody>
        </Table>
      )}

      {selectedApplicant && (
        <ApplicantPopup
          isOpen={isApplicantDataOpen}
          onClose={handleApplicantDataClose}
          selectedApplicant={selectedApplicant}
        />
      )}
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {errorApproveDialog && (
        <ErrorModal
          open={errorApproveDialog}
          handleClose={() => setErrorApproveDialog(false)}
          errorMessage={errorMessages}
        />
      )}

      {historyModal && (
        <ProposalHistoryModal
          selectedItem={selectedItem}
          open={historyModal}
          handleClose={() => setHistoryModal(false)}
        />
      )}
    </div>
  );
};
export default withStyles(styles)(ProposalsDataGrid);
