import { Box, Modal, Typography } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Container, Placeholder } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { API_BASE_URL, STRIPE_API_KEY } from '../../config/constants';
import AddCardForm from './CardForm/AddCardForm';

const stripePromise = loadStripe(STRIPE_API_KEY);

export default function CardForm({ open, handleClose, getBillingCards }) {
  const [selectedValue, setSelectedValue] = useState('1_1');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [paymentDialog, setPaymentDialog] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [setupIntent, setSetupIntent] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchIntent = async function () {
    const authToken = localStorage.getItem('auth_token');
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${API_BASE_URL}/owner/create-intent`,
          {},
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        )
        .then((res) => {
          setPaymentDialog(true);
          setClientSecret(res.data.setup_intent.client_secret);
          setSetupIntent(res.data.setup_intent);
        })
        .catch((e) => {
          setError(e.message);
        });
    });
  };

  useEffect(() => {
    setIsLoading(true);
    async function fetchData() {
      await fetchIntent();
    }

    fetchData();
  }, [open]);

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#ffffff',
      colorText: '#30313d',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '2px',
      borderRadius: '4px',
      margin: '0px',
    },
  };

  const CardPlaceholder = () => (
    <Container>
      {/* Form field placeholders */}
      <div className="d-flex flex-column gap-3">
        <div className="d-flex flex-wrap gap-3">
          <Placeholder
            as={Card.Title}
            animation="glow"
            className="flex-grow-1"
            style={{ height: '35px' }}
          >
            <Placeholder xs={12} />
          </Placeholder>
          <Placeholder
            as={Card.Title}
            animation="glow"
            className="flex-grow-1"
            style={{ height: '35px' }}
          >
            <Placeholder xs={5} /> <Placeholder xs={6} />
          </Placeholder>
        </div>

        <div className="d-flex flex-wrap gap-3">
          <Placeholder
            as={Card.Title}
            animation="glow"
            className="flex-grow-1"
            style={{ height: '35px' }}
          >
            <Placeholder xs={5} /> <Placeholder xs={6} />
          </Placeholder>
          <Placeholder
            as={Card.Title}
            animation="glow"
            className="flex-grow-1"
            style={{ height: '35px' }}
          >
            <Placeholder xs={11} />
          </Placeholder>
        </div>
        <Placeholder
          as={Card.Title}
          animation="glow"
          className="flex-grow-1"
          style={{ height: '35px' }}
        >
          <Placeholder xs={12} />
        </Placeholder>
      </div>

      {/* Justified buttons */}
      <div className="d-flex justify-content-between flex-wrap mt-3 gap-3">
        <Placeholder.Button
          bg="light"
          animation="glow"
          xs={5}
          className="flex-grow-1"
        />
        <Placeholder.Button xs={6} className="flex-grow-1" />
      </div>
    </Container>
  );

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 4,
    overflow: 'auto',
    maxHeight: '90vh',
    overflowX: 'auto',
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title">
      <Box sx={style}>
        <Header handleClose={handleClose} />
        <div style={{ backgroundColor: '#FAFAFA' }} className="pt-4 pb-0">
          {!paymentDialog ? (
            <CardPlaceholder />
          ) : (
            <Elements
              stripe={stripePromise}
              options={{ clientSecret, appearance }}
            >
              <AddCardForm
                handleClose={handleClose}
                getBillingCards={getBillingCards}
                setup_intent={setupIntent}
              />
            </Elements>
          )}
        </div>
      </Box>
    </Modal>
  );
}

function Header({ handleClose }) {
  return (
    <div className="d-flex justify-content-between align-items-center mb-4">
      <Typography variant="h6" component="h2" id="modal-title">
        Card Details
      </Typography>
      <CloseIcon handleClose={handleClose} />
    </div>
  );
}

function CloseIcon({ handleClose }) {
  return (
    <div onClick={handleClose} style={{ cursor: 'pointer' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        class="bi bi-x"
        viewBox="0 0 16 16"
      >
        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
      </svg>
    </div>
  );
}
