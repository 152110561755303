export const MuiTextField = {
  MuiTextField: {
    styleOverrides: {
      root: {
        '&.Mui-error .MuiInputBase-input': {
          borderColor: '#FA5A16', // Custom error border color
        },
      },
    },
  },
};
