import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from '@mui/material';
import { capitalizeFirstLetter } from '../../../utils/helper'; // Import your utility function
import CustomTime from '../../General/CustomTime'; // Import your CustomTime component

const UpdateTimeDialog = ({
  updateTimeModal,
  setUpdateTimeModal,
  selectedItem,
  timeZoneSelected,
  postingSchedules,
  updatedSelectedItem,
  setUpdatedSelectedItem,
  handleUpdatePostingSchedule,
  datesError,
}) => {
  return (
    <Dialog
      open={updateTimeModal}
      onClose={() => setUpdateTimeModal(false)}
      aria-labelledby="update-time-dialog-title"
      aria-describedby="update-time-dialog-description"
    >
      <Box sx={{ padding: 2 }}>
        <DialogTitle
          id="update-time-dialog-title"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">Change Time</Typography>
          <Button onClick={() => setUpdateTimeModal(false)}>X</Button>
        </DialogTitle>

        <DialogContent>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', marginBottom: 2 }}
          >
            <span style={{ fontSize: 14, color: '#595959' }}>
              {selectedItem.day.toUpperCase()}
            </span>
            <span style={{ fontSize: 20, color: '#262626', fontWeight: '500' }}>
              {selectedItem.date}
            </span>
          </Box>

          <Box
            sx={{
              backgroundColor: '#FAFAFA',
              padding: '20px',
              marginBottom: 2,
            }}
          >
            <Box
              sx={{
                marginBottom: '20px',
                borderBottom: '1px solid #D9D9D9',
                paddingBottom: '18px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>
                Start Time{' '}
                <span style={{ color: '#2561B0', fontWeight: '500' }}>
                  {timeZoneSelected[0].zone_name}
                </span>
              </Typography>
              <Typography>
                End Time{' '}
                <span style={{ color: '#2561B0', fontWeight: '500' }}>
                  {timeZoneSelected[0].zone_name}
                </span>
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <CustomTime
                hours={updatedSelectedItem.startHours}
                isActive={true}
                isError={postingSchedules.startDate === ' '}
                setHours={(newHours) =>
                  setUpdatedSelectedItem((prev) => ({
                    ...prev,
                    startHours: newHours,
                  }))
                }
                minutes={updatedSelectedItem.startMinutes}
                setMinutes={(newMinutes) =>
                  setUpdatedSelectedItem((prev) => ({
                    ...prev,
                    startMinutes: newMinutes,
                  }))
                }
                ampm={updatedSelectedItem.startPeriod}
                setAmpm={(newPeriod) =>
                  setUpdatedSelectedItem((prev) => ({
                    ...prev,
                    startPeriod: newPeriod,
                  }))
                }
              />
              <CustomTime
                hours={updatedSelectedItem.endHours}
                isError={!postingSchedules.endDate && datesError}
                isActive={true}
                setHours={(newHours) =>
                  setUpdatedSelectedItem((prev) => ({
                    ...prev,
                    endHours: newHours,
                  }))
                }
                minutes={updatedSelectedItem.endMinutes}
                setMinutes={(newMinutes) =>
                  setUpdatedSelectedItem((prev) => ({
                    ...prev,
                    endMinutes: newMinutes,
                  }))
                }
                ampm={updatedSelectedItem.endPeriod}
                setAmpm={(newPeriod) =>
                  setUpdatedSelectedItem((prev) => ({
                    ...prev,
                    endPeriod: newPeriod,
                  }))
                }
              />
            </Box>
          </Box>

          <Box sx={{ marginBottom: 2 }}>
            <Typography
              variant="h4"
              sx={{ color: '#2561B0', fontSize: '12px', fontWeight: '500' }}
            >
              REVERT TO ORIGINAL TIME
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '20px',
                justifyContent: 'space-between',
                alignItems: 'center',
                border: '1px solid #2561B0',
                padding: '18px 25px',
                borderRadius: '6px',
              }}
            >
              <Box>
                <h6>
                  {capitalizeFirstLetter(selectedItem.day)} {selectedItem.date}
                </h6>
                <p>
                  {selectedItem.startHours}:{selectedItem.startMinutes}{' '}
                  {selectedItem.startPeriod}
                </p>
              </Box>
              <Box>
                <h6>
                  {capitalizeFirstLetter(selectedItem.day)} {selectedItem.date}
                </h6>
                <p>
                  {selectedItem.endHours}:{selectedItem.endMinutes}{' '}
                  {selectedItem.endPeriod}
                </p>
              </Box>
              <Button
                variant="contained"
                sx={{ backgroundColor: '#2561B0', color: '#FFFFFF' }}
                onClick={() => setUpdatedSelectedItem(selectedItem)}
              >
                Revert Time
              </Button>
            </Box>
          </Box>

          <Grid item xs={12} md={4}>
            <Grid
              container
              spacing={1}
              sx={{ backgroundColor: '#D7E8FF', p: 1, borderRadius: 1 }}
            >
              <Grid item xs={1}>
                <ErrorOutlineOutlinedIcon sx={{ color: '#4A93F0' }} />
              </Grid>
              <Grid item xs={11} sx={{ fontSize: '0.9rem' }}>
                <p style={{ color: '#194378' }} className="fw-semibold mb-0">
                  Please Note
                </p>
                <p style={{ color: '#194378' }}>
                  Some states will require employer to pay minimum of 4 hours.
                </p>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="outlined" onClick={() => setUpdateTimeModal(false)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleUpdatePostingSchedule}>
            Confirm
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default UpdateTimeDialog;
