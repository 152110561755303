import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SuccessModal from '../../../../../components/General/SuccessModal';
import { getStatusStyle } from '../../../../../utils/CustomDataGridStyle';
import { capitalizeFirstLetter } from '../../../../../utils/helper';
import AcceptInterviewModal from './AcceptInterviewModal';
import ApplyInterviewModal from './ApplyInterviewModal';
import RescheduleInterviewModal from './RescheduleInterviewModal';

const styles = {
  table: {},
  cell: {
    padding: '10px',
  },
  newStatus: {
    backgroundColor: '#75B0FA',
    border: '1px solid #4A93F0',
    borderRadius: '16px',
    padding: '1px 8px',
  },
  activeStatus: {
    backgroundColor: '#4CAF50',
    border: '1px solid #388E3C',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  cancelledStatus: {
    backgroundColor: '#FA5A16',
    border: '1px solid #E54C0B',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  completedStatus: {
    backgroundColor: 'transparent',
    color: '#4CAF50',
  },
  updatedStatus: {
    backgroundColor: '#B6A8FF',
    border: '1px solid #7C67EB',
    borderRadius: '16px',
    padding: '1px 8px',
  },
  appliedStatus: {
    backgroundColor: '#FFC400',
    border: '1px solid #FFC400',
    borderRadius: '16px',
    padding: '1px 8px',
  },
  passStatus: {
    backgroundColor: '#FFC400',
    border: '1px solid #fff',
    borderRadius: '16px',
    padding: '1px 8px',
  },
  selectedRow: {
    backgroundColor: '#D7E8FF',
  },
};

// const getStatusStyle = (status, classes) => {
//   switch (status) {
//     case 'new':
//       return classes.newStatus;
//     case 'active':
//       return classes.activeStatus;
//     case 'pass':
//       return classes.passStatus;
//     case 'cancelled':
//       return classes.cancelledStatus;
//     case 'fail':
//       return classes.cancelledStatus;
//     case 'completed':
//       return classes.completedStatus;
//     case 'updated':
//       return classes.updatedStatus;
//     case 'applied':
//       return classes.appliedStatus;
//     case 'approved':
//       return classes.activeStatus;
//     case 'scheduled':
//       return classes.activeStatus;
//     case 'rejected':
//       return classes.cancelledStatus;
//     case 'suggested':
//       return classes.cancelledStatus;
//     default:
//       return status;
//   }
// };

const InterviewsDataGrid = ({
  classes,
  rows,
  columns,
  paging,
  selectedItem,
  setSelectedItem,
  fetchData,
  posting,
}) => {
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const { total, per_page, current_page } = paging;
  const [page, setPage] = useState(current_page - 1);
  const [rowsPerPage, setRowsPerPage] = useState(per_page);
  const navigate = useNavigate();
  const url = window.location.href;
  const posting_id = url.split('/')[6];

  useEffect(() => {
    setPage(current_page - 1);
    setRowsPerPage(per_page);
  }, [paging, current_page, per_page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSelectedItem(null);
    fetchData(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setSelectedItem(null);
    fetchData(1, newRowsPerPage);
  };

  const handleRowClick = (item) => {
    console.log('selectedItem.id', selectedItem?.id);
    console.log('passedItem.id', item?.id);

    if (selectedItem && selectedItem.id === item.id) {
      setSelectedItem(null);
      return;
    } else if (selectedItem && selectedItem.id !== item.id) {
      setSelectedItem(item);
      return;
    } else {
      setSelectedItem(item);
      return;
    }
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isProposalDialogOpen, setIsProposalDialogOpen] = useState(false);
  const [isRescheduleInterviewModalOpen, setIsRescheduleInterviewModal] =
    useState(false);
  const lo_user = localStorage.getItem('user');
  const lo_user_ob = JSON.parse(lo_user);
  const handleApplyClick = (item) => {
    // console.log('item === > ', item);
    setSelectedItem(item);
    setIsDialogOpen(true);
  };

  const [gridWidth, setGridWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setGridWidth(window.innerWidth > 800);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, [window.innerWidth]);

  const tableData = rows?.map((item, index) => {
    return {
      id: item.id,
      applicant: capitalizeFirstLetter(item.type),
      status: (
        <span style={getStatusStyle(item.interview_status.toLowerCase())}>
          {item.interview_status === 'rejected'
            ? 'Decline'
            : capitalizeFirstLetter(item.interview_status)}
        </span>
      ),
      start_time: item.start_time ? item.start_time : '-',
      end_time: item.end_time ? item.end_time : '-',
      selected_date: moment
        .utc(item.interview_date, 'YYYYMMDD HH:mm:ss')
        .tz(lo_user_ob.user_time_zone)
        .format('MM/DD/YY'),
      address: <>addresszz</>,
      actions:
        item.interview_status === 'new' ||
        item.interview_status === 'suggested' ? (
          <Button
            size="small"
            variant="outlined"
            style={{
              backgroundColor: '#2561B0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #2561B0',
              borderRadius: '3px',
              color: 'white',
              width: '100%',
              fontSize: 'bold',
              padding: '3px 15px',
              textTransform: 'capitalize',
            }}
            onClick={() => handleApplyClick(item)}
          >
            <CalendarMonthIcon style={{ fontSize: '15px', color: 'white' }} />
            Schedule
          </Button>
        ) : item.interview_status === 'pass' && item.proposal.length > 0 ? (
          <>
            {/* <Button
              style={{ backgroundColor: '#2561B0', border: 0 }}
              onClick={() => {
                navigate(`/professional/jobs/permanent/proposal/post/${posting_id}`);
              }}
            >
              View Proposal
            </Button> 
          */}
          </>
        ) : item.interview_status === 'pass' &&
          item.proposal[0]?.proposal_status === 'accepted' ? (
          <>Accepted Proposal</>
        ) : item.interview_status === 'pass' &&
          item.proposal[0]?.proposal_status === 'cancelled' ? (
          <>Declined</>
        ) : (
          <>----</>
        ),
    };
  });
  return (
    <div>
      {gridWidth && (
        <div
          style={{
            border: '1px solid #D9D9D9',
            borderRadius: '10px',
            backgroundColor: '#fff',
            overflow: 'auto',
          }}
        >
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {columns.map((item, index) => (
                  <TableCell
                    key={index}
                    style={{ width: item.width }}
                    className={classes.cell}
                  >
                    {item.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((item, index) => {
                return (
                  <TableRow
                    key={item.id}
                    onClick={() => handleRowClick(item)}
                    className={
                      selectedItem && selectedItem.id === item.id
                        ? classes.selectedRow
                        : ''
                    }
                  >
                    <TableCell className={classes.cell}>
                      {tableData[index].applicant}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {tableData[index].status}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {tableData[index].start_time}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {tableData[index].end_time}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {tableData[index].selected_date}
                    </TableCell>
                    <TableCell className={classes.cell}>- Hidden -</TableCell>

                    <TableCell
                      className={classes.cell}
                      style={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'left',
                        padding: 20,
                      }}
                    >
                      {tableData[index].actions}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      )}

      {!gridWidth && (
        <Table className={classes.table}>
          <TableBody>
            {tableData.map((row, rowIndex) => (
              <div
                style={{
                  margin: gridWidth ? '0 0 15px 0' : '0 10px 15px',
                  backgroundColor: '#fff',
                  padding: '0 15px',
                  borderRadius: '6px',
                  boxShadow: '0px 2px 8px 0px #00000026',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  <TableRow
                    key={rowIndex}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleRowClick(row)}
                  >
                    <ExpandMoreIcon />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        padding: '0 10px',
                      }}
                    >
                      <>
                        {Array.from({ length: 2 }, (_, i) => (
                          <TableCell
                            style={{ borderBottom: 'none' }}
                            key={i}
                            className={classes.cell}
                            sx={{ padding: { xs: '8px', sm: '1rem' } }}
                          >
                            {row[columns[i].field] !== undefined
                              ? row[columns[i].field]
                              : columns[i].field}
                          </TableCell>
                        ))}
                      </>
                    </div>

                    <TableCell
                      style={{ borderBottom: 'none', marginLeft: '10px' }}
                      key={Math.floor(Math.random() * 10000)}
                      className={classes.cell}
                      sx={{ padding: { xs: '8px', sm: '1rem' } }}
                    >
                      {row[columns[2].field] !== undefined
                        ? row[columns[2].field]
                        : columns[2].field}
                    </TableCell>
                  </TableRow>
                </div>
                {selectedItem && selectedItem.id === row.id && (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        padding: '0 20px',
                        marginTop: '20px',
                      }}
                    >
                      <>
                        {Array.from(
                          { length: columns.length },
                          (_, i) =>
                            i >= 3 && (
                              <TableCell
                                style={{
                                  borderBottom: 'none',
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                                key={i}
                                className={classes.cell}
                                sx={{ padding: { xs: '8px', sm: '1rem' } }}
                              >
                                <div>
                                  <p
                                    style={{
                                      fontSize: '13px',
                                      fontFamily: 'Roboto',
                                      color: '#262626',
                                    }}
                                  >
                                    {columns[i]?.headerName}
                                  </p>
                                  <div
                                    style={{
                                      color: '#000000',
                                      fontSize: '15px',
                                      marginTop: '6px',
                                    }}
                                  >
                                    {selectedItem[columns[i]?.field] !==
                                    undefined
                                      ? selectedItem[columns[i]?.field]
                                      : columns[i]?.field}
                                  </div>
                                </div>
                              </TableCell>
                            )
                        )}
                      </>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </TableBody>
        </Table>
      )}

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {isDialogOpen && selectedItem && (
        <ApplyInterviewModal
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          item={selectedItem}
          posting={posting}
          fetchData={fetchData}
          onRescheduleInterviewModal={() => setIsRescheduleInterviewModal(true)}
        />
      )}

      {isRescheduleInterviewModalOpen && (
        <RescheduleInterviewModal
          isOpen={isRescheduleInterviewModalOpen}
          onClose={() => setIsRescheduleInterviewModal(false)}
          setOpenSuccessModal={setOpenSuccessModal}
          setSuccessMessage={setSuccessMessage}
          item={selectedItem}
          fetchData={fetchData}
        />
      )}

      {isProposalDialogOpen && selectedItem && (
        <AcceptInterviewModal
          isOpen={isProposalDialogOpen}
          onClose={() => setIsProposalDialogOpen(false)}
          item={selectedItem}
          setOpenSuccessModal={setOpenSuccessModal}
          setSuccessMessage={setSuccessMessage}
          fetchData={fetchData}
        />
      )}

      {openSuccessModal && (
        <SuccessModal
          open={openSuccessModal}
          handleClose={() => setOpenSuccessModal(false)}
          successMessage={successMessage}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(InterviewsDataGrid);
