import { FormControl, FormHelperText } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React from 'react';

import '../../App.css';

export default function CustomDatePicker({
  label,
  value,
  onChange,
  error,
  disabled,
  size = 'small',
}) {
  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    onChange(formattedDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl className="pt-2">
        {/* <DemoContainer
        components={['DatePicker']}
        sx={{
          '& .MuiFormControl-root': {
            width: '100%',
            // overflow: 'hidden',
            // padding: '0px',
          },
        }}
      > */}
        {value ? (
          <DatePicker
            label={label}
            disabled={disabled}
            slotProps={{ textField: { size: size } }}
            value={value && dayjs(value)}
            onChange={(newValue) => {
              handleDateChange(newValue);
            }}
            sx={
              error
                ? {
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '5rem',

                      '& fieldset': {
                        borderColor: '#FA5A16',
                      },
                      '&:hover fieldset': {
                        borderColor: '#FA5A16',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#FA5A16',
                      },
                    },
                  }
                : { width: '100%' }
            }
            className={error ? 'border rounded' : ''}
            inputProps={{
              required: true,
            }}
          />
        ) : (
          <DatePicker
            label={label}
            disabled={disabled}
            slotProps={{ textField: { size: size } }}
            onChange={(newValue) => {
              handleDateChange(newValue);
            }}
            className={error ? 'border rounded' : ''}
            inputProps={{
              required: true,
            }}
            sx={
              error
                ? {
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#FA5A16',
                      },
                      '&:hover fieldset': {
                        borderColor: '#FA5A16',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#FA5A16',
                      },
                    },
                  }
                : {}
            }
          />
        )}
      </FormControl>
      {/* </DemoContainer> */}

      {error && (
        <FormHelperText style={{ color: '#FA5A16' }}>
          Please choose a date.
        </FormHelperText>
      )}
    </LocalizationProvider>
  );
}
