import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { getStatusStyle } from '../../../../../utils/CustomDataGridStyle';
import { capitalizeFirstLetter } from '../../../../../utils/helper';
// import CalendarIcon from '../../../../../assets/icons/calendar2.svg';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import ShowMoreIcon from '../../../../../assets/icons/showmore.png';
import useMobile from '../../../../../hooks/useMobile';
import InterviewSchedulesModal from './InterviewSchedulesModal';

const styles = {
  table: {},
  cell: {
    padding: '10px',
  },
  newStatus: {
    backgroundColor: '#75B0FA',
    border: '1px solid #4A93F0',
    borderRadius: '16px',
    padding: '1px 8px',
  },
  activeStatus: {
    backgroundColor: '#4CAF50',
    border: '1px solid #388E3C',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  cancelledStatus: {
    backgroundColor: '#FA5A16',
    border: '1px solid #E54C0B',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  completedStatus: {
    backgroundColor: 'transparent',
    color: '#4CAF50',
  },
  updatedStatus: {
    backgroundColor: '#B6A8FF',
    border: '1px solid #7C67EB',
    borderRadius: '16px',
    padding: '1px 8px',
  },
  appliedStatus: {
    backgroundColor: '#FFC400',
    border: '1px solid #FFC400',
    borderRadius: '16px',
    padding: '1px 8px',
  },
  selectedRow: {
    backgroundColor: '#D7E8FF',
  },
};

// const getStatusStyle = (status, classes) => {
//   switch (status) {
//     case 'new':
//       return classes.newStatus;
//     case 'active':
//       return classes.activeStatus;
//     case 'cancelled':
//       return classes.cancelledStatus;
//     case 'completed':
//       return classes.completedStatus;
//     case 'updated':
//       return classes.updatedStatus;
//     case 'applied':
//       return classes.appliedStatus;
//     case 'pass':
//       return classes.appliedStatus;
//     case 'approved':
//       return classes.activeStatus;
//     case 'scheduled':
//       return classes.activeStatus;
//     case 'rejected':
//       return classes.cancelledStatus;
//     case 'fail':
//       return classes.cancelledStatus;
//     case 'suggested':
//       return classes.cancelledStatus;
//     default:
//       return status;
//   }
// };

const InterviewsDataGrid = ({
  classes,
  rows,
  columns,
  paging,
  selectedItem,
  setSelectedItem,
  fetchData,
}) => {
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [isScheduleDialogOpen, setIsScheduleDialogOpen] = useState(false);
  const [actions, setActions] = useState(false);

  const { total, per_page, current_page } = paging;
  const [page, setPage] = useState(current_page - 1);
  const [rowsPerPage, setRowsPerPage] = useState(per_page);
  const navigate = useNavigate();
  const url = window.location.href;
  const posting_id = url.split('/')[6];

  useEffect(() => {
    setPage(current_page - 1);
    setRowsPerPage(per_page);
  }, [paging, current_page, per_page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSelectedItem(null);
    fetchData(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setSelectedItem(null);
    fetchData(1, newRowsPerPage);
  };

  const handleRowClick = (item) => {
    if (selectedItem && selectedItem.id === item.id) {
      setSelectedItem(null);
    } else if (selectedItem && selectedItem.id !== item.id) {
      setSelectedItem(item);
    } else {
      setSelectedItem(item);
    }
    console.log('selectedItem', selectedItem);
    return;
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isProposalDialogOpen, setIsProposalDialogOpen] = useState(false);
  const [isRescheduleInterviewModalOpen, setIsRescheduleInterviewModal] =
    useState(false);

  const handleApplyClick = (item) => {
    setSelectedItem(item);
    setIsScheduleDialogOpen(true);
  };

  const tableRows = rows?.map((item, index) => {
    return {
      id: item.id,
      applicant: capitalizeFirstLetter(item.type),
      user_location_id: null,
      posting_status: (
        <span style={getStatusStyle(item.interview_status.toLowerCase())}>
          {item.interview_status === 'rejected'
            ? 'Decline'
            : capitalizeFirstLetter(item.interview_status)}
        </span>
      ),
      start_date: item.start_time ? item.start_time : '-',
      end_date: item.interview_date ? item.interview_date : '-',
      applicants_count: (
        <>
          {item.interview_status === 'new' ||
          item.interview_status === 'suggested' ? (
            <Button
              size="small"
              variant="outlined"
              style={{
                backgroundColor: '#2561B0',
                border: 0,
                color: 'white',
              }}
              onClick={() => handleApplyClick(item)}
            >
              <CalendarMonthIcon
                style={{ fontSize: '15px', marginRight: '0.5rem' }}
              />
              Schedules
            </Button>
          ) : (
            <></>
          )}
        </>
      ),
    };
  });

  const isMobile = useMobile(767);

  return (
    <div>
      {!isMobile && (
        <div
          style={{
            border: '1px solid #D9D9D9',
            borderRadius: '10px',
            width: '100%',
          }}
        >
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {columns.map((item, index) => (
                  <TableCell
                    key={index}
                    style={{ width: item.width }}
                    className={classes.cell}
                  >
                    {item.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((item, index) => {
                return (
                  <TableRow
                    key={index}
                    onClick={() => handleRowClick(item)}
                    className={
                      selectedItem && selectedItem.id === item.id
                        ? classes.selectedRow
                        : ''
                    }
                  >
                    <TableCell className={classes.cell}>
                      {tableRows[index].applicant}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {tableRows[index].posting_status}
                    </TableCell>
                    <TableCell className={classes.cell}></TableCell>
                    <TableCell className={classes.cell}>
                      {tableRows[index].start_date}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {tableRows[index].end_date}
                    </TableCell>
                    <TableCell
                      className={classes.cell}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {tableRows[index].applicants_count}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}

      {isMobile && (
        <>
          <div>
            <Table className={classes.table}>
              <TableBody>
                {tableRows.map((row, rowIndex) => (
                  <div
                    style={{
                      margin: isMobile ? '0 0 15px 0' : '0 10px 15px',
                      backgroundColor: '#fff',
                      padding: '0 15px',
                      borderRadius: '6px',
                      boxShadow: '0px 2px 8px 0px #00000026',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                      }}
                    >
                      <TableRow
                        key={rowIndex}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleRowClick(row)}
                      >
                        <ExpandMoreIcon />
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            padding: '0 10px',
                          }}
                        >
                          <>
                            {Array.from({ length: 2 }, (_, i) => (
                              <TableCell
                                style={{ borderBottom: 'none' }}
                                key={i}
                                className={classes.cell}
                                sx={{ padding: { xs: '8px', sm: '1rem' } }}
                              >
                                {row[columns[i].field] !== undefined
                                  ? row[columns[i].field]
                                  : columns[i].field}
                              </TableCell>
                            ))}
                          </>
                        </div>

                        <TableCell
                          style={{ borderBottom: 'none', marginLeft: '10px' }}
                          key={Math.floor(Math.random() * 10000)}
                          className={classes.cell}
                          sx={{ padding: { xs: '8px', sm: '1rem' } }}
                        >
                          {row[columns[2].field] !== undefined
                            ? row[columns[2].field]
                            : columns[2].field}
                        </TableCell>
                      </TableRow>
                      {setActions && false && (
                        <div
                          onClick={() => {
                            setSelectedItem(row);
                            setActions(true);
                          }}
                          style={{
                            cursor: 'pointer',
                            border: '1px solid #E8E8E8',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            src={ShowMoreIcon}
                            style={{ padding: '10px' }}
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                    {selectedItem && selectedItem.id === row.id && (
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            padding: '0 20px',
                            marginTop: '20px',
                          }}
                        >
                          <>
                            {Array.from(
                              { length: columns.length },
                              (_, i) =>
                                i >= 3 && (
                                  <TableCell
                                    style={{
                                      borderBottom: 'none',
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                    key={i}
                                    className={classes.cell}
                                    sx={{ padding: { xs: '8px', sm: '1rem' } }}
                                  >
                                    <div>
                                      <p
                                        style={{
                                          fontSize: '13px',
                                          fontFamily: 'Roboto',
                                          color: '#262626',
                                        }}
                                      >
                                        {columns[i]?.headerName}
                                      </p>
                                      <div
                                        style={{
                                          color: '#000000',
                                          fontSize: '15px',
                                          marginTop: '6px',
                                        }}
                                      >
                                        {selectedItem[columns[i]?.field] !==
                                        undefined
                                          ? selectedItem[columns[i]?.field]
                                          : columns[i]?.field}
                                      </div>
                                    </div>
                                  </TableCell>
                                )
                            )}
                          </>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </TableBody>
            </Table>
            {paging && (
              <>
                {paging?.total === 0 && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '40vh',
                      color: '#D9D9D9',
                    }}
                  >
                    <span></span>
                  </div>
                )}
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={paging?.total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </div>
        </>
      )}

      {isScheduleDialogOpen && selectedItem && (
        <InterviewSchedulesModal
          isOpen={isScheduleDialogOpen}
          onClose={() => setIsScheduleDialogOpen(false)}
          item={selectedItem}
          fetchData={fetchData}
          onRescheduleInterviewModal={() => setIsRescheduleInterviewModal(true)}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(InterviewsDataGrid);
