export const MuiTablePagination = {
  MuiTablePagination: {
    styleOverrides: {
      root: {
        '@media (max-width: 600px)': {
          marginLeft: '0px',
          marginRight: '0px',
          paddingLeft: '0px',
          paddingRight: '0px',
        },
      },
      toolbar: {
        '@media (max-width: 600px)': {
          paddingLeft: '0px',
          paddingRight: '0px',
          justifyContent: 'space-between',
        },
      },
      input: {
        '@media (max-width: 600px)': {
          marginLeft: '0px',
          marginRight: '0px',
        },
      },
      select: {
        '@media (max-width: 600px)': {
          margin: '0px',
          paddingLeft: '5px',
          minWidth: '0px',
        },
      },
      selectIcon: {
        '@media (max-width: 600px)': {
          margin: '0px',
        },
      },
      spacer: {
        '@media (max-width: 600px)': {
          display: 'none',
        },
      },
      actions: {
        '@media (max-width: 600px)': {
          marginLeft: '0px',
          paddingLeft: '0px',
        },
      },
    },
  },
};
