import { makeStyles } from '@material-ui/core/styles';
import { Star } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Rating,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import CustomDatePicker from '../../../../components/CreatePosting/CustomDatePicker';

const useStyles = makeStyles((theme) => ({
  drawer: {
    maxWidth: 340,
    width: '100%',
    flexShrink: 0,
  },
  drawerPaper: {
    padding: theme.spacing(2),
    maxWidth: 340,
    width: '100%',
  },
  heading: {
    margin: theme.spacing(2),
  },
  specialtyBox: {
    margin: theme.spacing(1),
  },

  ratingBox: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    minWidth: '100%',
  },
}));

function FiltersSidebarPreviouslyBooked({
  open,
  handleClose,
  selectRating,
  setSelectRating,

  professional,
  setProfessional,
  date,
  setDate,
  posting,
  setPosting,
  type,
  setType,
  handleFilterData,
  resetFilter,
}) {
  const classes = useStyles();

  const handleSelectRating = (event) => {
    const {
      target: { value },
    } = event;
    setSelectRating(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <Drawer
      className={classes.drawer}
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="1rem"
      >
        <Typography variant="h5" style={{ fontWeight: 500 }}>
          Filters
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          label="Professional"
          id="professional"
          value={professional}
          onChange={(e) => setProfessional(e.target.value)}
          InputProps={{
            style: {
              textAlign: 'center',
              padding: '10px',
            },
          }}
        />

        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="Posting"
          id="posting"
          value={posting}
          onChange={(e) => setPosting(e.target.value)}
          InputProps={{
            style: {
              textAlign: 'center',
              padding: '10px',
            },
          }}
        />
        <CustomDatePicker
          label="Last Worked Date"
          onChange={(date) => setDate(date)}
          value={date}
          size="medium"
        />

        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Status"
            value={type}
            onChange={(e) => setType(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
            }}
          >
            <MenuItem value="1">Banned</MenuItem>
            <MenuItem value="0">Unbanned</MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel id="name-label">Rating</InputLabel>
          <Select
            style={{
              width: '100%',
              padding: '10px',
            }}
            size="small"
            label="Rating"
            labelId="Rating-select-label"
            id="Rating-select-label"
            multiple
            value={selectRating}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <>
                    {value},
                    <Star sx={{ color: 'orange' }} />
                  </>
                ))}
              </Box>
            )}
            onChange={handleSelectRating}
          >
            <MenuItem value={5}>
              <Rating name="read-only" value={5} readOnly />
            </MenuItem>
            <MenuItem value={4}>
              <Rating name="read-only" value={4} readOnly />
            </MenuItem>
            <MenuItem value={3}>
              <Rating name="read-only" value={3} readOnly />
            </MenuItem>
            <MenuItem value={2}>
              <Rating name="read-only" value={2} readOnly />
            </MenuItem>
            <MenuItem value={1}>
              <Rating name="read-only" value={1} readOnly />
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '3rem',
        }}
      >
        <Button
          variant="outlined"
          onClick={resetFilter}
          style={{
            color: '#595959',
            width: '100%',
            marginRight: '1rem',
            borderColor: '#2561B0',
          }}
        >
          Reset
        </Button>
        <Button
          onClick={handleFilterData}
          variant="contained"
          style={{
            backgroundColor: '#2561B0',
            color: 'white',
            width: '100%',
          }}
        >
          Find
        </Button>
      </Box>
    </Drawer>
  );
}

export default FiltersSidebarPreviouslyBooked;
