import { MyLocation, Person2Outlined } from '@mui/icons-material';
import { Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CustomDataGrid from '../../../components/General/CustomDataGrid';
import Layout from '../../../components/Layout';
import { API_BASE_URL } from '../../../config/constants';
import DeleteLocationModal from './DeleteLocationModal';

const OwnerLocations = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [locations, setLocations] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const navigate = useNavigate();

  const fetchLocations = (page, limit) => {
    axios
      .get(`${API_BASE_URL}/owner/locations?page=${page}&limit=${limit}`, {
        headers: {
          method: 'GET',
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      })
      .then((res) => {
        setLocations(res.data);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    fetchLocations(1, 10);
  }, []);

  const ownerLocationRows = locations?.data?.map((item) => {
    return {
      id: item.id,
      place_name: item.place_name ?? '',
      address: item.address ?? '-',
      contact_first_name: (
        <div>
          {item.contact_first_name && item.contact_last_name ? (
            <>
              {item.contact_first_name} {item.contact_last_name}
            </>
          ) : (
            '-'
          )}
        </div>
      ),
      location_email: item.location_email ?? '-',
      location_phone: item.location_phone ?? '-',
    };
  });

  const columns = [
    { field: 'place_name', headerName: 'Practice Name', width: 150 },
    { field: 'address', headerName: 'Address', width: 150 },
    {
      field: 'contact_first_name',
      headerName: 'Contact Person Name',
      width: 150,
    },
    { field: 'location_email', headerName: 'E-mail', width: 150 },
    { field: 'location_phone', headerName: 'Phone', width: 150 },
  ];

  return (
    <Layout
      items={[
        {
          name: 'My Account',
          link: '/',
          icon: <Person2Outlined sx={{ py: 0.2 }} />,
        },
        {
          name: 'Locations',
          link: '/owner/account/profile',
          icon: <MyLocation sx={{ py: 0.2 }} />,
        },
      ]}
    >
      <Grid
        sx={{
          px: 3,
          pt: 2,
          pb: 1,
          borderBottom: '1px solid #D9D9D9',
          width: 'auto',
        }}
      >
        <h4 className="pb-0 mb-1" style={{ color: '#262626' }}>
          Locations
        </h4>
        <p style={{ color: '#8C8C8C', fontSize: '0.8rem' }}>
          Management of Locations
        </p>
      </Grid>

      <div
        style={{
          display: 'flex',
          gap: 20,
          backgroundColor: '#F5F5F5',
          padding: '12px 20px',
          borderBottom: '1px solid #D9D9D9',
        }}
      >
        <Button
          variant="outlined"
          style={{
            border: '1px solid #2561B0',
            color: '#595959',
            backgroundColor: '#fff',
          }}
          onClick={() => navigate('/owner/account/locations/add')}
        >
          Add
        </Button>
        <Button
          variant="outlined"
          style={{
            border:
              selectedItem !== null ? '1px solid #2561B0' : '1px solid #D9D9D9',
            color: selectedItem !== null ? '#595959' : '#BFBFBF',
            backgroundColor: selectedItem != null ? '#fff' : '#F5F5F5',
          }}
          disabled={
            selectedItem === null || selectedItem.posting_status === 'cancelled'
          }
          onClick={() =>
            navigate(`/owner/account/locations/${selectedItem.id}`)
          }
        >
          Edit
        </Button>

        <Button
          variant="outlined"
          style={{
            border:
              selectedItem != null ? '1px solid #FA5A16' : '1px solid #D9D9D9',
            color: selectedItem != null ? '#FA5A16' : '#BFBFBF',
            backgroundColor: selectedItem != null ? '#fff' : '#F5F5F5',
          }}
          disabled={selectedItem == null}
          onClick={() => setOpenDeleteModal(true)}
        >
          Delete
        </Button>
      </div>

      {ownerLocationRows && locations && locations.data && (
        <CustomDataGrid
          rows={ownerLocationRows}
          columns={columns}
          paging={locations?.paging}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          fetchData={fetchLocations}
        />
      )}

      {selectedItem && openDeleteModal && (
        <DeleteLocationModal
          open={openDeleteModal}
          handleClose={() => setOpenDeleteModal(false)}
          selectedItem={selectedItem}
          fetchData={fetchLocations}
        />
      )}
    </Layout>
  );
};

export default OwnerLocations;
