import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ScheduleIcon from '@mui/icons-material/Schedule';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ShowMoreIcon from '../../../../assets/icons/showmore.png';
import ApplicantPopup from '../../../../components/General/ApplicantPopup';
import ErrorModal from '../../../../components/General/ErrorModal';
import LoadingButton from '../../../../components/General/LoadingButton';
import MarkPostCompletedDialog from '../../../../components/General/MarkPostCompletedDialog';
import StarRating from '../../../../components/General/StarRating';
import { API_BASE_URL } from '../../../../config/constants';
import useMobile from '../../../../hooks/useMobile';
import { getStatusStyle } from '../../../../utils/CustomDataGridStyle';
import { capitalizeFirstLetter } from '../../../../utils/helper';
import AddReviewModal from '../../BookedPostings/CurrentlyBooked/AddReviewModal';
import '../TemporaryJobs/PostingApplicants.css';
import ButtonsDialog from '../../../../components/General/CustomButtonsDialog';

const styles = {
  table: {},
  cell: {
    padding: '10px',
  },
  newStatus: {
    backgroundColor: '#75B0FA',
    border: '1px solid #4A93F0',
    borderRadius: '16px',
    padding: '1px 8px',
  },
  activeStatus: {
    backgroundColor: '#4CAF50',
    border: '1px solid #388E3C',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  cancelledStatus: {
    backgroundColor: '#ff9900',
    border: '1px solid #ff9900',
    borderRadius: '16px',
    padding: '1px 8px',
  },
  completedStatus: {
    backgroundColor: '#ff9900',
    border: '1px solid #ff9900',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  // completedStatus: {
  //   backgroundColor: 'transparent',
  //   color: '#4CAF50',
  // },
  updatedStatus: {
    backgroundColor: '#B6A8FF',
    border: '1px solid #7C67EB',
    borderRadius: '16px',
    padding: '1px 8px',
  },
  appliedStatus: {
    backgroundColor: '#FFC400',
    border: '1px solid #FFC400',
    borderRadius: '16px',
    padding: '1px 8px',
  },
  selectedRow: {
    backgroundColor: '#D7E8FF',
  },
  rejectedStatus: {
    backgroundColor: '#FA5A16',
    border: '1px solid #E54C0B',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  interviewingStatus: {
    backgroundColor: '#7bd17e',
    border: '1px solid #ffffff',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  interviewingPassStatus: {
    backgroundColor: '#8e8fc4',
    border: '1px solid #ffffff',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },

  interviewingSuggestedStatus: {
    backgroundColor: '#659c9f',
    border: '1px solid #659c9f',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },

  interviewingScheduledStatus: {
    backgroundColor: '#ccb8ed',
    border: '1px solid #ccb8ed',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'black',
  },
  interviewingDeclinedStatus: {
    backgroundColor: '#101010',
    border: '1px solid #101010',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  interviewingFailStatus: {
    backgroundColor: '#101010',
    border: '1px solid #101010',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },

  approvedStatus: {
    backgroundColor: '#4CAF50',
    border: '1px solid #388E3C',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  proposalSentStatus: {
    backgroundColor: '#7bd17e',
    border: '1px solid #ffffff',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
};

const ApplicantsDataGrid = ({
  classes,
  rows,
  columns,
  paging,
  selectedItem,
  setSelectedItem,
  fetchApplicants,
  postingId,
  setInterviewDetailsOpen,
  setEnableProposal,
  setProfessionalProposedInterviewDetailsOpen,
  gridWidth,
}) => {
  const { total, per_page, current_page } = paging;
  const [page, setPage] = useState(current_page - 1);
  const [rowsPerPage, setRowsPerPage] = useState(per_page);

  const [errorApproveDialog, setErrorApproveDialog] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [openViewReview, setOpenViewReview] = useState(false);
  const [user, setUser] = useState(null);
  const [posting, setPosting] = useState({});
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);

  const [reLoading, setReLoading] = useState(false);
  const [reFinished, setReFinished] = useState(false);
  const [actions, setActions] = useState(false);

  const [isMarkPostCompletedDialogOpen, setIsMarkPostCompletedDialogOpen] =
    useState(false);

  const [loadingButtonArray, setLoadingButtonArray] = useState(() => {
    var btn_array = [];
    rows.map((row, index) => {
      btn_array.push({
        loading: false,
        finished: false,
      });
    });
    return btn_array;
  });

  const [declineloadingButtonArray, setDeclineLoadingButtonArray] = useState(
    () => {
      var btn_array = [];
      rows.map((row, index) => {
        btn_array.push({
          loading: false,
          finished: false,
        });
      });
      return btn_array;
    }
  );

  const navigate = useNavigate();

  const getRate = (user_location) => {
    var rate = user_location.filter((location) => {
      return location.is_current === true;
    });
    if (rate.length !== 0) {
      return rate[0].desired_rate;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    setPage(current_page - 1);
    setRowsPerPage(per_page);
  }, [paging, current_page, per_page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSelectedItem(null);
    fetchApplicants(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setSelectedItem(null);
    fetchApplicants(1, newRowsPerPage);
  };

  const isProposal = (item) => {
    if (
      item?.posting_applicants?.length > 0 &&
      item.posting_applicants[0]?.interview_count > 0
    ) {
      return true;
    }
    return false;
  };

  const handleRowClick = (item) => {
    if (selectedItem && selectedItem.id === item.id) {
      setSelectedItem(null);
      setEnableProposal(isProposal(item));
    } else if (selectedItem && selectedItem.id !== item.id) {
      setSelectedItem(item);
      setEnableProposal(isProposal(item));
    } else {
      setSelectedItem(item);
      setEnableProposal(isProposal(item));
    }

    return;
  };

  const updateButtonChanged = (state, index, key, value) => {
    if (state === 'decline') {
      var updatedAreas = [...loadingButtonArray];
      updatedAreas[index][key] = value;
      setLoadingButtonArray(updatedAreas);
    }
    if (state === 'reinvite') {
      var updatedAreas = [...declineloadingButtonArray];
      updatedAreas[index][key] = value;
      setDeclineLoadingButtonArray(updatedAreas);
    }
  };

  useEffect(() => {}, [declineloadingButtonArray]);

  const declineAction = (item, index) => {
    updateButtonChanged('decline', index, 'loading', true);
    // setLoading(true);
    axios
      .put(
        `${API_BASE_URL}/owner/applicants/${item.posting_applicants[0].id}/decline`,
        {
          posting_applicant_id: item.posting_applicants[0].id,
        },
        {
          headers: {
            method: 'PUT',
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
          },
        }
      )
      .then((res) => {
        fetchApplicants(1, 10);
        updateButtonChanged('decline', index, 'finished', true);
        // setFinished(true);
      })
      .catch((res) => {
        updateButtonChanged('decline', index, 'finished', true);
      });
  };

  const reinviteAction = (item, index) => {
    updateButtonChanged('reinvite', index, 'loading', true);
    axios
      .put(
        `${API_BASE_URL}/owner/applicants/${item.posting_applicants[0].id}/re-invite`,
        {
          posting_applicant_id: item.posting_applicants[0].id,
        },
        {
          headers: {
            method: 'PUT',
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
          },
        }
      )
      .then((res) => {
        fetchApplicants(1, 10);
        updateButtonChanged('reinvite', index, 'finished', true);
      })
      .catch((res) => {
        updateButtonChanged('reinvite', index, 'finished', true);
      });
  };

  const [isApplicantDataOpen, setIsApplicantDataOpen] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const handleNameClick = (item) => {
    setSelectedApplicant(item);
    setIsApplicantDataOpen(true);
  };

  const handleApplicantDataClose = () => {
    setSelectedApplicant(null);
    setIsApplicantDataOpen(false);
  };

  const hireApplicant = (id) => {
    if (id) {
      axios
        .put(`${API_BASE_URL}/owner/applicants/${id}/hire`, null, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
          },
        })
        .then((res) => {
          fetchApplicants(page + 1, rowsPerPage);
        })
        .catch((res) => {
          setErrorApproveDialog(true);
          setErrorMessages(res.response.data.message.description);
        });
    }
  };

  const url = window.location.href;
  const dialogId = url.substring(url.lastIndexOf('/') + 1);

  const buttons = [
    {
      type: 'button',
      label: 'Send/View Proposals',
      onClick: () => {
        navigate(
          `/owner/postings/permanent/proposal/applicant/${selectedItem.id}/post/${dialogId}`
        );
      },
      disabled:
        selectedItem === null || selectedItem?.posting_status === 'cancelled',
      backgroundColor: '#fff',
      borderColor: '#2561B0',
      color: '#595959',
      marginTop: '16px',
    },
    {
      type: 'button',
      label: 'Interview Schedules',
      onClick: () => {
        navigate(
          `/owner/postings/permanent/applicant/interview/details/${dialogId}/applicants/${
            rows.find((obj) => obj.id === selectedItem?.id)
              .posting_applicants[0].id
          }/schedule`
        );
      },
      disabled:
        selectedItem === null || selectedItem?.posting_status === 'cancelled',
      backgroundColor: '#fff',
      borderColor: '#2561B0',
      color: '#595959',
      marginTop: '16px',
    },
    {
      type: 'button',
      label: 'Interview Calendar',
      onClick: () => {
        navigate(`/owner/postings/permanent/interview-calendar/${dialogId}`);
      },
      disabled:
        selectedItem === null || selectedItem?.posting_status === 'cancelled',
      backgroundColor: '#fff',
      borderColor: '#2561B0',
      color: '#595959',
      marginTop: '16px',
    },
  ];

  const tableRows = rows?.map((item, index) => {
    return {
      id: item.id,
      applicant: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <img
            src={`${API_BASE_URL}/assets/${item?.avatar}`}
            style={{
              width: 30,
              height: 30,
              borderRadius: '50%',
              cursor: 'pointer',
            }}
            onClick={() => handleNameClick(item)}
            alt=""
          />
          <p
            style={{
              fontSize: 14,
              color: '#2561B0',
              textDecoration: 'underline',
              paddingTop: '15px',
              cursor: 'pointer',
              padding: 0,
            }}
            onClick={() => handleNameClick(item)}
          >
            {item.first_name} {item.last_name}
          </p>
        </div>
      ),
      rate: getRate(item.user_locations),
      averageScore: <StarRating rating={item.average_score} />,
      status: (
        <span
          style={getStatusStyle(
            item.posting_applicants[0].applicant_status.toLowerCase()
          )}
        >
          {capitalizeFirstLetter(item.posting_applicants[0].applicant_status)}
        </span>
      ),
      actions: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
          }}
        >
          {item.posting_applicants[0].application_status === 'rejected' ? (
            <>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                  <Grid item xs={10}>
                    <LoadingButton
                      style={{
                        backgroundColor: '#2561B0',
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px solid #2561B0',
                        borderRadius: '3px',
                        width: '100%',
                        fontSize: 'bold',
                        padding: '3px 15px',
                      }}
                      loading={declineloadingButtonArray[index].loading}
                      done={declineloadingButtonArray[index].finished}
                      onClick={() => reinviteAction(item, index)}
                    >
                      Re-Invite
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : item.posting_applicants[0].application_status === 'hired' ? (
            <>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                  <Grid item xs={10}>
                    <Button
                      size="small"
                      variant="outline-primary"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px solid #2561B0',
                        borderRadius: '3px',
                        color: '#2561B0',
                        width: '100%',
                        fontSize: 'bold',
                        padding: '3px 15px',
                      }}
                      onClick={() => {
                        setSelectedItem({ id: item?.id });
                        setIsMarkPostCompletedDialogOpen(true);
                      }}
                    >
                      Mark Complete
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : item.posting_applicants[0].application_status === 'completed' ? (
            <>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                  <Grid item xs={10}>
                    <Button
                      size="small"
                      variant="outline-primary"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px solid #2561B0',
                        borderRadius: '3px',
                        color: '#2561B0',
                        width: '100%',
                        fontSize: 'bold',
                        padding: '3px 15px',
                      }}
                      onClick={() => {
                        setOpenViewReview(true);
                        setSelectedItem({ id: item?.id });
                        setPosting(item.posting_applicants[0].posting);
                        setUser(item);
                      }}
                    >
                      Review
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            <>
              <Box className="d-flex flex-wrap gap-2">
                <Button
                  size="small"
                  variant="outline-primary"
                  style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    gap: 4,
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid #2561B0',
                    borderRadius: '3px',
                    color: '#2561B0',
                    fontSize: 'bold',
                    padding: '3px 15px',
                  }}
                  onClick={() => {
                    navigate(
                      `/owner/postings/permanent/applicant/interview/details/${postingId}/applicants/${item.posting_applicants[0].id}/schedule`
                    );
                  }}
                >
                  <i>
                    <ScheduleIcon
                      style={{
                        fontSize: '18px',
                        paddingBottom: '2px',
                      }}
                    />
                  </i>
                  <div>Interviews</div>
                  <div>
                    {item.posting_applicants[0].is_interviewing > 0
                      ? '(' + item.posting_applicants[0].is_interviewing + ')'
                      : ''}
                  </div>
                </Button>
                <LoadingButton
                  size="small"
                  color="primary"
                  variant="contained"
                  style={{
                    whiteSpace: 'nowrap',
                    fontSize: 'bold',
                    borderRadius: '3px',
                    transition: 'background-color 0.3s',
                    color: '#fff',
                    backgroundColor: '#FA5A16',
                  }}
                  loading={loadingButtonArray[index]?.loading}
                  done={loadingButtonArray[index]?.finished}
                  // loading={loading}
                  // done={finished}
                  onClick={() => declineAction(item, index)}
                >
                  Decline
                </LoadingButton>
              </Box>
            </>
          )}
        </div>
      ),
    };
  });


  return (
    <div className="w-100">
      {!gridWidth && (
        <div
          style={{
            border: '1px solid #D9D9D9',
            borderRadius: '10px',
            overflow: 'auto',
          }}
        >
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {columns.map((item, index) => (
                  <TableCell
                    key={index}
                    style={{ width: item.width }}
                    className={classes.cell}
                  >
                    {item.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow
                    key={index}
                    onClick={() => handleRowClick(rows[index])}
                    className={
                      selectedItem && selectedItem.id === item.id
                        ? classes.selectedRow
                        : ''
                    }
                  >
                    <TableCell className={classes.cell}>
                      {tableRows[index].applicant}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {tableRows[index].rate}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {tableRows[index].averageScore}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {tableRows[index].status}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {tableRows[index].actions}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      )}

      {gridWidth && (
        <Table className={classes.table}>
          <TableBody>
            {tableRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => {
                return (
                  <div
                    style={{
                      margin: gridWidth ? '0 0 15px 0' : '0 10px 15px',
                      backgroundColor: '#fff',
                      padding: '0 15px',
                      borderRadius: '6px',
                      boxShadow: '0px 2px 8px 0px #00000026',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                      }}
                    >
                      <TableRow
                        key={row.id}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleRowClick(tableRows[rowIndex])}
                      >
                        <ExpandMoreIcon />
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            padding: '0 10px',
                          }}
                        >
                          <>
                            {Array.from({ length: 2 }, (_, i) => (
                              <TableCell
                                style={{ borderBottom: 'none' }}
                                key={i}
                                className={classes.cell}
                                sx={{ padding: { xs: '8px', sm: '1rem' } }}
                              >
                                {row[columns[i].field] !== undefined
                                  ? row[columns[i].field]
                                  : columns[i].field}
                              </TableCell>
                            ))}
                          </>
                        </div>

                        <TableCell
                          style={{ borderBottom: 'none', marginLeft: '10px' }}
                          key={Math.floor(Math.random() * 10000)}
                          className={classes.cell}
                          sx={{ padding: { xs: '8px', sm: '1rem' } }}
                        >
                          {row[columns[2].field] !== undefined
                            ? row[columns[2].field]
                            : columns[2].field}
                        </TableCell>
                      </TableRow>
                      {setActions && (
                        <div
                          onClick={() => {
                            setSelectedItem(row);
                            setActions(true);
                          }}
                          style={{
                            cursor: 'pointer',
                            border: '1px solid #E8E8E8',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            src={ShowMoreIcon}
                            style={{ padding: '10px' }}
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                    {selectedItem && selectedItem.id === rows[rowIndex].id && (
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            marginTop: '10px',
                          }}
                        >
                          <>
                            {Array.from(
                              { length: columns.length },
                              (_, i) =>
                                i >= 3 && (
                                  <TableCell
                                    style={{
                                      borderBottom: 'none',
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                    key={i}
                                    className={classes.cell}
                                    sx={{ padding: { xs: '8px', sm: '1rem' } }}
                                  >
                                    <div>
                                      <p
                                        style={{
                                          fontSize: '13px',
                                          fontFamily: 'Roboto',
                                          color: '#262626',
                                        }}
                                      >
                                        {columns[i]?.headerName}
                                      </p>
                                      <div
                                        style={{
                                          color: '#000000',
                                          fontSize: '15px',
                                          marginTop: '6px',
                                        }}
                                      >
                                        {selectedItem[columns[i]?.field] !==
                                        undefined
                                          ? selectedItem[columns[i]?.field]
                                          : columns[i]?.field}
                                      </div>
                                    </div>
                                  </TableCell>
                                )
                            )}
                          </>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
          </TableBody>
        </Table>
      )}

      {selectedApplicant && (
        <ApplicantPopup
          isOpen={isApplicantDataOpen}
          onClose={handleApplicantDataClose}
          selectedApplicant={selectedApplicant}
        />
      )}

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {errorApproveDialog && (
        <ErrorModal
          open={errorApproveDialog}
          handleClose={() => setErrorApproveDialog(false)}
          errorMessage={errorMessages}
        />
      )}

      {openViewReview && (
        <AddReviewModal
          fetchData={() => {
            fetchApplicants(1, 10);
          }}
          handleClose={() => {
            setOpenViewReview(false);
            setSelectedItem({});
          }}
          open={openViewReview}
          user={user}
          postingId={postingId}
          posting={posting}
          selectedItem={selectedItem}
          isScore
        />
      )}

      {isMarkPostCompletedDialogOpen && (
        <MarkPostCompletedDialog
          isOpen={isMarkPostCompletedDialogOpen}
          onClose={() => setIsMarkPostCompletedDialogOpen(false)}
          item={selectedItem}
          fetchData={() => {
            fetchApplicants(1, 10);
          }}
          type="owner"
        />
      )}

      <ButtonsDialog
        actions={actions}
        setActions={setActions}
        selectedItem={{ title: selectedItem?.applicant }}
        buttons={buttons}
        subheading={'Manage this applicant'}
      />
    </div>
  );
};
export default withStyles(styles)(ApplicantsDataGrid);
