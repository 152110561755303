import React, { useEffect, useState } from 'react';

import axios from 'axios';
import './SuccessModal.css';

import Avatar from '@material-ui/core/Avatar';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { API_BASE_URL } from '../../config/constants';
import { getStatusStyle } from '../../utils/CustomDataGridStyle';
import { capitalizeFirstLetter } from '../../utils/helper';

export default function ProposalHistoryModal({
  selectedItem,
  open,
  handleClose,
}) {
  const [closing, setClosing] = useState(false);
  const [history, setHistory] = useState(null);
  const authToken = localStorage.getItem('auth_token');
  const url = window.location.href;
  const id = url.substring(url.lastIndexOf('/') + 1);
  const applicant_user = url.split('/')[8];
  const post = url.split('/')[10];

  const fetchProposal = () => {
    axios
      .get(`${API_BASE_URL}/owner/proposal/histroy/${selectedItem.id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        setHistory(res.data.data[0].proposal_history);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    fetchProposal();
  }, []);

  useEffect(() => {
    console.log('history', history);
  }, [history]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <div
          className="d-flex"
          style={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography id="modal-modal-title_des" variant="h6" component="h2">
            Action History
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke="#595959"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="#595959"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <Typography id="modal-modal-description" sx={{ mt: 1 }}>
          <Box sx={{ flexGrow: 1, mt: 1 }}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Date</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Responsible Party</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history &&
                    history.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" align="left">
                          {moment(row.created_at)
                            .utc()
                            .format('DD:MM:YY HH:MM A')}
                        </TableCell>
                        <TableCell align="left">
                          <span
                            style={getStatusStyle(
                              row.schedule_status.toLowerCase()
                            )}
                          >
                            {capitalizeFirstLetter(row.schedule_status)}
                          </span>
                        </TableCell>
                        <TableCell align="left">
                          <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={1}>
                              <Grid item xs={5}>
                                {row.user.avatar ? (
                                  <Avatar
                                    alt="Remy Sharp"
                                    src={`${API_BASE_URL}/assets/${row.user.avatar}`}
                                    width="40px"
                                    height="40px"
                                  />
                                ) : (
                                  <AccountCircle style={{ fontSize: '40px' }} />
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
