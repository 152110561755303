import React, { useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import leftShoulder from '../assets/images/leftShoulderLogin.jpg';
import rightShoulder from '../assets/images/rightShoulderLogin.jpg';
import CreatePassword from '../components/Login/CreatePassword';
import SignUp from '../components/Login/SignUp';
import VerifyEmail from '../components/Login/VerifyEmail';
import { setVerifyToken } from '../redux/slices/authSlice';
import { selectUser, setSignupId } from '../redux/slices/userSlice';

export default function SignUpPage() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  useEffect(() => {
    if (user && user.role_type) {
      navigate('/login');
    }
  }, [navigate, user]);

  const signup_id = localStorage.getItem('signup_id');
  const verify_token = localStorage.getItem('verify_token');
  const [view, setView] = useState(
    signup_id && verify_token ? 'createpass' : signup_id ? 'verify' : 'signup'
  );
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setVerifyToken(verify_token));
    dispatch(setSignupId(signup_id));
  }, [dispatch, signup_id, verify_token]);

  return (
    <Row className="justify-content-between p-0 m-0">
      <Col className="d-none d-md-grid p-0 m-0" md={2}>
        <Image
          style={{ height: '100vh' }}
          className="img-fluid"
          src={leftShoulder}
        />
      </Col>

      <Col
        style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
        className="px-3 m-0"
        md={8}
        
      >
        {view === 'signup' && (
          <SignUp
            phone={phone}
            setPhone={setPhone}
            email={email}
            setEmail={setEmail}
            setView={setView}
          />
        )}

        {view === 'verify' && (
          <VerifyEmail setView={setView} phone={phone} email={email} />
        )}

        {view === 'createpass' && <CreatePassword setView={setView} />}

        <p
          className="mt-auto text-center mx-auto text-lightgrey"
          style={{ fontSize: '0.73rem' }}
        >
          If you have any questions, please contact us at:{' '}
          <a href="https://maydaydentalstaffing.com">
            maydaydentalstaffing.com
          </a>{' '}
          <br />. Mayday Dental Staffing is a registered trademark. Copyright ©
          Mayday Dental Staffing. All rights reserved. Patent pending.
        </p>
      </Col>

      <Col className="d-none d-md-grid p-0 m-0" md={2}>
        <Image
          style={{ height: '100vh' }}
          className="img-fluid ms-auto"
          src={rightShoulder}
        />
      </Col>
    </Row>
  );
}
