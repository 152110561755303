import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/icons/Logo.svg';
import leftShoulder from '../../assets/images/leftShoulderLogin.jpg';
import practice from '../../assets/images/practiceOwnerSignup.jpg';
import professional from '../../assets/images/professionalSignUp.jpg';
import rightShoulder from '../../assets/images/rightShoulderLogin.jpg';
import { API_BASE_URL } from '../../config/constants';
import { setAuthToken } from '../../redux/slices/authSlice';
import { selectUser, setUser } from '../../redux/slices/userSlice';

const AccountType = () => {
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  useEffect(() => {
    if (!user.role_type) {
      navigate('/login');
    } else if (user.role_type === 'general') {
      navigate('/selectRole');
    } else if (user.role_type === 'owner') {
      navigate('/registration/owner');
    } else if (user.role_type === 'professional') {
      navigate('/registration/professional');
    }
  }, [navigate, user.role_type]);

  const [verifyExp, setVerifyExp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem('auth_token');
  const dispatch = useDispatch();
  const handleAccType = (role_type, exp) => {
    setIsLoading(true);
    axios
      .post(
        `${API_BASE_URL}/signup/profile/types?role_type=${role_type}&is_dental_experience=${exp}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res) {
          dispatch(setUser(res.data.data));
          localStorage.setItem('user', JSON.stringify(res.data.data));
          navigate(`/registration/${res.data.data.role_type}`);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    setIsLoading(false);
  };

  return (
    <Row className="justify-content-between p-0 m-0">
      <Col className="d-none d-md-grid p-0 m-0" md={2}>
        <Image
          style={{ height: '100vh' }}
          className="img-fluid"
          src={leftShoulder}
        />
      </Col>

      <Col
        style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
        className="px-3 m-0"
        md={8}
      >
        {verifyExp && (
          <Dialog
            open={verifyExp}
            onClose={() => {
              setVerifyExp(false);
            }}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            fullWidth
            maxWidth="sm"
          >
            <DialogContent>
              <div className=" px-0 px-md-5 pt-0 pt-md-5 pb-0 pb-md-4">
                <h4 className="text-grey pb-4" id="modal-title">
                  By selecting “YES” you confirm that you have experience in the
                  dental field.
                </h4>
                <p id="modal-description">
                  Dental experince is required to access Mayday Dental Staffing
                  App
                </p>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ boxShadow: 'none', textTransform: 'none' }}
                onClick={() => {
                  setVerifyExp(false);
                }}
              >
                No
              </Button>
              <Button
                sx={{ boxShadow: 'none', textTransform: 'none' }}
                style={{ background: '#4CAF50', color: 'white' }}
                onClick={() => {
                  let role_type = 'professional';
                  let exp = true;
                  handleAccType(role_type, exp);
                  setVerifyExp(false);
                }}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Yes'
                )}
              </Button>
            </DialogActions>
          </Dialog>
        )}

        <Col md={10} className="text-center mx-auto my-3">
          <Image className="mt-3 mb-5" src={logo} />
          <h2 className="mt-1 d-none d-md-grid fw-semibold text-grey">
            Select Account Type
          </h2>
          <p className="text-center">
            Already have an account?{' '}
            <span
              onClick={() => {
                dispatch(setUser({}));
                dispatch(setAuthToken(''));
                localStorage.removeItem('user');
                localStorage.removeItem('auth_token');
                window.location.reload();
              }}
              style={{ color: '#2561B0', cursor: 'pointer' }}
            >
              {' '}
              Sign In{' '}
            </span>
          </p>
          <h1 className="mt-1 d-grid d-md-none fw-semibold text-grey">
            Join Mayday Dental <br />
            Staffing
          </h1>
          <Row className="text-center d-md-none mt-5 mt-md-0 justify-content-center">
            <Col sm={10} md={6} className=" mb-4 mb-md-2">
              <Card className="border-0">
                <Card.Img
                  className="d-none d-md-block"
                  variant="top"
                  src={practice}
                />

                <Card.Body>
                  <Card.Text className="fw-semibold text-grey text-center mb-3">
                    I want to hire temporary <br /> or permanent professionals
                  </Card.Text>

                  <Button
                    variant="contained"
                    color="primary"
                    className="w-75 mx-auto fw-normal"
                    sx={{
                      width: '100%',
                      borderRadius: '4px',
                      height: '56px',
                      background: '#2561B0',
                      boxShadow: 'none',
                      textTransform: 'none',
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      let role_type = 'owner';
                      let exp = false;
                      handleAccType(role_type, exp);
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      <span>
                        I'M A{' '}
                        <span style={{ textDecoration: 'underline' }}>
                          PRACTICE OWNER
                        </span>
                      </span>
                    )}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={10} md={6} className="my-2">
              <Card className="border-0">
                <Card.Img
                  className="d-none d-md-block"
                  variant="top"
                  src={professional}
                />
                <Card.Body className="text-center">
                  <Card.Text className="fw-semibold text-grey mb-3">
                    I'm looking for a temporary <br /> or permanent job
                  </Card.Text>

                  <Button
                    variant="contained"
                    color="primary"
                    className="w-75 mx-auto fw-normal"
                    sx={{
                      width: '100%',
                      borderRadius: '4px',
                      height: '56px',
                      background: '#2561B0',
                      boxShadow: 'none',
                      textTransform: 'none',
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setVerifyExp(true);
                    }}
                  >
                    <span>
                      I'M A{' '}
                      <span style={{ textDecoration: 'underline' }}>
                        PROFESSIONAL
                      </span>
                    </span>
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* // For Large Screens // */}

          <Row className="text-center d-none d-md-flex mt-5 mt-md-0 justify-content-center">
            <Col sm={10} md={6} className="my-2 mb-5 mb-md-2">
              <Card>
                <Card.Img
                  className="d-none d-md-grid"
                  variant="top"
                  src={practice}
                />

                <Button
                  variant="contained"
                  color="primary"
                  className="w-75 mx-auto fw-normal"
                  sx={{
                    width: '100%',
                    borderRadius: '4px',
                    height: '56px',
                    background: '#2561B0',
                    marginTop: '-1.7rem',
                    boxShadow: 'none',
                    textTransform: 'none',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    let role_type = 'owner';
                    let exp = false;
                    handleAccType(role_type, exp);
                  }}
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <span>
                      I'M A{' '}
                      <span style={{ textDecoration: 'underline' }}>
                        PRACTICE OWNER
                      </span>
                    </span>
                  )}
                </Button>
                <Card.Body>
                  <Card.Text className="fw-semibold text-grey">
                    I want to hire temporary <br /> or permanent professionals
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={10} md={6} className="my-2">
              <Card className="h-100">
                <Card.Img
                  className="d-none d-md-grid"
                  variant="top"
                  src={professional}
                />

                <Button
                  variant="contained"
                  color="primary"
                  className="w-75 mx-auto fw-normal"
                  sx={{
                    width: '100%',
                    borderRadius: '4px',
                    height: '56px',
                    background: '#2561B0',
                    marginTop: '-1.7rem',
                    boxShadow: 'none',
                    textTransform: 'none',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setVerifyExp(true);
                  }}
                >
                  <span>
                    I'M A{' '}
                    <span style={{ textDecoration: 'underline' }}>
                      PROFESSIONAL
                    </span>
                  </span>
                </Button>
                <Card.Body>
                  <Card.Text className="fw-semibold text-grey">
                    I'm looking for a temporary <br /> or permanent job
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>

        <p
          className="mt-auto text-center mx-auto text-lightgrey"
          style={{ fontSize: '0.73rem' }}
        >
          If you have any questions, please contact us at:{' '}
          <a href="https://maydaydentalstaffing.com">
            maydaydentalstaffing.com
          </a>{' '}
          <br />. Mayday Dental Staffing is a registered trademark. Copyright ©
          Mayday Dental Staffing. All rights reserved. Patent pending.
        </p>
      </Col>

      <Col className="d-none d-md-grid p-0 m-0" md={2}>
        <Image
          style={{ height: '100vh' }}
          className="img-fluid ms-auto"
          src={rightShoulder}
        />
      </Col>
    </Row>
  );
};

export default AccountType;
