import { Button, CircularProgress, TextField, Alert } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../assets/icons/Logo.svg';
import { API_BASE_URL } from '../../config/constants';
import { setVerifyToken } from '../../redux/slices/authSlice';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

const VerifyEmail = ({ email, phone, setView }) => {
  const dispatch = useDispatch();

  const [codes, setCodes] = useState(['', '', '', '', '', '']);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [resendFeedback, setResendFeedback] = useState({
    message: '',
    success: false,
  });
  const [isResending, setIsResending] = useState(false);

  const handleResend = () => {
    setCodes(['', '', '', '', '', '']);
    setResendFeedback({ message: '', success: false });
    setIsResending(true);

    const sendVerification = (url) => {
      return axios
        .post(url)
        .then((response) => {
          const data = response.data.data;
          if (data.verificationToken) {
            const { status, message } = data.verificationToken;
            if ([200, 201, 204].includes(status)) {
              setResendFeedback({
                message: url.includes('email')
                  ? 'Email Sent Again'
                  : 'Text Sent Again',
                success: true,
              });
            } else {
              setResendFeedback({ message, success: false });
            }
          } else {
            setResendFeedback({
              message: 'Unable to send verification token.',
              success: false,
            });
          }
        })
        .catch((error) => {
          setErrorMessage('Unable to send verification token.');
          console.error('Error sending POST request:', error);
        });
    };

    if (email.length > phone.length) {
      sendVerification(`${API_BASE_URL}/signup?email=${email}`);
    } else if (phone.length > email.length) {
      sendVerification(
        `${API_BASE_URL}/signup?mobile=${encodeURIComponent(phone)}`
      );
    } else {
      setResendFeedback({
        message:
          'Phone Number or Email is missing, kindly go back and enter the information again.',
        success: false,
      });
      console.log("Email or Number isn't present");
    }
    setIsResending(false);
  };

  const handleArrowKey = (index, direction) => {
    const newIndex = direction === 'left' ? index - 1 : index + 1;

    if (newIndex >= 0 && newIndex < 6) {
      document.getElementById(`code-input-${newIndex}`).focus();
    }
  };

  const handleKeyPress = (index, e) => {
    if (e.key === 'ArrowLeft') {
      handleArrowKey(index, 'left');
    } else if (e.key === 'ArrowRight') {
      handleArrowKey(index, 'right');
    }
  };

  const handleChange = (index, value) => {
    const newCodes = [...codes];
    newCodes[index] = value;

    if (value && index < 5) {
      document.getElementById(`code-input-${index + 1}`).focus();
    } else if (!value && index > 0) {
      document.getElementById(`code-input-${index - 1}`).focus();
    }

    setCodes(newCodes);
  };

  const handleNext = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setIsLoading(true);
    const verificationCode = codes.join('');

    axios
      .post(`${API_BASE_URL}/signup/verify?verify_token=${verificationCode}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setVerifyToken(verificationCode));
          localStorage.setItem('verify_token', verificationCode);
          setView('createpass');
        } else {
          console.error(`Unexpected status code: ${response.status}`);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setErrorMessage('Code Incorrect');
        } else {
          console.error(error);
        }
      });
    setIsLoading(false);
  };

  return (
    <Col
      xs={10}
      sm={8}
      md={7}
      lg={5}
      xxl={4}
      className="text-center mx-auto mt-3"
    >
      <Image className="mt-3 mb-5" src={logo} />
      <h2 className="mt-3 fw-semibold text-grey">
        Verify {email.length > phone.length ? 'Email' : 'Phone Number'}
      </h2>
      <div className="mb-5 text-grey fs-small">
        We just sent a code to
        <Link to={'#'} className="text-decoration-none">
          <span style={{ color: '#2561B0' }}>
            {' '}
            {email.length > phone.length ? email : phone}
          </span>
        </Link>
      </div>
      <Row className="text-center justify-content-center mb-3 mx-0">
        {codes.map((code, index) => (
          <Col className="mx-1 p-0 text-center" key={index}>
            <TextField
              id={`code-input-${index}`}
              className="form-control text-end"
              placeholder="0"
              error={errorMessage !== ''}
              value={code}
              onChange={(e) => {
                setErrorMessage('');
                const value = e.target.value;
                if (/^[0-9]?$/.test(value)) {
                  handleChange(index, value);
                } else if (value === '') {
                  handleChange(index, '');
                }
              }}
              inputProps={{
                style: { textAlign: 'center' },
                maxLength: 1,
                type: 'tel',
              }}
              onKeyDown={(e) => handleKeyPress(index, e)}
            />
          </Col>
        ))}
        {errorMessage && (
          <div
            className="text-start mt-2"
            style={{ color: '#FA5A16', fontSize: '0.7rem' }}
          >
            {errorMessage}
          </div>
        )}
      </Row>
      <div className="mb-4 fs-small text-grey text-start mt-2">
        Didn't recieve it? Please wait for a few minutes and
        <Button className="text-decoration-none" disabled={isResending}>
          <span onClick={handleResend} style={{ color: '#2561B0' }}>
            {' '}
            resend the code.
          </span>
        </Button>
      </div>
      {resendFeedback.message && (
        <Alert
          className="mt-2 mb-3"
          severity={resendFeedback.success ? 'success' : 'error'}
          icon={resendFeedback.success ? <CheckCircleIcon /> : <ErrorIcon />}
        >
          {resendFeedback.message}
        </Alert>
      )}

      <Row>
        <Col xs={4}>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              width: '100%',
              borderRadius: '4px',
              height: '56px',
              border: '2px solid #2561B0',
              color: '#2561B0',
              boxShadow: 'none',
              textTransform: 'none',
            }}
            onClick={() => {
              setView('signup');
            }}
          >
            Back
          </Button>
        </Col>
        <Col xs={8}>
          <Button
            disabled={!codes[codes.length - 1].length}
            onClick={handleNext}
            variant="contained"
            color="primary"
            sx={{
              width: '100%',
              borderRadius: '4px',
              height: '56px',
              background: '#2561B0',
              boxShadow: 'none',
              textTransform: 'none',
            }}
          >
            {isLoading ? <CircularProgress /> : 'Next'}
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

export default VerifyEmail;
