import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  Radio,
  Typography,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { API_BASE_URL } from '../../../../../config/constants';
import { capitalizeFirstLetter } from '../../../../../utils/helper';

const ApplyInterviewModal = ({
  isOpen,
  onClose,
  item,
  posting,
  fetchData,
  onRescheduleInterviewModal,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const ConfirmSchedule = () => {
    axios
      .put(
        `${API_BASE_URL}/interviews/${item.id}`,
        {
          interview_schedule_id: selectedDate,
          interview_status: 'scheduled',
        },
        {
          headers: {
            method: 'PUT',
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
          },
        }
      )
      .then(() => {
        onClose();
        fetchData(1, 10);
      })
      .catch((e) => console.log(e));
  };

  const RejectSchedule = () => {
    axios
      .put(
        `${API_BASE_URL}/interviews/${item.id}`,
        {
          interview_status: 'rejected',
        },
        {
          headers: {
            method: 'PUT',
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
          },
        }
      )
      .then(() => {
        onClose();
        fetchData(1, 10);
      })
      .catch((e) => console.log(e));
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <div className="d-flex justify-content-between align-content-center">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Applying For Job Interview
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              cursor: 'pointer',
            }}
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Box
            className="d-flex"
            sx={{
              flexDirection: { xs: 'column', sm: 'row' },
              gap: '20px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: { xs: '10px', sm: '20px' },
                width: { xs: '100%', sm: '30%' },
              }}
            >
              <h3
                style={{
                  color: '#262626',
                  margin: '0',
                  fontSize: '16px',
                  fontWeight: '500',
                }}
              >
                General Information
              </h3>
              <div>
                <p
                  style={{
                    color: '#595959',
                    margin: '0',
                    fontSize: '12px',
                    fontWeight: '400',
                  }}
                >
                  Interview Type
                </p>
                <p
                  style={{
                    margin: '0',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}
                >
                  {capitalizeFirstLetter(item.type)}
                </p>
              </div>
              <div>
                <p
                  style={{
                    color: '#595959',
                    margin: '0',
                    fontSize: '12px',
                    fontWeight: '400',
                  }}
                >
                  Company
                </p>
                <p
                  style={{
                    margin: '0',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}
                >
                  {/* {posting.user.companies[0].name} */}
                </p>
              </div>
              <div>
                <p
                  style={{
                    color: '#595959',
                    margin: '0',
                    fontSize: '12px',
                    fontWeight: '400',
                  }}
                >
                  Location
                </p>
                <p
                  style={{
                    margin: '0',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}
                >
                  {posting.user_location.place_name}
                </p>
              </div>
              <div>
                <p
                  style={{
                    color: '#595959',
                    margin: '0',
                    fontSize: '12px',
                    fontWeight: '400',
                  }}
                >
                  Posting Name
                </p>
                <p
                  style={{
                    margin: '0',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}
                >
                  {item.posting_applicant.posting.title}
                </p>
              </div>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                width: { xs: '100%', sm: '70%' },
              }}
            >
              <h3
                style={{
                  color: '#262626',
                  margin: '0',
                  fontSize: '16px',
                  fontWeight: '500',
                }}
              >
                Date & Time Interview Options
              </h3>

              <div
                style={{
                  backgroundColor: '#D7E8FF',
                  padding: '7px 13px',
                  borderRadius: '3px',
                }}
              >
                <p
                  style={{
                    color: '#194378',
                    margin: '0',
                    fontSize: '12px',
                    fontWeight: '400',
                  }}
                >
                  Please choose one option that will be sent to the Doctor. Only
                  one option can be chosen.
                </p>
              </div>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: {
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                  },
                  gap: '20px',
                }}
              >
                {item.interview_schedules.map((schedule, index) => {
                  if (schedule.schedule_status === 'suggested') {
                    return (
                      <InterviewTime
                        key={schedule.id}
                        schedule={schedule}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                      />
                    );
                  }
                  return <></>;
                })}
              </Box>
              <div
                style={{
                  backgroundColor: '#D7E8FF',
                  padding: '7px 13px',
                  borderRadius: '3px',
                }}
              >
                <p
                  style={{
                    color: '#194378',
                    margin: '0',
                    fontSize: '12px',
                    fontWeight: '400',
                  }}
                >
                  If you are not available for these dates/times, click on
                  Decline button and office will provide new options.
                </p>
              </div>
            </Box>
          </Box>
        </Typography>
      </DialogContent>
      <DialogActions>
        <div className="d-flex flex-wrap justify-content-sm-between align-items-center row-gap-2">
          <Button
            onClick={onClose}
            color="primary"
            style={{
              backgroundColor: 'transparent',
              color: '#194378',
              border: 'none',
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#E54C0B',
              color: '#fff',
              border: 'none',
              padding: '5px 20px',
              borderRadius: '5px',
              marginRight: '10px',
            }}
            onClick={RejectSchedule}
          >
            Decline
          </Button>

          <Button
            variant="contained"
            style={{
              backgroundColor: '#817c79',
              color: '#fff',
              border: 'none',
              padding: '5px 20px',
              borderRadius: '5px',
              marginRight: '10px',
            }}
            onClick={() => {
              onClose();
              onRescheduleInterviewModal();
            }}
          >
            Reschedule Interview
          </Button>

          <Button
            onClick={ConfirmSchedule}
            // color='primary'
            style={{
              backgroundColor: '#4CAF50',
              // color: '#194378',
              border: 'none',
            }}
            disabled={selectedDate === null}
          >
            Confirm
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ApplyInterviewModal;

const InterviewTime = ({ schedule, selectedDate, setSelectedDate }) => {
  const lo_user = localStorage.getItem('user');
  const lo_user_ob = JSON.parse(lo_user);
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  const day = new Date(schedule.interview_date).getDay();
  const dayName = days[day];
  return (
    <div
      className="d-flex"
      style={{
        flexDirection: 'column',
        gap: 10,
        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '6px',
        padding: '10px',
      }}
    >
      <div className="d-flex" style={{ justifyContent: 'space-between' }}>
        <div className="d-flex" style={{ gap: 13, alignItems: 'center' }}>
          <p
            style={{
              color: '#000000',
              margin: '0',
              fontSize: '14px',
              fontWeight: '500',
            }}
          >
            {dayName}
          </p>
          <p
            style={{
              color: '#262626',
              margin: '0',
              fontSize: '12px',
              fontWeight: '400',
            }}
          >
            {moment
              .utc(schedule.interview_date, 'YYYYMMDD HH:mm:ss')
              .tz(lo_user_ob.user_time_zone)
              .format('MM/DD/YY hh:mm A')}
            {/* {schedule.interview_date} */}
          </p>
        </div>
        <Radio
          checked={selectedDate === schedule.id}
          onChange={() => setSelectedDate(schedule.id)}
        />
      </div>
      <div className="d-flex" style={{ gap: 20 }}>
        <p
          style={{
            color: '#8C8C8C',
            margin: '0',
            fontSize: '12px',
            fontWeight: '400',
          }}
        >
          Start Time
        </p>
        <p
          style={{
            color: '#8C8C8C',
            margin: '0',
            fontSize: '12px',
            fontWeight: '400',
          }}
        >
          End Time
        </p>
      </div>
      <p
        style={{
          color: '#262626',
          margin: '0',
          fontSize: '12px',
          fontWeight: '500',
        }}
      >
        {schedule.start_time} - {schedule.end_time}
      </p>
    </div>
  );
};
