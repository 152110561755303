import { Grid } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
// import { Button } from 'react-bootstrap';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../../components/Layout';
import '../TemporaryJobs/PostingApplicants.css';
import ProposalFilterSidebar from './ProposalFilterSidebar';
import ProposalsDataGrid from './ProposalsDataGrid';

import AccountCircle from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import StarRating from '../../../../components/General/StarRating';
import SendProposalModal from './SendProposalModal';

import CancelProposalModal from './CancelProposalModal';
import HiringProfessionalModal from './HiringProfessionalModal';

import ErrorModal from '../../../../components/General/ErrorModal';
import SuccessModal from '../../../../components/General/SuccessModal';
import { API_BASE_URL } from '../../../../config/constants';
import ButtonsDialog from '../../../../components/General/CustomButtonsDialog';

const PostingApplicantProposal = () => {
  const [applicants, setApplicants] = useState(null);
  const [permanentJobs, setPermanentJobs] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [gridWidth, setGridWidth] = useState();
  const [actions, setActions] = useState(false);

  const [showFilter, setShowFilter] = useState(false);
  const [filteredApplicants, setFilteredApplicants] = useState([]);
  const [proposals, setProposals] = useState([]);
  const [hiringRate, setHiringRate] = useState([]);
  const [proposalDate, setProposalDate] = useState('');
  const [selectStatus, setSelectStatus] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [showFilteredApplicants, setShowFilteredApplicants] = useState(false);
  const [interviewDetailsOpen, setInterviewDetailsOpen] = useState(false);
  const [openFailModal, setOpenFailModal] = useState(false);

  const [userLocations, setUserLocations] = useState([]);
  const [userCurrentLocation, setUserCurrentLocation] = useState(null);
  const [applicant, setApplicant] = useState(null);
  const [proposalModal, setProposalModal] = useState(false);

  const [cancelProposalModal, setCancelProposalModal] = useState(false);
  const [hiringModal, setHiringModal] = useState(false);

  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const authToken = localStorage.getItem('auth_token');
  const url = window.location.href;
  const id = url.substring(url.lastIndexOf('/') + 1);

  const navigate = useNavigate();
  const applicant_user = url.split('/')[8];
  const post = url.split('/')[10];

  const getApplicant = async (selectedApplicant) => {
    axios
      .get(`${API_BASE_URL}/owner/application-profile/${applicant_user}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      })
      .then((response) => {
        setApplicant(response.data.data);
        setUserLocations(response.data.data.user_locations);
        setUserCurrentLocation(() => {
          return response.data.data.user_locations.find(
            (location) => location.is_current == true
          );
        });
      });
  };

  const fetchApplicants = (page, limit) => {
    axios
      .get(
        `${API_BASE_URL}/owner/applicants?posting_id=${id}&page=${page}&limit=${limit}`,
        {
          headers: {
            method: 'GET',
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        setApplicants(res.data);
        setSelectedItem(null);
      })
      .catch((e) => console.log(e));
  };

  const fetchPosting = () => {
    axios
      .get(`${API_BASE_URL}/owner/postings/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        setPermanentJobs(res.data);
      })
      .catch((e) => console.log(e));
  };

  const fetchFilterProposal = () => {
    let endpoint = `${API_BASE_URL}/owner/proposals?posting_id=${post}&applicant=${applicant_user}`;

    if (selectStatus && selectStatus !== '') {
      endpoint += `&proposal_status=${selectStatus}`;
    }

    if (hiringRate) {
      endpoint += `&hiringRate=${hiringRate}`;
    }

    if (proposalDate) {
      endpoint += `&from=${proposalDate}`;
    }

    axios
      .get(endpoint, {
        headers: {
          method: 'GET',
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      })
      .then((response) => {
        setProposals(response.data.data);
        setIsSidebarOpen(false);
      })
      .catch((e) => {
        setIsSidebarOpen(false);
      });
  };

  const fetchProposal = () => {
    axios
      .get(
        `${API_BASE_URL}/owner/proposals?posting_id=${post}&applicant=${applicant_user}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        setProposals(res.data.data);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    fetchApplicants(1, 10);
    fetchProposal();
    getApplicant();
    fetchPosting();
  }, []);

  // useEffect(() => {
  //   console.log('proposals ==== > ', proposals);
  // }, [proposals]);

  useEffect(() => {
    const handleResize = () => {
      setGridWidth(window.innerWidth < 800 ? true : false);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, [window.innerWidth]);

  const columns = [
    { field: 'date', headerName: 'Date' },
    { field: 'hiring_rate', headerName: 'Hiring Rate($/h)' },
    // { field: 'rate', headerName: 'Rate($/h)' },
    { field: 'status', headerName: 'Status' },
    { field: 'days', headerName: 'Days' },
    { field: 'actions', headerName: '' },
  ];

  const closeFilterHandler = () => {
    setHiringRate([]);
    setSelectStatus([]);
    setProposalDate('');
    fetchProposal();
  };

  const cancelProposal = () => {
    if (selectedItem !== null) {
      axios
        .delete(`${API_BASE_URL}/owner/proposals/${selectedItem.id}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          fetchProposal();
          setCancelProposalModal(false);
        })
        .catch((e) => console.log(e));
    }
  };

  const mobileDrawerButtons = [
    {
      type: 'button',
      label: 'Cancel Proposal',
      onClick: () => {
        setCancelProposalModal(true);
      },
      disabled: false,
      backgroundColor: '#fff',
      borderColor: '#FA5A16',
      color: '#FA5A16',
      marginTop: '16px',
    },
    {
      type: 'hr',
      marginTop: '20px',
      color: '#2561B0', // Customize hr color
    },
    {
      type: 'button',
      label: 'Hire',
      onClick: () => {
        setHiringModal(true);
      },
      disabled: false,
      backgroundColor: '#fff',
      borderColor: '#2561B0',
      color: '#595959',
      marginTop: '16px',
    },
  ];

  return (
    <Layout
      items={[
        {
          name: 'Postings',
          link: '/',
        },
        {
          name: 'Permanent Job',
          link: '/owner/postings/permanent',
        },
        {
          name: 'Assignment Applicants',
          link: `/owner/postings/permanent/applicants/${id}`,
        },
      ]}
    >
      <div
        className="d-flex justify-content-between"
        style={{
          backgroundColor: '#F5F5F5',
          padding: '12px 20px',
          borderBottom: '1px solid #D9D9D9',
        }}
      />

      <Grid
        sx={{
          px: 3,
          pt: 2,
          pb: 1,
          borderBottom: '1px solid #D9D9D9',
          width: 'auto',
        }}
      >
        <div className="postingSchedule">
          <div
            className="d-flex"
            style={{
              gap: 60,
            }}
          >
            <div>
              <h4>Proposals</h4>
              <p>{permanentJobs?.data?.title}</p>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              gap: 10,
              alignItems: 'left',
            }}
          >
            {applicant ? (
              <Avatar
                alt="Remy Sharp"
                src={`${API_BASE_URL}/assets/${applicant?.avatar}`}
                sx={{ width: 75, height: 75 }}
              />
            ) : (
              <AccountCircle style={{ fontSize: '75px' }} />
            )}
            <div>
              <div>
                <p>
                  {applicant?.first_name} {applicant?.last_name}
                </p>
              </div>
              <div>
                <StarRating
                  rating={
                    applicant?.average_score ? applicant?.average_score : 0
                  }
                />
              </div>
              {/* <div>
                <p>Rate : {userCurrentLocation ? userCurrentLocation.desired_rate : 0}</p>
              </div>
              <div>
                <p>Rate : {userCurrentLocation ? userCurrentLocation.desired_rate : 0}</p>
              </div> */}
            </div>
          </div>

          <div
            className="d-flex"
            style={{
              gap: 10,
            }}
          >
            {/* <div>
            <h6>Proposals</h6>
            <p>{permanentJobs?.data?.title}</p>
          </div> */}
          </div>
        </div>
      </Grid>

      {permanentJobs && (
        // <div className='postingSchedule'>
        <div
          className="d-flex justify-content-between"
          style={{
            backgroundColor: '#F5F5F5',
            padding: '12px 20px',
            borderBottom: '1px solid #D9D9D9',
            gap: 5,
          }}
        >
          <div
            className="d-flex"
            style={{
              gap: 20,
            }}
          >
            <Button
              style={{
                backgroundColor: '#2561B0',
                border: 0,
                textTransform: 'capitalize',
                borderRadius: '3px',
                padding: '6px 15px',
                color: 'white',
              }}
              onClick={() => setProposalModal(true)}
            >
              Send Proposal
            </Button>

            <Button
              onClick={() => setCancelProposalModal(true)}
              disabled={selectedItem ? false : true}
              style={{
                border: selectedItem
                  ? '1px solid #2561B0'
                  : '1px solid #D9D9D9',
                color: selectedItem ? '#595959' : '#BFBFBF',
                backgroundColor: selectedItem ? '#fff' : '#F5F5F5',
                display: `${gridWidth ? 'none' : ''}`,
                textTransform: 'capitalize',
                borderRadius: '3px',
                padding: '6px 15px',
              }}
            >
              Cancel Proposal
            </Button>

            <Button
              onClick={() => {
                // console.log('slected Item click hire === > ', selectedItem.proposal_status);
                setHiringModal(true);
              }}
              style={{
                border:
                  selectedItem?.proposal_status === 'accepted'
                    ? '1px solid #2561B0'
                    : '1px solid #D9D9D9',
                color:
                  selectedItem?.proposal_status === 'accepted'
                    ? '#595959'
                    : '#BFBFBF',
                backgroundColor:
                  selectedItem?.proposal_status === 'accepted'
                    ? '#fff'
                    : '#F5F5F5',
                display: `${gridWidth ? 'none' : ''}`,
                textTransform: 'capitalize',
                borderRadius: '3px',
                padding: '6px 15px',
              }}
            >
              HIRE
            </Button>
          </div>

          <div
            style={{
              display: 'flex',
              gap: 20,
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => setIsSidebarOpen(true)}
              style={{
                backgroundColor: '#2561B0',
                border: 0,
                textTransform: 'capitalize',
                borderRadius: '3px',
                padding: '6px 15px',
                color: 'white',
              }}
            >
              <i>
                {' '}
                <FilterListOutlinedIcon
                  style={{ fontSize: '18px', paddingBottom: '2px' }}
                />{' '}
              </i>
              <span style={{ display: gridWidth ? 'none' : 'inline-block' }}>
                Filters
              </span>
            </Button>
            <Button
              onClick={fetchProposal}
              style={{
                backgroundColor: '#fff',
                color: '#595959',
                borderRadius: 5,
                border: '1px solid #2561B0',
                textTransform: 'capitalize',
                padding: '6px 15px',
              }}
            >
              Reset Filters
            </Button>
          </div>
        </div>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 20,
        }}
      >
        <div
          style={{
            border: '1px solid #D9D9D9',
            borderRadius: '10px',
            width: '100%',
            overflow: 'auto',
          }}
        >
          {applicants && applicants.data && (
            <ProposalsDataGrid
              columns={columns}
              // rows={applicants.data}
              // rows={rowss}
              rows={proposals}
              paging={applicants.paging}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              fetchApplicants={fetchApplicants}
              postingId={id}
              setInterviewDetailsOpen={setInterviewDetailsOpen}
              setActions={setActions}
            />
          )}
        </div>
      </div>

      {isSidebarOpen && (
        <ProposalFilterSidebar
          fetchProposal={() => {
            fetchFilterProposal();
          }}
          resetFilter={closeFilterHandler}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          hiringRate={hiringRate}
          setHiringRate={setHiringRate}
          selectStatus={selectStatus}
          setSelectStatus={setSelectStatus}
          proposalDate={proposalDate}
          setProposalDate={setProposalDate}
        />
      )}

      {proposalModal && (
        <SendProposalModal
          open={proposalModal}
          handleClose={() => setProposalModal(false)}
          fetchProposal={fetchProposal}
          applicant={applicant}
          userCurrentLocation={userCurrentLocation}
          permanentJob={permanentJobs.data}
          successModal={() => setOpenSuccessModal(true)}
        />
      )}

      {openSuccessModal && (
        <SuccessModal
          open={openSuccessModal}
          handleClose={() => setOpenSuccessModal(false)}
          successMessage={successMessage}
        />
      )}

      {openErrorModal && (
        <ErrorModal
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          errorMessage={errorMessage}
        />
      )}

      {hiringModal && selectedItem && (
        <HiringProfessionalModal
          isOpen={hiringModal}
          onClose={() => setHiringModal(false)}
          item={selectedItem}
          setOpenSuccessModal={setOpenSuccessModal}
          setOpenErrorModal={setOpenErrorModal}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          fetchProposal={fetchProposal}
        />
      )}

      {cancelProposalModal && (
        <CancelProposalModal
          open={cancelProposalModal}
          handleClose={() => setCancelProposalModal(false)}
          selectedItem={selectedItem}
          cancelProposal={cancelProposal}
          fetchData={() => fetchApplicants(1, 10)}
        />
      )}

      <ButtonsDialog
        actions={actions}
        setActions={setActions}
        selectedItem={{ title: 'Manage this Proposal' }}
        buttons={mobileDrawerButtons}
        subheading={'Actions given below'}
      />
    </Layout>
  );
};

export default PostingApplicantProposal;
